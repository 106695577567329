import { IProcessProperty } from 'interfaces/IProcessProperty';
import { IStore } from 'interfaces/IStore';
import { SetStateAction, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNewPropertiesData } from 'services/getNewPropertiesData';
import { updateExtensions } from 'store/actions/generalActions';
import useHandleResponseErrors from './useHandleResponseErrors';

const usePropertiesForm = (sameEnvConfiguration: boolean, extensions: any, envs: string[], step: number, setIsLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }) => {
    const { networkError, isEditInstall, isLoading: isDataLoading } = useSelector((state: IStore) => state.general);
    const dispatch = useDispatch();
    const { handleResponseErrors, handleResponseErrorsForEditInstall } = useHandleResponseErrors();
    const getPropertiesFormData = (data: any, processId: string) => {
        if (sameEnvConfiguration) {
            const updatedExtensions: any[] = [...extensions];
            extensions.forEach((item: any, i: number) => {
                if (item.extensionGroupId === processId) {
                    const updatedObject = getNewPropertiesData(false, extensions, i, data);
                    updatedExtensions[i] = updatedObject[i];
                }
            });
            dispatch(updateExtensions(updatedExtensions));
        } else {
            const extensionIndex = extensions.findIndex((item: any) => item.extensionGroupId === processId && item.environmentId === envs[step]);
            const updatedObject = getNewPropertiesData(sameEnvConfiguration, extensions, extensionIndex, data);
            dispatch(updateExtensions(updatedObject));
        }
    };

    const getPropertiesData = (process: string) => {
        let propertyData: IProcessProperty[] = [];
        if (sameEnvConfiguration) {
            propertyData = extensions.find((item: any) => item.extensionGroupId === process)?.processProperties?.ProcessProperty || [];
        } else {
            propertyData = extensions.find((item: any) => item.extensionGroupId === process && item.environmentId === envs[step])?.processProperties?.ProcessProperty || [];
        }
        return propertyData;
    };

    useEffect(() => {
        if (networkError) {
            if (isEditInstall) {
                handleResponseErrorsForEditInstall();
            } else {
                handleResponseErrors();
            }
            setIsLoading(false);
        }
    }, [networkError]);

    useEffect(() => {
        if (isDataLoading) {
            setIsLoading(true);
        }
    }, [isDataLoading]);

    return { getPropertiesFormData, getPropertiesData };
};

export default usePropertiesForm;
