import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IDropdown } from 'interfaces/IDropdownField';
import { ExFilter, ExMenuItem } from '@boomi/exosphere';
import './Filter.scss';

export const Filter: FC<IDropdown> = ({ handleChange ,title='',options}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="filter">
                <ExFilter
                    filterLabel={t(title)}
                    onChange={handleChange}
                    showSelectedCategory={true}
                >
                    {options.map((el) => (
                        <ExMenuItem category={t(el.value)} key={el.id}></ExMenuItem>
                    ))}
                </ExFilter>
            </div>
        </>
    );
};

export default Filter;
