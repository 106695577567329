import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useBasePageData from 'hooks/useBasePageData';
import useDisplayEnvName from 'hooks/useDisplayEnvName';
import useConditionalRoutes from 'hooks/useConditionalRoutes';
import { IConfigureConnection, IConnectionsArr } from 'interfaces/IConfigureConnection';
import { IStore } from 'interfaces/IStore';
import { getDynamicPropertiesQuery } from 'services/getDynamicQuery';
import { useQueryParam } from 'hooks/useQueryParam';
import { IProcessProperty, IPropertiesData } from 'interfaces/IProcessProperty';
import { setDifferentEnvStep, setSeletedExtensionProcess, updateExtensions } from 'store/actions/generalActions';
import useProcessExtensions from './useProcessExtensions';
import useGenericInstall from './useGenericInstall';
import { isExtensionExists } from 'utils/Extensions';
import usePropertiesForm from './usePropertiesForm';
import useInstallationStep from './useInstallationStep';
import { ExtensionTypes } from 'constants/extensions';
import useProcessSelection from './useProcessSelection';

const useProperties = (): IPropertiesData => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const { getPropertiesPaths } = useConditionalRoutes();
    const { selectedIPack, modalStatus, selectedEnvironments, extensions, isEditInstall } = useSelector((state: IStore) => state.general);
    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env) || { sameEnvConfiguration: true, step: 0 };
    const { isDataMaps } = useSelector((state: IStore) => state.general.extensionsStatus) || { isDataMaps: false };
    const { process, setSelectedProcess, getProcessesForExtension, getExtensionsData } = useProcessExtensions();
    const [property, setProperty] = useState<IProcessProperty[]>([]);
    const { getCancelBtnLabel, handleUninstall, getExtensionPageTitle } = useGenericInstall();
    const [isLoading, setIsLoading] = useState(false);
    const { stepChangeWithOneExtension } = useInstallationStep(step, ExtensionTypes.PROCESS_PROPERTY, sameEnvConfiguration);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm();

    const { envName } = useDisplayEnvName();
    const { id, envIds } = useQueryParam();

    //Create dynamic query to pass to the BE
    const { query } = getDynamicPropertiesQuery(id, envIds?.split(',') || '');
    const { data: pageData } = useBasePageData<IConfigureConnection>(query);
    const nextStep = step + 1;
    const envStep = step ? step : 0;

    // get conditional route based on extensions data
    const { nextPathStep, afterPropertiesStep } = getPropertiesPaths();

    const environmentExtensions = pageData?.data.integrationPackEnvironmentAttachments[envStep].environmentExtensions[0];
    const environmentAttachments = pageData?.data.integrationPackEnvironmentAttachments;
    const processes = getProcessesForExtension(environmentAttachments, 'PROCESS_PROPERTY');
    const { setDefaultProcess } = useProcessSelection(processes);
    const envs = envIds.split(',');
    const { getPropertiesFormData, getPropertiesData } = usePropertiesForm(sameEnvConfiguration, extensions, envs, step, setIsLoading);

    const onSubmit = (data: unknown) => {
        getPropertiesFormData(data, process);
        if (!sameEnvConfiguration && step !== (selectedEnvironments?.length as number) - 1) {
            !isDataMaps && dispatch(setDifferentEnvStep(nextStep));

            navigate(`${afterPropertiesStep}${search}`);

            return;
        }
        navigate(`${pathname}/${nextPathStep}${search}`);
    };

    useEffect(() => {
        dispatch(setSeletedExtensionProcess(''));
        setIsLoading(true);
    }, []);

    useEffect(() => {
        if (process.length) {
            const propertyData: IProcessProperty[] = getPropertiesData(process);
            propertyData && setProperty(propertyData);
        }
    }, [process]);

    useEffect(() => {
        if (environmentAttachments) {
            const extensionsStateArr: IConnectionsArr['result'] = getExtensionsData(environmentAttachments, 'PROCESS_PROPERTY');
            dispatch(updateExtensions(extensionsStateArr));
            setIsLoading(false);
        }
    }, [environmentAttachments]);

    if (isExtensionExists(environmentExtensions, pageData, envStep)) {
        return {
            property: [],
            selectedIPack,
            onSubmit,
            handleSubmit,
            register,
            errors,
            setValue,
            handleUninstall,
            t,
            processes,
            setSelectedProcess,
            process,
            getValues,
            reset,
            getPropertiesFormData,
            isEditInstall,
            getCancelBtnLabel,
            getExtensionPageTitle,
            isLoading,
            modalStatus,
            envName,
            stepChangeWithOneExtension,
            setDefaultProcess,
        };
    }

    return {
        property,
        selectedIPack,
        onSubmit,
        envName,
        modalStatus,
        isLoading,
        register,
        handleSubmit,
        errors,
        setValue,
        t,
        handleUninstall,
        processes,
        setSelectedProcess,
        process,
        getValues,
        reset,
        getPropertiesFormData,
        isEditInstall,
        getCancelBtnLabel,
        getExtensionPageTitle,
        stepChangeWithOneExtension,
        setDefaultProcess,
    };
};

export default useProperties;
