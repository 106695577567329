import { IEnvironmentData, IEnvironmentElement, IEnvironments } from 'interfaces/IEnvironment';
import { IStore } from 'interfaces/IStore';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from 'store/actions/generalActions';
import { useQueryParam } from './useQueryParam';

const useEditIpackTable = (environmentsAll: IEnvironmentData | null) => {
    const { installedData } = useSelector((state: IStore) => state.general);
    const { id } = useQueryParam();
    const [isAnyEnvironmentMissingAtom, setEnvironmentNoAtomWarning] = useState(false);
    const [rowDetails, setRowDetails] = useState<{ environmentName: string | undefined; installStatus: string; Actions: string; id: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const rowData: any = [];
        let isAtomMissing = false;
        environmentsAll?.data?.environments?.forEach((item: IEnvironmentElement) => {
            const envData = installedData?.environments?.find((env: IEnvironments) => env.id === item.id);
            const isEnvAttached = isIpackAttachedToEnv(envData) ? true : false;
            const { rowValue, isEnvMissingAtom } = getRowValue(item, isEnvAttached);
            rowData.push(rowValue);
            isAtomMissing = isAtomMissing ? isAtomMissing : isEnvMissingAtom;
        });
        setEnvironmentNoAtomWarning(isAtomMissing);
        setRowDetails(rowData);
        setIsLoading(false);
    }, [installedData, environmentsAll]);

    const isIpackAttachedToEnv = (envData: any) => envData && envData.integrationPackEnvironmentAttachment.findIndex((instance: any) => instance.integrationPackInstanceId === id) != -1;

    const getRowValue = (item: any, isInstalled: boolean) => {
        let isEnvMissingAtom = false;
        let rowValue = {};
        if (item.atoms?.length == 0) {
            isEnvMissingAtom = true;
            rowValue = {
                environmentName: item.name,
                id: item.id,
                attachmentStatus: 'no',
                isInstalled,
                installStatus: getInstallationStatus(isInstalled),
                classification: item.classification ? item.classification : 'NO classification set in BE',
            };
        } else {
            rowValue = {
                environmentName: item.name,
                id: item.id,
                attachmentStatus: 'yes',
                isInstalled,
                installStatus: getInstallationStatus(isInstalled),
                classification: item.classification ? item.classification : 'NO classification set in BE',
            };
        }
        return { rowValue, isEnvMissingAtom };
    };

    const getInstallationStatus = (isInstalled: boolean) => (isInstalled ? 'Installed' : 'Not Installed');

    useEffect(() => {
        setIsLoading(true);
        dispatch(setModalStatus(false));
    }, []);

    return {
        rowDetails,
        isLoading,
        isAnyEnvironmentMissingAtom,
        environments: installedData?.environments || [],
    };
};

export default useEditIpackTable;
