import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

import ButtonIcon from 'components/common/ButtonIcon/ButtonIcon';
import { IInput } from 'interfaces/IForm';

const useDefaultInputField = (type: IInput['type'], hiddenValue: boolean | undefined) => {
    const [inputType, setInputType] = useState<IInput['type']>(type || 'text');
    const { t } = useTranslation();

    useEffect(() => {
        if (hiddenValue) {
            setInputType('password');
        }
    }, []);

    const getInputButton = (fieldType: string | undefined) => {
        if (fieldType === 'password') {
            return <ButtonIcon label={t('btn.show')} icon={<BsEye size={20} />} handleClick={() => setInputType('text')} testId="showButton" />;
        }
        return <ButtonIcon label={t('btn.hide')} icon={<BsEyeSlash size={20} />} handleClick={() => setInputType('password')} testId="hideButton" />;
    };

    return { inputType, getInputButton };
};

export default useDefaultInputField;
