import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

export const Footer = () => {
    const { t } = useTranslation();
    const year = new Date().getFullYear();

    return (
        <div className="footer-wrapper" data-testid="footer-wrapper">
            <div className="ex-ml-2x-large ex-mr-2x-large">
                <div className="ex-row ex-py-small">
                    <div className="ex-col-xs-6">
                        <div className="ex-pt-x-small">{t('footer.copyright', { year: year })}</div>
                    </div>
                    <nav className="ex-col-xs-6">
                        <div className="footer-right">
                            <ul className="footer__nav">
                            <li className="footer__nav-item" data-testid="footer_platformStatus">
                                    <a href="https://status.boomi.com/"  >
                                        {t('footer.platformStatus')}
                                    </a>
                                </li>
                                <li className="footer__nav-divider"></li>
                                <li className="footer__nav-item" data-testid="footer_policy">
                                    <a href="https://boomi.com/privacy/" >
                                        {t('footer.policy')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Footer;
