import React, { FC } from 'react';

import Button from 'components/common/Button/Button';
import DefaultInputField from 'components/Form/DefaultInputField/DefaultInputField';
import Dropdown from 'components/common/DropdownField/Dropdown';
import useTransformNumber from 'hooks/useTransformNumber';
import { setModalType } from 'store/actions/generalActions';
import { IModal } from 'interfaces/IModal';
import { MOCK_DATA } from 'constants/dropdown/dropdownData';
import { NUMBER_FORMAT_INPUT } from 'constants/formData';
import { IFormField } from 'interfaces/IForm';
import styles from 'components/common/DialogBox/DialogBox.module.scss';

const NumberFormatModal: FC<IModal> = ({ closeModal }) => {
    const { t, dispatch, name, register, errors, setValue } = useTransformNumber();
    const { dialogTitle, dialogText, dialogSections, dialogActions, dialogActions_left, dialogActions_right, dialog_body } = styles;

    return (
        <>
            <div className={dialogTitle}>
                <h2>{t('dialogBox.numberFormat.title', { item: name })}</h2>
            </div>
            <p className={`${dialogText} ${dialog_body}`}>{t('dialogBox.numberFormat.description')}</p>

            <div className={dialogSections}>
                {NUMBER_FORMAT_INPUT.map((input: IFormField) => (
                    <DefaultInputField key={input.label} {...{ defaultValue: '', register, errors, setValue }} field={input} />
                ))}
                <Dropdown title="dialogBox.description" options={MOCK_DATA} selectedItem={MOCK_DATA[0].value} handleChange={() => undefined} contained fullWidth />
            </div>

            <div className={dialogActions}>
                <div className={dialogActions_left}>
                    <Button label={t('btn.back')} card light handleClick={() => dispatch(setModalType('transform'))} data-testid="numberFormatModal-back" />
                </div>
                <div className={dialogActions_right}>
                    <Button label={t('btn.cancel')} card light handleClick={() => closeModal()} data-testid="numberFormatModal-cancel" />
                    <Button label={t('btn.apply')} card handleClick={() => undefined} data-testid="numberFormatModal-apply" />
                </div>
            </div>
        </>
    );
};

export default NumberFormatModal;
