import { IPackInstallType } from 'constants/artifact';
import { ICardItem } from 'interfaces/ICardContainer';
import { IKeyStringValueString } from 'interfaces/IGeneral';
import { IEnvironmentItem } from 'interfaces/IInstalled';
import { IStore } from 'interfaces/IStore';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGuidParam } from './useQueryParam';

const useArtifactData = () => {
    const { pathname } = useLocation();
    const { guid } = useGuidParam();
    const { spaceGuid } = useSelector((state: IStore) => state.login);

    const getAvailableIpacksData = (installedIPacks: ICardItem[], allPacks: any) => {
        const installedPacks: IKeyStringValueString = {};
        installedIPacks.forEach((iPack: ICardItem) => {
            installedPacks[iPack.id] = iPack.installationType;
        });
        
        return allPacks.filter((installedIPack: ICardItem) => {
            if (!installedPacks[installedIPack.id]) {
                return true;
            }
            if (installedPacks[installedIPack.id] !== IPackInstallType.SINGLE) {
                return true;
            }
            return false;
        });
    };

    const parseInstalledIpackData = (response: any) => {
        const installedIPacks: ICardItem[] = [];
        response?.data?.integrationPackInstances?.forEach((element: IEnvironmentItem) => {
            response?.data.space.sharedIpacks?.some((ipack: ICardItem) => {
                if (ipack.id === element.integrationPack?.id) {
                    installedIPacks.push({
                        ...ipack,
                        name: element.integrationPackOverrideName || ipack.name,
                        integrationId: element.id || ipack.id,
                    });
                }
            });
        });
        return installedIPacks;
    };

    const getGuid = () => (guid && pathname === '/' ? guid : spaceGuid);

    return { getAvailableIpacksData, parseInstalledIpackData, getGuid };
};

export default useArtifactData;
