import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'components/common/DialogBox/DialogBox.module.scss';
import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/IStore';
import { IModal } from 'interfaces/IModal';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';

const TooltipModal: FC<IModal> = ({ closeModal }) => {
    const { dialogTitle, dialogText, dialogActions, dialogActions_right, dialog_body } = styles;
    const { t } = useTranslation();
    const selectedIPack = useSelector((state: IStore) => state.general.selectedIPack);
    return (
        <>
            <div className={dialogTitle}></div>
            <p className={`${dialogText} ${dialog_body}`}>{selectedIPack.description}</p>
            <div className={dialogActions}>
                <div className={dialogActions_right}>
                    <ExButton className="artifact-card__actions" slot="footer" flavor={ButtonFlavor.BRANDED} type={ButtonType.PRIMARY} size={ButtonSize.LARGE} onClick={closeModal}>
                        {t('btn.close')}
                    </ExButton>
                </div>
            </div>
        </>
    );
};

export default TooltipModal;
