export const ALL_AVAILABLE_IPACKS = (tempSpaceId: string) => `
  query space{ 
    space(id:"${tempSpaceId}"){ 
      name 
      description
      sharedIpacks { 
        description 
        id 
        name 
        installationType 
      } 
    } 
  }
`;

export const ENVIRONMENT_IPACKS = (tempSpaceId: string) => `
query space{
  space(id:"${tempSpaceId}"){
    sharedIpacks {
      description
      id
      name
      installationType
    }
  }

  integrationPackInstances{
    id
    integrationPack {
      id
    }
    integrationPackOverrideName
  }

  environments {
    id
    integrationPackEnvironmentAttachment {
        environmentId
        integrationPackInstanceId
    }

  }
}
`;

export const ENVIRONMENTS = `
query{
  environments {
    id
    name
    classification
    atoms {
      id
      name
    }
  }
}
`;

export const SPACE_DATA = (tempSpaceId: string) => `
  query space{
    space(id:"${tempSpaceId}"){
      name
      theme {
        logoUrl
        logoAltTxt
      }
    }
  }
`;

export const SPACE_ACCOUNTS = (searchCriteria: string) => `
query spaceAccounts
{
  spaceAccounts(criteria:{
    operator: CONTAINS,
    argument: "${searchCriteria}"
    
  })
  {
     id
     name
  }
}
`;

export const IPACK_ENV_ATTACHMENTS = `
query{
  environments {
    id
    name
    classification
    integrationPackEnvironmentAttachment {
      environmentId
      id
      integrationPackInstanceId
    }
  }
}
`;

export const NUMBER_OF_EXECUTION_HISTORY = 1000;
