import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/IStore';

const usePagination = (data: any, artifactState = '', pageSizeOptions = [10, 25, 50]) => {
    const { searchQuery } = useSelector((state: IStore) => state.general.search);
    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [maxPageNum, setMaxPageNum] = useState<number>(1);
    const [paginatedData, setPaginatedData] = useState(data.slice(selectedPage * pageSize - pageSize, selectedPage * pageSize));

    const handlePaginationChange = (e: any) => {
        if (e.detail.value) {
            setPageSize(parseInt(e.detail.value));
            setSelectedPage(1);
            setPaginatedData(data.slice(selectedPage * pageSize - pageSize, selectedPage * pageSize));
        } else {
            setPageSize(parseInt(e.detail.pageSize));
            setSelectedPage(e.detail.selectedPage);
            setPaginatedData(data.slice(selectedPage * pageSize - pageSize, selectedPage * pageSize));
        }
    };

    useEffect(() => {
        setPaginatedData(data.slice(selectedPage * pageSize - pageSize, selectedPage * pageSize));
        const maxPage = Math.ceil(data.length / pageSize);
        setMaxPageNum(Math.max(maxPage, 1));
    }, [data, selectedPage, pageSize]);

    useEffect(() => {
        setSelectedPage(1);
    }, [artifactState, searchQuery]);
    return {
        paginatedData,
        pageSize,
        selectedPage,
        handlePaginationChange,
        pageSizeOptions,
        maxPageNum,
    };
};

export default usePagination;
