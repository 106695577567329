import React, { FC } from 'react';

import Button from 'components/common/Button/Button';
import Dropdown from 'components/common/DropdownField/Dropdown';
import DefaultInputField from 'components/Form/DefaultInputField/DefaultInputField';
import useTransformPrecision from 'hooks/useTransformPrecision';
import { setModalType } from 'store/actions/generalActions';
import { IModal } from 'interfaces/IModal';
import { MOCK_DATA } from 'constants/dropdown/dropdownData';
import { SET_PRECISION_INPUT } from 'constants/formData';

import styles from 'components/common/DialogBox/DialogBox.module.scss';

const SetPrecisionModal: FC<IModal> = ({ closeModal }) => {
    const { t, dispatch, name, register, errors, setValue, handleSubmit, inputValidationOptions } = useTransformPrecision();
    const { dialogTitle, dialogText, dialogSections, dialogActions, dialogActions_left, dialogActions_right, dialog_body } = styles;

    return (
        <>
            <div className={dialogTitle}>
                <h2>{t('dialogBox.setPrecision.title', { item: name })}</h2>
            </div>
            <p className={`${dialogText} ${dialog_body}`}>{t('dialogBox.setPrecision.description')}</p>

            <div className={dialogSections}>
                <DefaultInputField validationOptions={inputValidationOptions} {...{ defaultValue: '', register, errors, setValue }} field={SET_PRECISION_INPUT} />

                <Dropdown title="dialogBox.description" selectedItem={MOCK_DATA[0].value} options={MOCK_DATA} handleChange={() => undefined} contained fullWidth />
            </div>

            <div className={dialogActions}>
                <div className={dialogActions_left}>
                    <Button label={t('btn.back')} card light handleClick={() => dispatch(setModalType('transform'))} data-testid="setPrecisionModal-back" />
                </div>
                <div className={dialogActions_right}>
                    <Button label={t('btn.cancel')} card light handleClick={() => closeModal()} data-testid="setPrecisionModal-cancel" />
                    <Button label={t('btn.apply')} card handleClick={handleSubmit(() => undefined)} data-testid="setPrecisionModal-apply" />
                </div>
            </div>
        </>
    );
};

export default SetPrecisionModal;
