import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IEnvironmentElement } from 'interfaces/IEnvironment';
import { ISourceProfileData } from 'interfaces/IGenericTable';
import { setDifferentEnvStep, setSameEnvConfiguration } from 'store/actions/generalActions';

const useTableWithCheckbox = <T extends IEnvironmentElement | ISourceProfileData>(
    setValue: (label: string, value: { id?: string; name: string; xpath?: string }[]) => void,
    environments: T[],
    clearErrors: (type: string) => void,
) => {
    const [checkedState, setCheckedState] = useState<boolean[]>(new Array(environments.length).fill(false));
    const [checkedAllState, setCheckedAllState] = useState(false);
    const dispatch = useDispatch();
    const selectedEnvironmentsNumber = checkedState.filter(Boolean).length;
    const handleOnChange = (rows: IEnvironmentElement[], noOfEnv?: number) => {
        const selectedState = new Array(environments.length).fill(false);

        environments.forEach((item: any, index: number) => {
            rows.forEach((env: IEnvironmentElement) => {
                if (env.id == item.id) {
                    selectedState[index] = true;
                }
            });
        });
        setCheckedAllState(rows.length === noOfEnv);
        setCheckedState(selectedState);
    };

    useEffect(() => {
        const allElementsAreTrue = checkedState.every((element) => element === true);
        const allElementsAreFalse = checkedState.every((element) => element === false);

        if (allElementsAreTrue) {
            setCheckedAllState(true);
        }

        if (allElementsAreFalse || !allElementsAreTrue) {
            setCheckedAllState(false);
        }

        const environmentsArr: { id?: string; name: string; xpath?: string }[] = [];

        checkedState.forEach((el: boolean, index: number) => {
            const environmentElement = environments[index];
            // type IEnvironmentElement
            if (el === true && 'id' in environmentElement) {
                environmentsArr.push({ id: environmentElement.id, name: environments[index].name });
            }
            // type ISourceProfileData
            if (el === true && 'xpath' in environmentElement) {
                environmentsArr.push({ xpath: environmentElement.xpath, name: environments[index].name });
            }
        });

        setValue('environments', environmentsArr);

        if (selectedEnvironmentsNumber > 0) {
            clearErrors('environments');
        }

        if (selectedEnvironmentsNumber === 1) {
            dispatch(setSameEnvConfiguration(true));
            dispatch(setDifferentEnvStep(0));
        }
    }, [checkedState]);

    const checkIfAnyEnvironmentMissingAtom = (environmentsData: IEnvironmentElement[]): boolean => {
        let missingAtomFound = false;
        environmentsData.forEach((environment: IEnvironmentElement) => {
            if (!environment.name) {
                return;
            }

            if (environment.atoms?.length == 0) {
                missingAtomFound = true;
            }
        });

        return missingAtomFound;
    };

    return { handleOnChange, checkedAllState, checkedState, selectedEnvironmentsNumber, checkIfAnyEnvironmentMissingAtom };
};

export default useTableWithCheckbox;
