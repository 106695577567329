import { RESET_LOGIN_STATE, SET_SPACE_GUID, SET_JWT, SET_TOKEN_ID, SET_TOKEN_SECRET, SET_ACCOUNT_SEARCH_CRITERIA, SET_ACCOUNT_ID, SET_USER_ID, SET_SPACE_ACCOUNT_ID } from './actionTypes';

export const setSpaceGuid = (payload: string) => {
    return {
        type: SET_SPACE_GUID,
        payload,
    };
};

export const setTokenId = (payload: any) => {
    return {
        type: SET_TOKEN_ID,
        payload,
    };
};

export const setTokenSecret = (payload: any) => {
    return {
        type: SET_TOKEN_SECRET,
        payload,
    };
};

export const setJwt = (payload: any) => {
    return {
        type: SET_JWT,
        payload,
    };
};

export const setAccountId = (payload: string) => {
    return {
        type: SET_ACCOUNT_ID,
        payload,
    };
};

export const setUserId = (payload: string) => {
    return {
        type: SET_USER_ID,
        payload,
    };
};

export const setSpaceAccountId = (payload: string) => {
    return {
        type: SET_SPACE_ACCOUNT_ID,
        payload,
    };
};

export const resetLoginState = (payload: any) => {
    return {
        type: RESET_LOGIN_STATE,
        payload,
    };
};

export const setAccountSearchCriteria = (payload: any) => {
    return {
        type: SET_ACCOUNT_SEARCH_CRITERIA,
        payload,
    };
};
