import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertBox from 'components/common/AlertBox/AlertBox';
import Spinner from 'components/common/Spinner/Spinner';
import { useLoginForm } from 'hooks/useLoginForm';

import Logo from 'assets/images/boomi-website-logo.svg';
import LoginImage from 'assets/images/loginBg.svg';
import style from './Login.module.scss';
import AccountDropdown from 'components/AccountDropdown/AccountDropdown';
import DialogBox from 'components/common/DialogBox/DialogBox';

const LoginForm = () => {
    const { wrapper, form, form_description, backgroundImgWrapper, footer, logo } = style;
    const { t } = useTranslation();
    const { isLoading, accountList, mounted, handleAccountSelection, onSearch, isAccountsLoading, searchQuery } = useLoginForm();
    const year = new Date().getFullYear();
    if (!mounted.current) {
        return null;
    }

    const renderAccountList = () => {
        if (!accountList.length && !searchQuery && !isAccountsLoading) {
            return <AlertBox type="warning" message={t('alert.noLoginAccounts')} />;
        }
        return (
            <div>
                <AccountDropdown
                    accountList={accountList.map((account: any) => ({ label: account.name.trim(), value: account.id }))}
                    onChange={handleAccountSelection}
                    searchPlaceholder={t('search.accountSearchPlaceholder')}
                    listHeader={''}
                    itemNotFound={t('search.accountNotFound')}
                    onSearch={onSearch}
                    displaySearchHeader={true}
                    isDataLoading={isAccountsLoading}
                    searchKeyword={searchQuery}
                />
            </div>
        );
    };

    return (
        <div className={wrapper}>
            <div className={form}>
                <img src={Logo} alt="logo" className={logo} />
                <h1 className={`${form_description} ex-mb-2x-large`}>{t('login.description')}</h1>
                {isLoading ? <Spinner /> : renderAccountList()}

                <div className={footer}>
                    <ul>
                        <li>{t('login.copyright', { year: year })}</li>
                    </ul>
                </div>
            </div>
            <div className={backgroundImgWrapper}>
                <img src={LoginImage} alt="placeholder-img" />
            </div>
            <DialogBox />
        </div>
    );
};

export default LoginForm;
