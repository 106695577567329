import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { IStore } from 'interfaces/IStore';

const useTransformDate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { name } = useSelector((state: IStore) => state.general.selectedSourceField);
    const {
        register,
        formState: { errors },
        setValue,
    } = useForm();    

    return { t, dispatch, name, register, errors, setValue };
}

export default useTransformDate;