import React, { FC } from 'react';

import useDaysBlock from 'hooks/useDaysBlock';
import { IDaysBlock } from 'interfaces/IDaysBlock';

import styles from 'pages/Schedule/DaysBlock.module.scss';
import scheduleBlockStyles from 'pages/Schedule/ScheduleBlock.module.scss';
import { ExCheckbox } from '@boomi/exosphere';

const DaysBlock: FC<IDaysBlock> = ({
    days,
    numOfCheckedDays,
    index: i,
    checkAllDays = false,
    scheduleBlocksToSend,
    setScheduleBlocksToSend,
    alreadyScheduledBlocksToSend,
    setAlreadyScheduledBlocksToSend,
}) => {
    const daysChecked = {
        days: days,
        numOfCheckedDays: numOfCheckedDays,
        index: i,
        checkAllDays: checkAllDays,
    };
    const { t, checkboxError, handleInputChange, checkedDays } = useDaysBlock(
        daysChecked,
        scheduleBlocksToSend,
        setScheduleBlocksToSend,
        alreadyScheduledBlocksToSend,
        setAlreadyScheduledBlocksToSend,
    );

    const { daysWrapper, daysWrapper_day, daysBlock_title } = styles;
    const { errorMessage } = scheduleBlockStyles;

    return (
        <>
            <p className={daysBlock_title}>{t('daysBlock.title')}</p>
            <div className={daysWrapper}>
                {days.map((el: { value: string }, index: number) => {
                    return (
                        <div className={daysWrapper_day} key={el.value}>
                            <ExCheckbox checked={checkedDays[index] || false} onChange={(e: CustomEvent<any>) => handleInputChange(e, index)}>
                                {t(`${el.value}`)}
                            </ExCheckbox>
                        </div>
                    );
                })}
            </div>
            {checkboxError && <p className={errorMessage}> {t('daysBlock.error.days')} </p>}
        </>
    );
};

export default DaysBlock;
