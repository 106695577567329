import React, { useEffect } from 'react';

import Spinner from 'components/common/Spinner/Spinner';
import useLogout from 'hooks/useLogout';

const Logout = () => {
    const {handleLogout} = useLogout();
    useEffect(()=>{
        handleLogout();
    },[])

    return <Spinner />;
};

export default Logout;
