const removeEmptyParams = (params: Record<string, any>): void => {
    for (const paramKey in params) {
        if (!params[paramKey]) {
            delete params[paramKey];
        }
    }
};

export const removeEmptyAuthorizationParams = (data: any): any => {
    for (const key in data) {
        const oauthOptionsAccessToken = data[key]?.['oauthOptions/OAuth2Config/authorizationParameters'];
        if (oauthOptionsAccessToken) {
            removeEmptyParams(oauthOptionsAccessToken);
        }

        const oauthOptionsAuth = data[key]?.['oauthOptions/OAuth2Config/accessTokenParameters'];
        if (oauthOptionsAuth) {
            removeEmptyParams(oauthOptionsAuth);
        }
    }
    return data;
};
