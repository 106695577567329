export const cleanEmptyKeys = (data: any) => {
    const cleanedData = { ...data };
    for (const key in cleanedData['0']) {
        if (cleanedData['0'][key] === '' || (typeof cleanedData['0'][key] === 'object' && Object.keys(cleanedData['0'][key]).includes('') && cleanedData['0'][key][''] === '')) {
            delete cleanedData['0'][key];
        }
    }
    return cleanedData;
};

export const removeEmptyKeys = (catalogsvcPayload: any) => {
    for (const key in catalogsvcPayload) {
        if (key === '') {
            delete catalogsvcPayload[key];
        }
    }
};
