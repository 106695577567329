import { IEnvironmentExtensions } from 'interfaces/IConfigureConnection';
import { SECRET_FIELDS } from 'constants/formFields';
import { IProcessProperty, IPropertyField } from 'interfaces/IProcessProperty';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNewPropertiesData = (sameEnvConfiguration: boolean | undefined, extensions: any, step: number, data: any) => {
    const extensionsObject = sameEnvConfiguration ? 0 : step;

    return extensions.map((item: IEnvironmentExtensions, index: number) => {
        if (index !== extensionsObject) {
            return item;
        }

        return {
            ...item,
            processProperties: {
                ...item.processProperties,
                ProcessProperty: item.processProperties.ProcessProperty.map((el: IProcessProperty, i: number) => {
                    return {
                        ...el,
                        ProcessPropertyValue: el.ProcessPropertyValue.map((field: IPropertyField) => {
                            if (data[i]?.[field.label] === '') {
                                return {
                                    ...field,
                                    useDefault: true,
                                    value: '',
                                };
                            }

                            if (!data[i]?.[field.label]) {
                                return {
                                    ...field,
                                    useDefault: true,
                                };
                            }

                            if (SECRET_FIELDS.includes(field.label)) {
                                return {
                                    ...field,
                                    value: data[i]?.[field.label],
                                    encryptedValueSet: true,
                                    usesEncryption: true,
                                    useDefault: false,
                                };
                            }

                            return {
                                ...field,
                                value: data[i]?.[field.label],
                                useDefault: false,
                            };
                        }),
                    };
                }),
            },
        };
    });
};
