import { IConnection } from 'interfaces/IConfigureConnection';
import { IStore } from 'interfaces/IStore';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewConnectionsData } from 'services/getNewConnectionsData';
import { updateExtensions } from 'store/actions/generalActions';
import useHandleResponseErrors from './useHandleResponseErrors';
const useConnectionForm = (sameEnvConfiguration: boolean, extensions: any, envs: string[], step: number) => {
    const { networkError, isEditInstall } = useSelector((state: IStore) => state.general);
    const dispatch = useDispatch();
    const { handleResponseErrors, handleResponseErrorsForEditInstall } = useHandleResponseErrors();

    const getConnectionFormData = (data: any, processId: string) => {
        if (sameEnvConfiguration) {
            const updatedExtensions: any[] = [...extensions];
            extensions.forEach((item: any, i: number) => {
                if (item.extensionGroupId === processId) {
                    const updatedObject = getNewConnectionsData(false, extensions, i, data);
                    updatedExtensions[i] = updatedObject[i];
                }
            });
            dispatch(updateExtensions(updatedExtensions));
        } else {
            const extensionIndex = extensions.findIndex((item: any) => item.extensionGroupId === processId && item.environmentId === envs[step]);
            const updatedObject = getNewConnectionsData(sameEnvConfiguration, extensions, extensionIndex, data);
            dispatch(updateExtensions(updatedObject));
        }
    };

    const getConnectionData = (process: string) => {
        let connectionData: IConnection[] = [];
        if (sameEnvConfiguration) {
            connectionData = extensions.find((item: any) => item.extensionGroupId === process)?.connections?.connection || [];
        } else {
            connectionData = extensions.find((item: any) => item.extensionGroupId === process && item.environmentId === envs[step])?.connections?.connection || [];
        }
        return connectionData;
    };

    useEffect(() => {
        if (networkError) {
            if (isEditInstall) {
                handleResponseErrorsForEditInstall();
            } else {
                handleResponseErrors();
            }
        }
    }, [networkError]);

    return { getConnectionFormData, getConnectionData };
};

export default useConnectionForm;
