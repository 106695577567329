import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/IStore';
import { useQueryParam } from './useQueryParam';
import { updateExtensions, updateMappers } from 'services/getDynamicQuery';

const useExtensionUpdate = () => {
    const { extensions, dataMaps } = useSelector((state: IStore) => state.general);
    const { envIds } = useQueryParam();
    const generateConnectionsPropertiesPayLoad = () => {
        const extensionQueries: any = {};
        if (extensions.length) {
            envIds?.split(',').forEach((env) => {
                const processExtensionsForEnv = extensions.filter((ext) => ext?.environmentId === env);
                extensionQueries[env] = [];
                for (const extension of processExtensionsForEnv) {
                    const extensionObj = `{extensions: ${JSON.stringify(JSON.stringify(extension))}, envExtensionId: ${JSON.stringify(extension.id)}}`;
                    const { updateExtensionQuery } = updateExtensions(extensionObj);
                    extensionQueries[env].push(updateExtensionQuery);
                }
            });
        }
        return extensionQueries;
    };

    const generateDataMapsPayLoad = () => {
        const dataMapQueries: any = {};
        const envDataMaps: any = {};

        if (dataMaps.length) {
            const envs = envIds.split(',');
            envs.forEach((env) => {
                const envDataMap: any[] = [];
                dataMaps.forEach((dataMapArr) => {
                    dataMapArr.forEach((dataMap) => {
                        if (dataMap.Result.environmentId === env) {
                            envDataMap.push(dataMap);
                        }
                    });
                });
                envDataMaps[env] = envDataMap;
            });

            for (const env of Object.keys(envDataMaps)) {
                const dataMapArr = envDataMaps[env];

                let dataMapsData = '';
                for (const dataMap of dataMapArr) {
                    const dataMapsObj = `{extensions: ${JSON.stringify(JSON.stringify(dataMap.Result))}, envExtensionId: ${JSON.stringify(dataMap?.Result?.id as string)}}`;
                    dataMapsData = dataMapsData ? dataMapsData + ',' + dataMapsObj : dataMapsObj;
                }
                const { updateMappersQuery } = updateMappers(dataMapsData);
                dataMapQueries[env] = updateMappersQuery;
            }
        }
        return dataMapQueries;
    };

    return {
        generateDataMapsPayLoad,
        generateConnectionsPropertiesPayLoad,
    };
};

export default useExtensionUpdate;
