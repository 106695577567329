import React, { FC, useEffect, useRef, useState } from 'react';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton, ExCard, ExIconButton, IconButtonFlavor, IconButtonType, IconVariant } from '@boomi/exosphere';
import { IArtifactCard } from 'interfaces/ICardContainer';
import './ArtifactCard.scss';
import useIPackDetails from 'hooks/useIPackDetails';
import { useTranslation } from 'react-i18next';
import { ArtifactState } from 'constants/artifact';
import useArtifactCard from '../../hooks/useArtifactCard';

const ArtifactCard: FC<IArtifactCard> = ({ item, artifactState }) => {
    const { t } = useTranslation();
    const titleRef = useRef<HTMLHeadingElement>(null);
    const { getIPackCardDetails } = useIPackDetails();
    const [tooltip, setTooltip] = useState(false);
    const { name, description, installationType } = item;
    const { isSingleInstall, typeLabel } = getIPackCardDetails(installationType);
    const { handleInstall, handleEditClick, handleScheduleRunClick, handleTooltipModal, handleSingleIpackDeleteButton } = useArtifactCard(item, isSingleInstall);

    useEffect(() => {
        if ((titleRef?.current && Math.round(titleRef.current.scrollHeight / titleRef.current.clientHeight) > 1) || (item?.description && item?.description.trim()?.length > 135)) {
            setTooltip(true);
        } else {
            setTooltip(false);
        }
    }, [item, artifactState]);

    return (
        <ExCard className="artifact-card ex-col-xxl-3 ex-col-lg-4 ex-col-md-6 ex-col-sm-12 ex-col-xs-12 ex-col-any ex-col-xs">
            <div className="ex-mb-small artifact-card__type">{t(`label.${typeLabel}`)}</div>
            <div className="artifact-card__content">
                <h2 className="ex-mb-standard artifact-card__title" ref={titleRef}>
                    {name}
                </h2>
                <div className="artifact-card__desc">{description}</div>
                {tooltip && (
                    <div className="artifact-card__readmore" onClick={() => handleTooltipModal(item)}>
                        {t('label.readMore')}
                    </div>
                )}
            </div>
            <div className={`artifact-card__actions artifact-card__actions-${artifactState === ArtifactState.INSTALLED ? 'installed' : ''}`}>
                {artifactState === ArtifactState.INSTALLED ? (
                    <>
                        {!isSingleInstall && (
                            <ExIconButton
                                icon="pencil"
                                tooltipText="Edit"
                                className="artifact-card__actions-edit ex-mr-x-small"
                                variant={IconVariant.DEFAULT}
                                onClick={handleEditClick}
                                type={IconButtonType.SECONDARY}
                                flavor={IconButtonFlavor.BASE}
                            >
                                {t('btn.edit')}
                            </ExIconButton>
                        )}
                        {isSingleInstall && (
                            <ExIconButton
                                icon="delete"
                                tooltipText="Delete"
                                className="artifact-card__actions-uninstall ex-ml-small"
                                variant={IconVariant.ICON}
                                onClick={handleSingleIpackDeleteButton}
                                type={IconButtonType.TERTIARY}
                                data-testid="singleIpack-uninstall"
                            >
                                {t('btn.uninstallAll')}
                            </ExIconButton>
                        )}
                        <ExButton className="artifact-card__actions-schedule" type={ButtonType.SECONDARY} flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} onClick={handleScheduleRunClick}>
                            {t('btn.scheduleAndRun')}
                        </ExButton>
                    </>
                ) : (
                    <>
                        <ExButton className="artifact-card__actions-install" type={ButtonType.SECONDARY} flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} onClick={handleInstall}>
                            {t('btn.install')}
                        </ExButton>
                    </>
                )}
            </div>
        </ExCard>
    );
};

export default ArtifactCard;
