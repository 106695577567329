import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import AlertBox from 'components/common/AlertBox/AlertBox';
import useTableWithCheckbox from 'hooks/useTableWithCheckbox';
import { ITableWithCheckbox } from 'interfaces/ITableWithCheckbox';
import { IEnvironmentElement } from 'interfaces/IEnvironment';
import { ISourceProfileData, ITableItem } from 'interfaces/IGenericTable';
import { ExTable } from '@boomi/exosphere';
import styles from 'components/Form/Table/Table.module.scss';

interface IGridReadyParams {
    api: {
        sizeColumnsToFit: () => void;
    };
}
export const TableWithCheckbox = <T extends IEnvironmentElement | ISourceProfileData>({
    data,
    children,
    title,
    required,
    setValue,
    errors,
    clearErrors,
    emptyErrorLabel,
    headerName,
}: ITableWithCheckbox<T>) => {
    const { t } = useTranslation();
    const { tableItem, tableLabel, fieldError, tableWrapper } = styles;
    const [rowData, setRowData] = useState<ITableItem[]>([]);
    const [isDataRefresh, setIsDataRefresh] = useState(true);
    useEffect(() => {
        setRowData(data);
        setIsDataRefresh(false);
    }, []);
    const { handleOnChange, selectedEnvironmentsNumber, checkIfAnyEnvironmentMissingAtom } = useTableWithCheckbox(setValue, data, clearErrors);

    const dataMapGridOptions: any = useMemo(() => {
        const environmentRenderer = (params: any) => {
            const environment: IEnvironmentElement | ISourceProfileData = params.data;
            if (isAnyEnvironmentMissingAtom && isIEnvironmentElement(environment) && !environment.atoms?.length) {
                params.node.selectable = false;
            }
            return `<ex-tooltip position="top" alignment="start"><span className="${tableItem}">${environment.name}</span><span className="${tableItem}" slot="anchor">${environment.name}</span></ex-tooltip>`;
        };

        const atomRenderer = (params: any) => {
            const environment: IEnvironmentElement | ISourceProfileData = params.data;

            return isAnyEnvironmentMissingAtom && isIEnvironmentElement(environment) && environment.atoms?.length
                ? ` <ex-tooltip position="top" alignment="start">
              <div>Atom(s) attached</div>
              <span slot="anchor"><div style="display:flex;align-items: center;height:100%;">
              <ex-icon hide-browser-tooltip="true" style="font-size:1.5rem; margin-right:0.5rem" icon="status-success" className="status-icon" variant="secondary">
              </ex-icon>
              </div></span>
              </ex-tooltip>`
                : isAnyEnvironmentMissingAtom &&
                      isIEnvironmentElement(environment) &&
                      `<ex-tooltip position="top" alignment="start">
    <span style="white-space: initial;word-break: break-word;">${t('alert.noEnvironmentAtomInInstallIpackPage')}</span>
    <span slot="anchor">
    <div style="display:flex; align-items: center;height:100%;">
    <ex-icon hide-browser-tooltip="true" style="font-size:1.5rem; margin-right:0.5rem" icon="status-warning" className="status-icon" variant="danger"></ex-icon>
    </div>
    </span>
   </ex-tooltip>
    `;
        };
        const isEnvironmentMissingAtom = checkIfAnyEnvironmentMissingAtom(data as IEnvironmentElement[]);
        const columnDefs = [
            {
                headerName: t('tableWithCheckbox.environmentName'),
                field: 'environmentName',
                tooltipField: t(headerName),
                cellRenderer: environmentRenderer,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: isEnvironmentMissingAtom ? 320 : '100%',
            },
            {
                headerName: t('tableWithCheckbox.attachmentStatus'),
                field: 'atomName',
                tooltipField: t('alert.noEnvironmentAtomInInstallIpackPage'),
                cellRenderer: atomRenderer,
                checkboxSelection: false,
                hide: isEnvironmentMissingAtom ? false : true,
                supressToolPanel: isEnvironmentMissingAtom ? false : true,
                maxWidth: 320,
            },
        ];
        return {
            defaultColDef: {
                sortable: false,
            },
            animateRows: true,
            suppressRowTransform: true,
            columnDefs,
            rowData,
            domLayout: 'autoHeight',
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            onGridReady: (params: IGridReadyParams) => {
                params.api.sizeColumnsToFit();
            },
            onGridSizeChanged: (params: IGridReadyParams) => {
                params.api.sizeColumnsToFit();
            },
            onSelectionChanged: (event: any) => {
                const rows = event.api.getSelectedRows();
                handleOnChange(rows, rowData.length);
            },
            onFirstDataRendered: (params: any) => {
                params.api.forEachNode((node: any) => {
                    return node.setSelected(false);
                });
            },
            onRowDataChanged(params: any): void {
                params.api.getDisplayedRowAtIndex(0).setSelected(true);
            },
            popupParent: document.querySelector('.map-modal'),
            cacheQuickFilter: false,
            rowHeight: 48,
        };
    }, [rowData, t]);

    const isIEnvironmentElementArray = function (object: any): object is IEnvironmentElement[] {
        return true;
    };

    const isIEnvironmentElement = function (object: any): object is IEnvironmentElement {
        return true;
    };

    let isAnyEnvironmentMissingAtom = false;
    if (isIEnvironmentElementArray(data)) {
        isAnyEnvironmentMissingAtom = checkIfAnyEnvironmentMissingAtom(data);
    }

    return (
        <>
            <p className={tableLabel}>
                {t(title)}
                {required && <span>({t('form.required')})</span>}
            </p>
            {data.length ? (
                <div>
                    <div className={`${tableWrapper} env-selection-table-wrapper`}>{!!data.length && !isDataRefresh && dataMapGridOptions && <ExTable gridOptions={dataMapGridOptions}  overflowVisible={true}></ExTable>}</div>
                </div>
            ) : (
                <AlertBox type="warning" message={emptyErrorLabel} />
            )}
            {errors.environments && (
                <span role="alert" className={fieldError}>
                    This field is required
                </span>
            )}
            {selectedEnvironmentsNumber > 1 && children}
        </>
    );
};

export default TableWithCheckbox;
