import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { APPEND_INPUT } from 'constants/formData';
import { IStore } from 'interfaces/IStore';

const useTransformAppend = () => {
    const defaultAppendValue = 'dialogBox.append.dropdown.options.append';
    const [appendOption, setAppendOption] = useState<string>(defaultAppendValue);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { name } = useSelector((state: IStore) => state.general.selectedSourceField);
    const {
        register,
        formState: { errors },
        setValue,
    } = useForm();

    const getAppendOptionsList = (source: string) => {
        const list = [{ value: defaultAppendValue }];
        list.push({ value: `to ${source}` });

        return list;
    };

    const handleOnChange = (e: CustomEvent) => {
        setAppendOption(e.detail.value);
    };

    const appendOptions = getAppendOptionsList(name);
    const filteredInputs = APPEND_INPUT.filter((el) => el.inputName !== APPEND_INPUT[0].inputName);
    const inputFieldList = appendOption !== defaultAppendValue ? filteredInputs : APPEND_INPUT;

    return { t, dispatch, name, register, errors, setValue, appendOption, appendOptions, inputFieldList, handleOnChange };
};

export default useTransformAppend;
