const en = {
    label: {
        browse: 'Browse',
        home: 'Home',
        installed: 'Installed',
        activity: 'Activity',
        logout: 'Log Out',
        multiInstall: 'Integration',
        singleInstall: 'Single install integration',
        readMore: 'Read more',
        notMapped: 'Not Mapped',
        mapAction: 'Map',
        transformData: 'Transform Data',
        mappedTo: ' Mapped to ',
        StringToLower: 'String to Lowercase ',
        Append: 'Append ',
        Sum: 'Sum ',
        SimpleLookup: 'Simple Lookup ',
        and: 'and',
        loading: 'Loading...',
        toggleArtifactState: 'Show Installed Only',
    },

    dropdown: {
        optionOne: 'Sort by most popular',
        optionTwo: 'Sort by option 2',
        optionThree: 'Sort by option 3',
        placeholder: 'Select an option',
        allFields: 'All Fields',
        allMaps: 'All Maps',
        mapStatus: 'Map Status',
        actions: 'Actions',
        title: 'View',
        dateFormat: 'Date Format',
        numberFormat: 'Number Format',
        setPrecision: 'Set Precision',
        append: 'Append',
        stringToLowercase: 'String to Lowercase',
        trimWhitespace: 'Trim Whitespace',
        dataTransformationType: 'Select Data Transformation Type',
        select: 'Select',
        selectField: 'Select Field',
        selectFilter: 'Select Filter',
        notMapped: 'Not Mapped',
        mapped: 'Mapped',
        noMoreCategories: 'No more categories',
    },

    search: {
        integrations: 'Search for integrations',
        title: 'Search',
        byFieldName: 'Search by field name',
        filterFields: 'Filter Fields',
        confirmationTitle: 'Search',
        confirmationPlaceholder: 'Search list by process name',
        accountSearchPlaceholder: 'Search by Account Name, ID',
        accountNotFound: 'No matching accounts',
    },

    btn: {
        cancelAndUninstall: 'Cancel And Uninstall',
        continue: 'Continue',
        done: 'Done',
        edit: 'Edit',
        install: 'Install',
        schedule: 'SCHEDULE',
        scheduleAndRun: 'Schedule/Run',
        cancel: 'Cancel',
        next: 'NEXT',
        apply: 'Apply',
        back: 'BACK',
        show: 'SHOW',
        hide: 'HIDE',
        viewExecutionHistory: 'View Process Reporting',
        uninstall: 'UNINSTALL',
        uninstallAll: 'Uninstall Integration',
        unattach: 'UNATTACH',
        logout: 'Logout',
        close: 'Close',
        continueProcessRun: 'Continue Process Run',
        generate: 'Generate Token',
        regenerate: 'Re-Generate Token',
        encryptCS: 'Encrypt Client Secret',
        resetCS: 'Reset Client Secret',
        addProperty: 'Add Property',
    },

    pagination: {
        next: 'Next >',
        previous: '< Previous',
    },

    blankState: {
        message: 'No integrations available for your account yet.',
        description: 'When integrations are made available they will appear here.',
        search: {
            message: 'No integrations match your search criteria.',
            description: 'Try another search.',
        },
        accountSearch: 'No matching accounts',
    },

    form: {
        name: 'Name',
        email: 'Email',
        required: 'required',
        installAndConfigure: 'Install & Configure Integration',
        selectEnv: 'Select Environment(s)',
        selectFieldsToMap: 'Select fields to map the source field {{fieldName}} to:',
        encryptField: 'Enter client secret and encrypt to enable Generate/Regenerate Token button',
        encryptRegenerateField: 'Enter client secret and encrypt to enable Generate/Regenerate Token button',
        encryptErrorField: '{{handleEncryptionError}}',
        encryptInfo: 'To update the client secret, click on the "Reset Client Secret" button.',
        radioGroup: {
            sameConfiguration: {
                title: 'Use the same configuration in each selected environment',
                yes: 'Yes, use the same configuration in each selected environment',
                no: 'No, use different configurations in each selected environment',
            },

            dataMaps: {
                title: 'Do you need to map additional fields for this integration?',
                yes: 'Yes, additional field mapping is required for this integration.',
                no: 'No, additional field mapping is not required for this integration.',
            },
        },
    },

    installPage: {
        title: 'Install & Configure Integration',
        subtitle: 'Integration Name and Environment',
        installHeader: 'Install & Configure {{titleVariable}} Install Integration {{titleVariable2}}',
        connections: 'Connections in Process',
        properties: 'Properties in Process',
        dataMaps: 'Data maps in Process',
        successfulInstalation: 'Your integration was successfully installed.',
        successfulInstalationDescription: 'Each Integration process can run on a schedule or run on demand. To run the process immediately, set a run Schedule for each process or select Run Now.',
        inputLabel: 'Integration Name',
    },

    editInstallPage: {
        title: 'Edit Integration  {{titleVariable2}}',
    },

    propertiesPage: {
        properties: 'Properties - ',
        extraInfo: 'Leave any field blank to use the default value for that field',
    },

    activityPage: {
        title: 'Integration Process Reporting',
        header: 'Process Reporting',
        description: 'View your integration process executions. This may return a maximum of {{numberOfExecutionHistoryResults}} execution records.',
    },

    alert: {
        noEnvironments:
            'Your account does not have any environments. To configure and run the installed integration, you need to deploy to an environment. Contact your system administrator for assistance.',
        noMapItems: 'All destination fields are mapped. To map this field, delete an existing field map or data transformation.',
        noAvailableFields: 'No target fields match your field filter.',
        noLoginAccounts: 'We could not find an account associated with a Space. Contact your system administrator for assistance.',
        noAtomAttached:
            '{{messageVariable}} does not have any atoms. To schedule or run the installed integration, you need at least one attached atom. Contact your system administrator for assistance.',
        noEnvironmentAtomInInstallIpackPage: 'Atom(s) not attached.',
        noEnvironmentAtomInEditIpackPage:
            'Atom(s) not attached. If you continue to install the integration, it will not run successfully. This happens when one or more environments do not have attachments. Contact your system administrator for assistance.',
        noEnvironmentAttachedInEditIpackPage:
            'The environment(s) and integration packs installed do not exist anymore or detached manually. Here is what happens ,The integration pack will be removed from your installed list. To use again, you can reinstall it from the browse page. If you need further assistance, contact your system administrator.',
        atomsAttached: 'Atom(s) attached',
    },

    dialogBox: {
        logout: {
            title: 'Do you want to logout?',
            message: 'We will log you out from all your current active sessions on other computer and devices',
            cancel: 'Cancel',
            logout: 'logout',
        },
        uninstall: {
            title: 'Uninstall Integration?',
            message:
                'Uninstalling the {{messageSubject}} will permanently remove the integration from your account. You will lose your data, configuration, and integration activity. You may reinstall the integration from the Browse page.',
            cancel: 'Cancel',
            uninstall: 'Uninstall',
        },

        uninstallIpackFromEditPage: {
            title: 'Uninstall Integration?',
            message: 'If you uninstall the {{messageSubject}} Integration, it will remove the integration from your account. You will lose your data, configuration, and integration activity.',
            cancel: 'Cancel',
            uninstall: 'Uninstall Integration',
        },

        uninstallFromLastEnv: {
            title: 'Uninstall Integration?',
            message:
                'Uninstalling the {{messageSubject}} Integration from the {{envName}} environment will also uninstall the Integration because this is the only environment in which the Integration is installed.',
            cancel: 'Cancel',
            uninstall: 'Uninstall Integration',
        },

        unattach: {
            title: 'Uninstall Integration from {{envName}} environment?',
            message:
                'Uninstalling the {{ipackName}} will permanently remove the integration from the {{envName}} environment. You will lose your data, configuration, and integration activity associated with this environment.',
            cancel: 'Cancel',
            unattach: 'Uninstall Integration',
        },
        map: {
            title: 'Select Data Transformation Type',
            sourceField: 'Map Source Field - {{fieldName}}',
        },
        trimWhitespace: {
            description: 'Remove whitespace from the beginning and end of the source field data.',
            title: 'Trim Whitespace - {{item}}',
        },
        stringToLowercase: {
            description: 'Converts the source field to lowercase.',
            title: 'String to Lowercase - {{item}}',
        },

        setPrecision: {
            description: 'Set the number of decimal places for the source field.',
            title: 'Set Precision - {{item}}',
            inputLabel: 'Set the Number of Decimal Places',
            inputDescription: 'Must be a whole number 0 or greater.',
        },

        dateFormat: {
            description: 'To transform the outgoing date format of the source field, specify the incoming date format and the output date format.',
            title: 'Outgoing Date Format - {{item}}',
            inputLabel: {
                incomingDate: 'Format of Incoming Date',
                outputDate: 'Format of Output Date',
            },
            inputDescription: "Examples: MMddyyyy, yyyy-MM-dd'T'HH:mm:ssZ, h:mm a, and yyyy-MM-dd",
        },

        numberFormat: {
            description: 'To transform the number format of the source field, specify the incoming number format and the output number format.',
            title: 'Number Format - {{item}}',
            inputLabel: {
                incomingNumber: 'Format of Incoming Number',
                outputNumber: 'Format of Output Number',
            },
            inputDescription: 'Examples: #,##0, $###,###.###, 000000.000, ###,###.###, and ###.##',
        },

        append: {
            description: 'To append, add characters to the end of the source field. You can also add the source field to the end of another source field.',
            title: 'Append - {{item}}',
            inputLabel: {
                characters: 'Characters to Append',
                length: 'Fix to Length',
            },
            dropdown: {
                label: 'Append',
                options: {
                    append: 'Characters',
                    to: 'to',
                },
            },
            inputDescription: 'Leaving blank will not change the length of the result.',
        },

        transform: {
            title: 'Transform Data - {{item}}',
        },

        runNow: {
            loading: 'Initiating Integration Pack {{ processName }}',
            title: {
                success: 'Process Successfully Initiated',
                failed: 'Process Failed to Initiate',
            },
            description: {
                success: '{{processName}} is successfully initiated. Check the status of this process run on Process Reporting.',
                failed: '{{processName}}, failed to initiate. {{backendMessage}}',
            },
        },
        runNowConfirmation: {
            title: 'Initiate process run?',
            message: 'Are you sure you want to execute {{processName}}?',
            cancel: 'Cancel',
            continueProcessRun: 'Continue Process Run',
        },
        openingAccessToken: {
            title: 'Opening Access Token',
            message: 'Third-party application will open in a new tab.',
            headercontent: 'info',
        },
        initializingOAuthToken: {
            title: 'Initializing OAuth Token.',
            message: 'Boomi is initializing OAuth Token with third-party application.',
            headercontent: 'info',
        },
        accessTokenSucess: {
            title: 'Access Token successfully generated',
            message: 'Boomi successfully generated token from third-party application.',
            headercontent: 'positive',
        },
        accessTokenFailed: {
            title: 'Authorization code not generated',
            message:  '{{errorMessage}}',
            headercontent: 'negative',
            error:'An error occurred while generating an authorization code. Try generating a new code.'
        },
        description: 'Map Transformation Result to Selected Field',
    },

    error: {
        404: {
            title: 'Error 404',
            description: 'The page you are looking for does not exist',
        },
        500: {
            available: 'There was a problem retrieving the integrations available to your account.',
            installed: 'There was a problem retrieving the integrations installed to your account.',
            history: 'There was a problem retrieving the processes reporting to your account.',
            confirmation: 'There was a problem retrieving the available processes for this IPack.',
            editIpack: 'There was a problem retrieving the integration Environment data.',
        },
        btn: 'Browse integrations',
        transformationType: 'Select a transformation type.',
        encryptionError: 'Error while encrypting client secret. Please check your client secret and try again',
        oauthAccessTokenError: 'Error while generating OAuth token',
    },

    tableWithCheckbox: {
        environment: 'Select Environment(s)',
        environmentName: 'Environment Name',
        attachmentStatus: 'Status',
        targetField: 'Target Field Name',
    },

    toast: {
        info: 'The changes will be reflected once you complete the whole install flow.',
    },

    processesHistoryTable: {
        columns: {
            name: 'Process Name',
            status: 'Status',
            timeStamp: 'TimeStamp',
        },
        complete: 'Complete',
        error: 'Error',
        processDetails: {
            message: 'Message',
            errorTip: 'Check your integration configuration or contact your account administrator if this error persists.',
            counters: {
                duration: 'Duration',
                type: 'Type',
                inboundDocuments: 'Inbound Documents',
                inboundErrors: 'Inbound Errors',
                outboundDocuments: 'Outbound Documents',
            },
        },
    },

    confirmationTable: {
        columns: {
            name: 'Process Name',
            status: 'Schedule Status',
            actions: 'Actions',
        },
        runNow: 'Run Now',
        schedule: 'Schedule',
        notScheduled: 'Not Scheduled',
        scheduled: 'Scheduled',
    },

    ConfigureConnectionTable: {
        columns: {
            key: 'Key',
            value: 'Value',
            action: 'Action',
        },
        error: { 
            accessTokenError: 'Keys must not be duplicated.',
            authorizationError: 'Keys must not be duplicated.',
            keyEmptyError:'Key must not be empty'
        },
    },

    setSchedule: {
        name: '{{ipackName}}',
        title: 'Schedule - {{processTitle}}',
        description: 'How often would you like this process to run? You can set multiple schedules to run different processes.',
        addSchedule: 'Add New Schedule',
        save: 'Save',
        cancel: 'Cancel',
    },

    editIpack: {
        title: 'Edit Integration  {{titleVariable2}}',
        success: 'Run or Schedule a Process ',
        information: 'Each Integration process can run on a schedule or run on demand. To run the process immediately, set a run Schedule for each process or select Run Now.',
        subTitle: 'Your Integration was successfully Updated.',
        columns: {
            environmentName: 'Environment Name',
            attachmentStatus: 'Attachment Status',
            installStatus: 'Installation Status',
            actions: 'Actions',
        },
        environments_one: 'Environment',
        environments_other: 'Environment(s)',
    },
    scheduleBlock: {
        hour0: '00',
        hour1: '01',
        hour2: '02',
        hour3: '03',
        hour4: '04',
        hour5: '05',
        hour6: '06',
        hour7: '07',
        hour8: '08',
        hour9: '09',
        hour10: '10',
        hour11: '11',
        hour12: '12',
        hour13: '13',
        hour14: '14',
        hour15: '15',
        hour16: '16',
        hour17: '17',
        hour18: '18',
        hour19: '19',
        hour20: '20',
        hour21: '21',
        hour22: '22',
        hour23: '23',
        minutes00: '00',
        minutes01: '01',
        minutes02: '02',
        minutes03: '03',
        minutes04: '04',
        minutes05: '05',
        minutes06: '06',
        minutes07: '07',
        minutes08: '08',
        minutes09: '09',
        minutes10: '10',
        minutes11: '11',
        minutes12: '12',
        minutes13: '13',
        minutes14: '14',
        minutes15: '15',
        minutes16: '16',
        minutes17: '17',
        minutes18: '18',
        minutes19: '19',
        minutes20: '20',
        minutes21: '21',
        minutes22: '22',
        minutes23: '23',
        minutes24: '24',
        minutes25: '25',
        minutes26: '26',
        minutes27: '27',
        minutes28: '28',
        minutes29: '29',
        minutes30: '30',
        minutes31: '31',
        minutes32: '32',
        minutes33: '33',
        minutes34: '34',
        minutes35: '35',
        minutes36: '36',
        minutes37: '37',
        minutes38: '38',
        minutes39: '39',
        minutes40: '40',
        minutes41: '41',
        minutes42: '42',
        minutes43: '43',
        minutes44: '44',
        minutes45: '45',
        minutes46: '46',
        minutes47: '47',
        minutes48: '48',
        minutes49: '49',
        minutes50: '50',
        minutes51: '51',
        minutes52: '52',
        minutes53: '53',
        minutes54: '54',
        minutes55: '55',
        minutes56: '56',
        minutes57: '57',
        minutes58: '58',
        minutes59: '59',
        schedule: 'Schedule {{number}}',
        runEvery: 'This Process Runs Every',
        scheduleType: 'Schedule Type',
        interval: 'Interval',
        runFrom: 'Start Time',
        runUntil: 'End Time',
        description: {
            minutes: 'Between (1-59)',
            hour: 'Between (1-23)',
            day: 'Between (1-7)',
        },
        hr: 'Hr.',
        min: 'Min.',
        delete: 'Delete Schedule',
        error: {
            minutes: 'Interval must be from 1 to 30.This process may be set to run once every 1 to 30 minutes.',
            hour: 'Interval must be from 1 to 12.This process may be set to run once every 1 to 12 hours.',
            days: 'Interval must be from 1 to 14.This process may be set to run once every 1 to 14 days.',
            hourRange: 'Start Time should not be greater than End Time.',
        },
        advancedSchedule: {
            subtitle: 'Advanced Schedule',
            description: 'A schedule created using cron formatting by your system administrator.',
        },
    },

    daysBlock: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        title: 'Select Day(s) to Run',
        error: {
            days: 'You must select at least one day.',
        },
    },

    scheduleAndRun: {
        title: 'Schedule/Run',
    },

    partialSuccess: {
        SubTitle: 'Integration Partially Installed',
        description: 'For successful installation, run process on a schedule or on demand. To run the process now, set a run schedule for each process or select Run Now.',
        EnvfailureMessage: 'Failed to install integration in {{envName}}',
        environmentName: 'Environment -  {{envName}}',
    },

    login: {
        description: 'Select an account',
        selectAccount: 'Select account',
        copyright: 'Copyright © {{year}} Boomi, LP.',
        privacy: 'Boomi and your ',
        privacyLink: ' right to privacy.',
        platformStatus: 'Platform Status',
        id: 'ID: ',
        continue: 'Continue',
        loaderLabel: 'Searching for matching account',
        searchInputLabel: 'Search Accounts',
        searchBtnLabel: 'Search',
    },

    historyDropdown: {
        all: 'All',
        complete: 'Complete',
        error: 'Error',
        status: 'Status',
        executions: 'Executions per page',
    },

    history: {
        refresh: 'Refresh',
        searchPlaceholder: 'Filter list by process name',
    },

    datepick: {
        title: 'Show history since',
        placeholderText: 'mm-dd-yyyy',
    },

    installErrorPage: {
        title: 'Install & Configure Integration',
        failedInstalation: 'Installation Failed.',
        description: 'There was an error when installing the integration. The integration was not installed in any environments. Contact your system administrator for assistance.',
    },

    scheduleErrorPage: {
        title: 'Schedule Process',
        failedSchedule: 'Failed.',
        description: 'There was an error when saving the schedules.',
    },

    processDropdown: {
        title: 'Select a Process',
        connectionPlaceholder: 'Connections In Process',
        propertiesPlaceHolder: 'Select process properties for the process',
        dataMapsPlaceHolder: 'Select data maps for the process',
    },

    editErrorPage: {
        title: 'Edit Integration',
        subtitle: 'Edit failed',
        description: 'There was an error when editing the integration. Contact your system administrator for assistance.',
        buttonLabel: 'Go Back To Integration Edit',
    },

    editUninstallIpackInstance: {
        title: 'Edit Integration',
        subtitle: 'Your integration was successfully uninstalled from ALL environments.',
        description: 'You may reinstall the integration from the Browse page.',
        buttonLabel: 'Done',
    },
    accountDropdown: {
        noOfAccountsDisplayed: 'Showing {{range}} of {{total}} accounts. You can search for additional accounts.',
    },
    footer: {
        copyright: '© Copyright {{year}} Boomi, LP. All rights reserved.',
        platformStatus: 'Platform Status & Announcements',
        policy: 'Policy',
    },
    toggle: {
        off: 'off',
    },
};

export default en;
