import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IStore } from 'interfaces/IStore';

const useTransformPrecision = () => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { name } = useSelector((state: IStore) => state.general.selectedSourceField);
    const {
        register,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm();
    
    const regExForIntegers = /^\d+$/;
    const inputValidationOptions = {
        pattern: {
            value: regExForIntegers,
            message: t('dialogBox.setPrecision.inputDescription')
        },
        required: true,
    };
    
    return { t, dispatch, name, register, errors, setValue, handleSubmit, inputValidationOptions };
}

export default useTransformPrecision;