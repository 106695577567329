import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useUninstallModal from 'hooks/useUninstallModal';
import { IModal } from 'interfaces/IModal';
import { IStore } from '../../interfaces/IStore';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';
import styles from 'components/common/DialogBox/DialogBox.module.scss';

const UninstallModal: FC<IModal> = ({ closeModal, handleIPackUninstall }) => {
    const { t } = useTranslation();
    const { description, uninstall, handleCancel } = useUninstallModal();
    const { isEditUninstallFromLastEnv, isEditUninstallIpackInstance } = useSelector((state: IStore) => state.general);
    const { dialog_body } = styles;
    return (
        <>
            <div className={dialog_body}>{description}</div>
            <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large ">
                <ExButton
                    className="ex-mr-small"
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={() => {
                        handleCancel(closeModal);
                    }}
                    data-testid="uninstallModal-cancel"
                >
                    {t('btn.cancel')}
                </ExButton>

                <ExButton
                    flavor={ButtonFlavor.RISKY}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.LARGE}
                    data-testid="uninstallModal-uninstall"
                    onClick={() => {
                        if (handleIPackUninstall) {
                            closeModal();
                            handleIPackUninstall(isEditUninstallIpackInstance || isEditUninstallFromLastEnv ? '/uninstall' : '/');
                        }
                    }}
                >
                    {uninstall}
                </ExButton>
            </div>
        </>
    );
};

export default UninstallModal;