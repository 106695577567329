import React, { FC } from 'react';

import useMapModal from 'hooks/useMapModal';
import TableWithCheckboxDataMaps from 'components/Form/TableWithCheckbox/TableWithCheckboxDataMaps';
import { IModal } from 'interfaces/IModal';

import styles from 'components/common/DialogBox/DialogBox.module.scss';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';

const MapModal: FC<IModal> = ({ closeModal }) => {
    const { dialogActions, dialogActions_right } = styles;
    const { disableMaps, checkedValues, name, searchQuery, searchResults, handleOnChange, handleApply, t } = useMapModal();

    return (
        <>
            <TableWithCheckboxDataMaps
                extraClass="tableItem_bold"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data={searchResults.filter((el: any) => el.name && el.xpath)}
                checkedValues={checkedValues}
                handleOnChange={handleOnChange}
                title={t('form.selectFieldsToMap', { fieldName: name })}
                emptyErrorLabel="alert.noMapItems"
                searching={searchQuery.length ? true : false}
                headerName="tableWithCheckbox.targetField"
            />

            <div className={dialogActions}>
                <div className={dialogActions_right}>
                    <ExButton slot="footer" flavor={ButtonFlavor.BASE} type={ButtonType.SECONDARY} size={ButtonSize.LARGE} onClick={() => closeModal()} data-testid="mapModal-cancel">
                        {t('btn.cancel')}
                    </ExButton>
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.BASE}
                        type={ButtonType.PRIMARY}
                        size={ButtonSize.LARGE}
                        data-testid="mapModal-apply"
                        disabled={Object.keys(checkedValues).length === 0 && disableMaps}
                        onClick={() => {
                            handleApply();
                            closeModal();
                        }}
                    >
                        {t('btn.apply')}
                    </ExButton>
                </div>
            </div>
        </>
    );
};

export default MapModal;
