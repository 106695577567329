import React, { useEffect } from 'react';

import SwitchRoutes from 'components/common/SwitchRoutes';
import { initPendo } from 'utils/Pendo';
import '@boomi/exosphere/dist/styles.css';
import '@boomi/exosphere/dist/exo-component-theme.css';

import 'assets/scss/general.scss';
import { IStore } from 'interfaces/IStore';
import { useSelector } from 'react-redux';

export function App() {
    const { userId, accountId } = useSelector((state: IStore) => state.login);
    useEffect(() => {
        initPendo(userId, accountId);
    }, [userId, accountId]);

    return <SwitchRoutes />;
}

export default App;
