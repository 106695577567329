import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TableRowDetails from 'components/History/TableRowDetails';
import { IHistoryProcess, IHistoryTableRow } from 'interfaces/IProcessesHistory';
import { PROCESS_STATUS } from 'constants/processesTableCols';
import { truncateString, truncateTooltipText } from 'utils/truncateString';

import style from 'components/common/ProcessesTable/ProcessesTableRow.module.scss';
import { ExIcon, ExTooltip, IconVariant, TooltipAlignment, TooltipPosition } from '@boomi/exosphere';

const HistoryTableRow: FC<IHistoryTableRow> = ({ item }) => {
    const [hasChildren, setHasChildren] = useState<boolean>(false);
    const { t } = useTranslation();
    const formatDate = (historyProcess: IHistoryProcess) => {
        const date = new Date(historyProcess?.timeStamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const formattedDate = `${date.getDate()} ${month} ${date.getFullYear()}`;
        const formattedTime = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', hourCycle: 'h12' }).toUpperCase();
        return `${formattedDate} ${formattedTime}`;
    };

    const { tableRow, tableRow_name, tableRow_status, tableRow_timeStamp, successIcon, failIcon, borderLeft, tableRow_icon, tableRow_iconText, tableRow_name_text } = style;

    const displayStatusWithIcon = () => {
        if (item.status === PROCESS_STATUS['historyDropdown.error']) {
            return (
                <span className={failIcon}>
                    <ExIcon hideBrowserTooltip={true} icon="status-fail" variant={IconVariant.DANGER}></ExIcon> <span className={tableRow_iconText}>{t('processesHistoryTable.error')}</span>
                </span>
            );
        }
        return (
            <span className={successIcon}>
                {' '}
                <ExIcon hideBrowserTooltip={true} icon="status-success" variant={IconVariant.SECONDARY}></ExIcon> <span className={tableRow_iconText}>{t('processesHistoryTable.complete')}</span>
            </span>
        );
    };

    return (
        <div className={hasChildren ? borderLeft : ''}>
            <div className={tableRow} onClick={() => setHasChildren(!hasChildren)}>
                <div className={tableRow_icon}>
                    <ExIcon hideBrowserTooltip={true} icon={hasChildren ? 'direction-arrowhead-up' : 'direction-arrowhead-down'} variant={IconVariant.ICON}></ExIcon>
                </div>
                <div className={tableRow_name}>
                    <ExTooltip position={TooltipPosition.TOP} alignment={TooltipAlignment.START}>
                        <div className="componentExTooltip"> {truncateTooltipText(item?.name)}</div>
                        <div className={tableRow_name_text} slot="anchor">
                            {truncateString(item?.name)}
                        </div>
                    </ExTooltip>
                </div>
                <div className={tableRow_status}>{displayStatusWithIcon()}</div>
                <div className={tableRow_timeStamp}>{formatDate(item)}</div>
            </div>
            {hasChildren && <TableRowDetails processDetails={item.details} errorStatus={item.status !== PROCESS_STATUS['historyDropdown.error']} errorMessage={item.message} />}
        </div>
    );
};

export default HistoryTableRow;
