import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useBasePageData from 'hooks/useBasePageData';
import useConditionalRoutes from 'hooks/useConditionalRoutes';
import { useQueryParam } from 'hooks/useQueryParam';
import { IDataMaps } from 'interfaces/IDataMaps';
import { IStore } from 'interfaces/IStore';
import { getDynamicMappingsQuery } from 'services/getDynamicQuery';
import { setDifferentEnvStep, setSameEnvConfiguration, setSeletedExtensionProcess } from 'store/actions/generalActions';
import useProcessExtensions from './useProcessExtensions';
import { IDataMapsTable } from 'interfaces/IGenericTable';
import useGenericInstall from './useGenericInstall';
import useDataMapsHelper from './useDataMapsProcessDataHandler';
import useDataMapsValidatorAndErrorHandler from './useDataMapsValidatorAndErrorHandler';
import useDataMapsPageDataHandler from './useDataMapsPageDataHandler';
import { ExtensionTypes } from 'constants/extensions';
import useInstallationStep from './useInstallationStep';
import useProcessSelection from './useProcessSelection';

const useDataMaps = () => {
    const { getDataMapsPaths } = useConditionalRoutes();
    const { processDataMapsData } = useDataMapsHelper();
    const { handlePageData } = useDataMapsPageDataHandler();

    const { handleUseDataMapsErrorResponse } = useDataMapsValidatorAndErrorHandler();

    const { isLoading: isDataLoading, modalStatus, selectedEnvironments, selectedIPack, isEditInstall, dataMaps, radioGroup, networkError } = useSelector((state: IStore) => state.general);
    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env);
    const { process, setSelectedProcess, getProcessesForExtension } = useProcessExtensions();
    const type = selectedIPack?.installationType || '';
    const [processDataMaps, setProcessDataMaps] = useState<IDataMapsTable[]>([]);
    const { getCancelBtnLabel, handleUninstall, getExtensionPageTitle } = useGenericInstall();
    const [isLoading, setIsLoading] = useState(false);

    const { id, envIds } = useQueryParam();
    const { pathname, search } = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { query } = getDynamicMappingsQuery(id, envIds?.split(','));
    const { data: pageData } = useBasePageData<IDataMaps>(query);
    const environmentAttachments = pageData?.data.integrationPackEnvironmentAttachments;
    const processes = getProcessesForExtension(environmentAttachments, 'DATA_MAPS');
    const { setDefaultProcess } = useProcessSelection(processes);
    const envs = envIds.split(',');
    const { stepChangeWithOneExtension } = useInstallationStep(step, ExtensionTypes.DATA_MAPS, sameEnvConfiguration);

    const handleClick = () => {
        const nextStep = step + 1;
        const nextPathStep = getDataMapsPaths();

        if (type === 'SINGLE' && !sameEnvConfiguration && step !== (selectedEnvironments?.length as number) - 1) {
            dispatch(setDifferentEnvStep(nextStep));

            return navigate(`/install/configureDifferent/${nextStep}${search}`);
        }

        if (!sameEnvConfiguration && step !== (selectedEnvironments?.length as number) - 1) {
            dispatch(setDifferentEnvStep(nextStep));

            return navigate(`/install/multi/${nextPathStep}${search}`);
        }

        dispatch(setSameEnvConfiguration(true));
        dispatch(setDifferentEnvStep(0));
        navigate(`${pathname}/confirmation${search}`);
    };

    useEffect(() => {
        handlePageData(dataMaps, pageData);
        setIsLoading(false);
    }, [pageData]);

    useEffect(() => {
        dispatch(setSeletedExtensionProcess(''));
    }, []);

    useEffect(() => {
        if (process.length) {
            const dataMapsData = () => processDataMapsData(dataMaps, sameEnvConfiguration, envs, step, process);

            setProcessDataMaps(dataMapsData);
        }
    }, [process, dataMaps, step]);

    useEffect(() => {
        dispatch(setSeletedExtensionProcess(''));
    }, [step]);

    useEffect(() => {
        isDataLoading && setIsLoading(true);
    }, [isDataLoading]);

    useEffect(() => {
        networkError && setIsLoading(false);
    }, [networkError]);

    handleUseDataMapsErrorResponse(isEditInstall, networkError);

    return {
        name: selectedIPack?.name,
        data: processDataMaps || [],
        isLoading,
        modalStatus,
        radioGroup,
        t,
        dispatch,
        handleClick,
        handleUninstall,
        selectedIPack,
        processes,
        process,
        setSelectedProcess,
        isEditInstall,
        getCancelBtnLabel,
        getExtensionPageTitle,
        stepChangeWithOneExtension,
        setDefaultProcess,
    };
};

export default useDataMaps;
