export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_DROPDOWN_OPTION = 'SET_DROPDOWN_OPTION';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_SELECTED_IPACK = 'SET_SELECTED_IPACK';
export const SET_SELECTED_ENVIRONMENTS = 'SET_SELECTED_ENVIRONMENTS';
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
export const SET_SAME_ENV_CONFIGURATION = 'SET_SAME_ENV_CONFIGURATION';
export const SET_DIFFERENT_ENV_STEP = 'SET_DIFFERENT_ENV_STEP';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';
export const SET_SELECTED_SOURCE_FIELD = 'SET_SELECTED_SOURCE_FIELD';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_ACTIVE_TAB_PAGE = 'SET_ACTIVE_TAB_PAGE';
export const SET_FILTERED_TABLE = 'SET_FILTERED_TABLE';
export const SET_DATA_MAPS = 'SET_DATA_MAPS';
export const SET_SELECTED_PROCESS = 'SET_SELECTED_PROCESS';
export const SET_EXTENSIONS_STATUS = 'SET_EXTENSIONS_STATUS';
export const SET_RADIO_GROUP = 'SET_RADIO_GROUP';
export const SET_EXTENSIONS = 'SET_EXTENSIONS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const UPDATE_EXTENSIONS = 'UPDATE_EXTENSIONS';
export const SET_PROCESS_RUN_STATUS = 'SET_PROCESS_RUN_STATUS';
export const SET_PROCESS_SCHEDULES = 'SET_PROCESS_SCHEDULES';
export const SET_IS_RUNS_EVERY_ERROR = 'SET_IS_RUNS_EVERY_ERROR';
export const SET_IS_DAYS_BLOCK_ERROR = 'SET_IS_DAYS_BLOCK_ERROR';
export const SET_INSTALLED_DATA = 'SET_INSTALLED_DATA';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const SET_SPACE_GUID = 'SET_SPACE_GUID';
export const SET_TOKEN_ID = 'SET_TOKEN_ID';
export const SET_TOKEN_SECRET = 'SET_TOKEN_SECRET';
export const SET_JWT = 'SET_JWT';
export const RESET_GENERAL_STATE = 'RESET_GENERAL_STATE';
export const SET_EXTENSION_PROCESSES = 'SET_EXTENSION_PROCESSES';
export const SET_SELECTED_EXTENSION_PROCESS = 'SET_SELECTED_EXTENSION_PROCESS';
export const SET_INSTANCE_PROCESSES = 'SET_INSTANCE_PROCESSES';
export const SET_SELECTED_DATA_MAP = 'SET_SELECTED_DATA_MAP';
export const SET_IS_EDIT_INSTALL = 'SET_IS_EDIT_INSTALL';
export const SET_IS_IPACK_EDIT = 'SET_IS_IPACK_EDIT';
export const SET_IS_EDIT_UNINSTAL_IPACK_INSTANCE = 'SET_IS_EDIT_UNINSTAL_IPACK_INSTANCE';
export const SET_IS_EDIT_UNINSTALL_FROM_LAST_ENV = 'SET_IS_EDIT_UNINSTALL_FROM_LAST_ENV';
export const SET_BROWSE_SWITCH_STATUS = 'SET_BROWSE_SWITCH_STATUS';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
export const SET_ACCOUNT_SEARCH_CRITERIA = 'SET_ACCOUNT_SEARCH_CRITERIA';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_SPACE_ACCOUNT_ID = 'SET_SPACE_ACCOUNT_ID';
export const SET_RUN_NOW_CONFIRMATION_MODAL_STATUS = 'SET_RUN_NOW_CONFIRMATION_MODAL_STATUS';
export const SET_FAILED_MODAL_TYPE = 'SET_FAILED_MODAL_TYPE';
export const SET_AUTHORIZATION_PARAMETER_VALUE = 'SET_AUTHORIZATION_PARAMETER_VALUE';
export const SET_ACCESS_TOKEN_PARAMETER_VALUE = 'SET_ACCESS_TOKEN_PARAMETER_VALUE';
export const SET_TOKEN_GENERATED_STATUS = 'SET_TOKEN_GENERATED_STATUS';
export const SET_OAUTH_TOKEN_DETAILS = 'SET_OAUTH_TOKEN_DETAILS';