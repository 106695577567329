import { ExtensionTypes } from 'constants/extensions';
import { IStore } from 'interfaces/IStore';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSeletedExtensionProcess } from 'store/actions/generalActions';

const useInstallationStep = (step: number, extensionType: ExtensionTypes, sameEnvConfiguration: boolean) => {
    const { isProperties, isDataMaps, isConnection } = useSelector((state: IStore) => state.general.extensionsStatus);
    const dispatch = useDispatch();
    const [stepChangeWithOneExtension, setStepChangeWithOneExtension] = useState<boolean>(false);
    const [installationStep, setInstallationStep] = useState<number>(step);
    const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        if (installationStep != step) {
            if (!sameEnvConfiguration && isOtherExtensionDoesNotExists()) {
                dispatch(setSeletedExtensionProcess(''));
                setStepChangeWithOneExtension(true);
            }
            setInstallationStep(step);
        }
    }, [step]);

    useEffect(() => {
        return () => {
            timer?.current && clearTimeout(Number(timer.current));
        };
    }, []);

    useEffect(() => {
        if (stepChangeWithOneExtension) {
            timer.current = setTimeout(() => setStepChangeWithOneExtension(false), 500);
        }
    }, [stepChangeWithOneExtension]);

    const isOtherExtensionDoesNotExists = () => {
        switch (extensionType) {
            case ExtensionTypes.CONNECTION:
                return !isProperties && !isDataMaps;
            case ExtensionTypes.PROCESS_PROPERTY:
                return !isConnection && !isDataMaps;
            case ExtensionTypes.DATA_MAPS:
                return !isConnection && !isProperties;
            default:
                return false;
        }
    };

    return { stepChangeWithOneExtension };
};

export default useInstallationStep;
