import { ALL_AVAILABLE_IPACKS, ENVIRONMENT_IPACKS } from 'constants/api';
import { ArtifactState } from 'constants/artifact';
import { ICardItem } from 'interfaces/ICardContainer';
import { IInstalledData } from 'interfaces/IInstalled';
import { IStore } from 'interfaces/IStore';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setArtifactState, setInstalledData, setModalStatus, setSearchQuery, setSelectedIPack } from 'store/actions/generalActions';
import { artifactSearchProperties } from 'utils/genericSearch';
import useHandleApiCall from './useHandleApiCall';
import useSearch from './useSearch';
import { getUninstall } from '../services/getDynamicQuery';
import useArtifactData from './useArtifactData';
import useArtifactToggle from './useArtifactToggle';

const useLandingPage = () => {
    const dispatch = useDispatch();
    const {
        artifactState,
        search: { isSearching, searchResults, searchQuery },
    } = useSelector((state: IStore) => state.general);
    const { INSTALLED, NOT_INSTALLED } = ArtifactState;
    const { makeApiCall } = useHandleApiCall();
    const [cardData, setCardData] = useState<ICardItem[]>([]);
    const { clearSearchState } = useSearch(cardData as [], artifactSearchProperties);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const selectedIpack = useSelector((state: IStore) => state.general.selectedIPack);
    const { getAvailableIpacksData, parseInstalledIpackData, getGuid } = useArtifactData();
    const { isToggleSwitchOn } = useArtifactToggle();

    const handleSharedIpacks = async () => {
        setIsLoading(true);
        const installedIPacks: ICardItem[] = await getInstalledIpacks();
        const response = await makeApiCall(ALL_AVAILABLE_IPACKS(getGuid()));
        const allPacks = response?.data?.space?.sharedIpacks || [];
        const availableIPacks = getAvailableIpacksData(installedIPacks, allPacks);
        setCardData(availableIPacks);
        setIsLoading(false);
    };
    const handleInstalledIpacks = async () => {
        setIsLoading(true);
        const installedIPacks: ICardItem[] = await getInstalledIpacks();
        setCardData(installedIPacks);
        setIsLoading(false);
    };

    const getInstalledIpacks = async () => {
        const response = await makeApiCall(ENVIRONMENT_IPACKS(getGuid()));
        dispatch(setInstalledData(response?.data as IInstalledData['data']));
        return parseInstalledIpackData(response);
    };

    const handleSearch = (e: any) => {
        dispatch(setSearchQuery(e.target.value));
    };

    const handleUninstall = async () => {
        setIsLoading(true);
        const { uninstallQuery } = getUninstall(selectedIpack?.integrationId as string);
        await makeApiCall(uninstallQuery);
        await handleInstalledIpacks();
        dispatch(setSelectedIPack(selectedIpack));
        setIsLoading(false);
    };

    const handleToggle = async (e: any) => {
        const switchValue = e.detail ? INSTALLED : NOT_INSTALLED;
        dispatch(setArtifactState(switchValue));
        await handleSwitchData(switchValue);
    };

    const handleSwitchData = async (state: ArtifactState) => {
        if (state === INSTALLED) {
            await handleInstalledIpacks();
        } else {
            await handleSharedIpacks();
        }
    };

    useEffect(() => {
        if (!artifactState) {
            dispatch(setArtifactState(ArtifactState.NOT_INSTALLED));
        }
        handleSwitchData(artifactState);
    }, []);

    useEffect(() => {
        clearSearchState();
        dispatch(setModalStatus(false));
    }, [artifactState]);

    return {
        handleToggle,
        isToggleSwitchOn,
        cardData: isSearching ? searchResults : cardData,
        searchValue: searchQuery,
        handleSearch,
        artifactState,
        isLoading,
        handleUninstall,
    };
};

export default useLandingPage;
