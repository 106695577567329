import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IFormField } from 'interfaces/IForm';
import axios from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import Auth from '../utils/Auth';
import { setModalStatus, setModalType, setAccessTokenFailedModalType, setTokenGeneratedStatus } from '../store/actions/generalActions';
import axiosAbortInstance, { createAbortController, abortController, resetAbortController } from '../utils/axiosAbortInstance';
import createDeferredPromise from 'utils/CreateDeferredPromise';
import { IStore } from '../interfaces/IStore';
import { setOAuthConnectorDetails } from '../services/getDynamicQuery';
import useHandleApiCall from './useHandleApiCall';
import { useQueryParam } from './useQueryParam';
import { extractFormIndex } from 'utils/extractFormIndex';
import useConnectionForm from './useConnectionForm';
import useProcessExtensions from './useProcessExtensions';
import { IEnvironmentElement } from 'interfaces/IEnvironment';
import { removeEmptyKeys } from 'utils/cleanFormData';

const useConfigureConnectionForm = ({ connectionId }: any) => {
    const { t } = useTranslation();
    const [encryption, setEncryption] = useState<string>(t('btn.encryptCS'));
    const dispatch = useDispatch();
    const { spaceAccountId, jwt, spaceGuid } = useSelector((state: IStore) => state.login);

    const { isEdit } = useSelector((state: IStore) => state.general);
    const [generateButton, setGenerateButton] = useState<string>(t('btn.generate'));
    const [clientSecretHelpText, setClientSecretHelpText] = useState<string>(t('form.encryptField'));
    const [inputDisabled, setInputDisabled] = useState<boolean>(false);
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    const [handleEncryptionError, setHandleEncryptionError] = useState(null);
    const { oauthTokenGeneratedStatus, env, extensions, oauthTokenDetails } = useSelector((state: IStore) => state.general || {});
    const selectedEnvironments: IEnvironmentElement[] = useSelector((state: IStore) => state.general?.selectedEnvironments || []);

    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env) || { sameEnvConfiguration: false, step: 0 };
    const { id, envIds } = useQueryParam();
    const envs = envIds.split(',');
    const { getConnectionFormData } = useConnectionForm(sameEnvConfiguration, extensions, envs, step);
    const { process } = useProcessExtensions();

    const { makeApiCall } = useHandleApiCall();

    const envId = selectedEnvironments?.[env.step]?.id ?? 'envId';

    const handleEncryption = async (formData: any, setValue: any, field: IFormField) => {
        const formIndex = extractFormIndex(field.inputName);
        setButtonLoader(true);
        setHandleEncryptionError(null);
        formData[formIndex]['shouldDisableGenerateButton'] = true;
        formData[formIndex]['encryptStatusReset'] = false;
        const now = Auth.getAuthDate();
        const awsAuth = Auth.getAWSToken(now, 'com.boomi.redsky.platform.gwt.common.EncryptPasswordAction');

        const headers = {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        };

        const deferredPromise = createDeferredPromise();

        const payload = {
            password: formData[formIndex]['oauthOptions/OAuth2Config/credentials/@clientSecret'],
            context: 'ATOMSPHERE',
            dateString: now,
            authString: awsAuth,
            accountId: spaceAccountId,
            clientUserAgent: window.navigator.userAgent,
        };
        if (!inputDisabled) {
            try {
                const { data } = await axios.post(`/restrpc/account/${spaceAccountId}/handler/com.boomi.platform.gwt.account.EncryptPasswordHandler`, payload, {
                    headers,
                } as AxiosAuthRefreshRequestConfig);
                setValue(field.inputName.split('.')[1], data.resultString);
                formData[formIndex]['shouldDisableGenerateButton'] = false;
                formData[formIndex]['encryptStatusReset'] = true;
                setInputDisabled(true);
                setEncryption(t('btn.resetCS'));
                setClientSecretHelpText(t('form.encryptInfo'));
            } catch (error: any) {
                if (axios.isAxiosError(error)) {
                    deferredPromise.cancel(error.message);
                    return;
                }
                setHandleEncryptionError(error?.message ? error.message : t('error.encryptionError'));
            } finally {
                setButtonLoader(false);
            }
        } else {
            field?.fieldValue?.[formIndex]?.['oauthOptions/OAuth2Config/credentials/@clientSecret'] && setValue(field.inputName, '');
            setInputDisabled(false);
            setEncryption(t('btn.encryptCS'));
            setButtonLoader(false);
            if (isEdit && oauthTokenGeneratedStatus) {
                setClientSecretHelpText(t('form.encryptRegenerateField'));
                return;
            }
            setClientSecretHelpText(t('form.encryptField'));
        }
    };

    const HideInputValue = (field: IFormField) => {
        return {
            ...field,
            hiddenValue: true,
            disabled: inputDisabled,
        };
    };

    const onGenerateToken = async (dataInput: any, connectionId: string, field: IFormField, processId: string) => {
        // show Starting access token modal
        const formIndex = extractFormIndex(field.inputName);
        const data: any = {
            '0': dataInput[formIndex],
            shouldDisableGenerateButton: dataInput.shouldDisableGenerateButton,
            'oauthOptions/OAuth2Config/credentials/@clientSecret': dataInput['oauthOptions/OAuth2Config/credentials/@clientSecret'],
        };

        removeEmptyKeys(data['0']['oauthOptions/OAuth2Config/authorizationParameters']);
        removeEmptyKeys(data['0']['oauthOptions/OAuth2Config/accessTokenParameters']);

        const keysToRemove = ['shouldDisableGenerateButton', 'oauthOptions/OAuth2Config/credentials/@clientSecret'];

        keysToRemove.forEach((key) => delete dataInput[key]);
        dispatch(setModalStatus(true));
        dispatch(setModalType('openingAccessToken'));

        createAbortController();
        const now = Auth.getAuthDate();
        const awsAuth = Auth.getAWSToken(now, 'com.boomi.redsky.platform.gwt.common.OAuth2GenerateAction');
        const gwtPayload = {
            loginSession: null,
            sessionId: null,
            properties: {
                'oauthOptions/OAuth2Config/credentials/@clientSecret': data['oauthOptions/OAuth2Config/credentials/@clientSecret'],
                accessTokenKey: null,
                connectionId: connectionId,
                'oauthOptions/OAuth2Config/credentials/@accessToken': null,
                grantType: 'code',
                fieldId: 'oauthOptions',
                'oauthOptions/OAuth2Config/credentials/@clientId': data[0]['oauthOptions/OAuth2Config/credentials/@clientId'],
            },
            clientUserAgent: window.navigator.userAgent,
            dateString: now,
            authString: awsAuth,
            context: 'ATOMSPHERE',
            accountId: spaceAccountId,
            authParameters: data[0]['oauthOptions/OAuth2Config/authorizationParameters'], // take the value from form field
            accessTokenParameters: data[0]['oauthOptions/OAuth2Config/accessTokenParameters'],
        };
        const catalogsvcPayload = { ...data, connectionId, processId };

        await generateOAuthToken(gwtPayload, catalogsvcPayload, dataInput);
    };

    const generateOAuthToken = async (gwtPayload: any, catalogsvcPayload: any, dataInput: any) => {
        const headers = {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        };

        try {
            const { data } = await axiosAbortInstance.post(`/restrpc/account/${spaceAccountId}/handler/com.boomi.platform.gwt.browse.OAuth2GenerateHandler`, gwtPayload, {
                headers,
                signal: abortController?.signal,
            } as AxiosAuthRefreshRequestConfig);
            return await getGWTRPCResponse(data, gwtPayload, catalogsvcPayload, dataInput);
        } catch (error: any) {
            if (axios.isCancel(error)) {
                // When we abort an axios call, it basically throws an error and we do not want to show that on screen. hence not using deferredPromise
                console.log(error);
            } else {
                resetAbortController();
                dispatch(
                    setAccessTokenFailedModalType({
                        data: t('error.oauthAccessTokenError'),
                    }),
                );
                dispatch(setModalType('accessTokenFailed'));
            }
        }
    };

    const getGWTRPCResponse = async (result: any, gwtPayload: any, catalogsvcPayload: any, dataInput: any) => {
        let rt;
        if (result.resultType) {
            rt = result.resultType;
        }
        switch (rt) {
            case 'SESSION_ID':
                gwtPayload = {
                    ...gwtPayload,
                    sessionId: result.sessionId,
                };
                await generateOAuthToken(gwtPayload, catalogsvcPayload, dataInput);
                break;
            case 'RESULT':
                await onReceivingResult(result, gwtPayload, catalogsvcPayload, dataInput);
                break;
            case 'MESSAGE':
                await onReceivingMessage(result, gwtPayload, catalogsvcPayload, dataInput);
                break;
            default:
                return result;
        }
    };

    const onReceivingResult = async (result: any, gwtPayload: any, catalogsvcPayload: any, dataInput: any) => {
        if (result.oauthRequestResponse?.requestURL) {
            //open a new tab and navigate user
            window.open(`${result.oauthRequestResponse.requestURL}`, '_blank');
            //3rd RPC calls to get the new session Id
            gwtPayload = {
                ...gwtPayload,
                sessionId: null,
                loginSession: gwtPayload.sessionId,
                properties: {
                    objectRequested: 'getAccessToken',
                },
            };
            await generateOAuthToken(gwtPayload, catalogsvcPayload, dataInput);
        } else if (result.oauthAccessResponse?.accessTokenKey) {
            // Access token generated successfully modal

            if (env.sameEnvConfiguration && selectedEnvironments.length) {
                const queries = selectedEnvironments.map((env: any) => {
                    catalogsvcPayload.isTokenGenerated = true;
                    catalogsvcPayload.oauthConnectorDetails = catalogsvcPayload?.['0'];
                    catalogsvcPayload.catalogGuid = spaceGuid;
                    catalogsvcPayload.envId = `${env.id}`;
                    const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
                    return oAuthConnectorDetailsMutation;
                });
                for (const query of queries) {
                    await makeApiCall(query, true);
                }
            } else {
                catalogsvcPayload.isTokenGenerated = true;
                catalogsvcPayload.oauthConnectorDetails = catalogsvcPayload?.['0'];
                catalogsvcPayload.catalogGuid = spaceGuid;
                catalogsvcPayload.envId = envId;
                const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
                await makeApiCall(oAuthConnectorDetailsMutation, true);
            }
            getConnectionFormData(dataInput, process);
            dispatch(setTokenGeneratedStatus(true));
            dispatch(setModalType('accessTokenSucess'));
            setGenerateButton(t('btn.regenerate'));
            return result;
        }
    };

    const onReceivingMessage = async (result: any, gwtPayload: any, catalogsvcPayload: any, dataInput: any) => {
        if (result.message.type === 'error') {
            resetAbortController();
            dispatch(
                setAccessTokenFailedModalType({
                    data: result.message.Data,
                }),
            );
            dispatch(setModalType('accessTokenFailed'));
        } else {
            // show Initializing OAuth token modal
            dispatch(setModalType('initializingOAuthToken'));
            setTimeout(async () => {
                await generateOAuthToken(gwtPayload, catalogsvcPayload, dataInput);
            }, 5000);
        }
    };

    useEffect(() => {
        oauthTokenDetails.forEach((item: any) => {
            const details = item.IPackInstanceOAuthConnectorDetails;

            if (details && details.envId === selectedEnvironments?.[env.step]?.id && details.processId === process && details.connectionId === connectionId && details.isTokenGenerated) {
                setGenerateButton(t('btn.regenerate'));
            }
        });
        if (isEdit && oauthTokenGeneratedStatus) {
            setClientSecretHelpText(t('form.encryptRegenerateField'));
        }
    }, [isEdit, oauthTokenGeneratedStatus, oauthTokenDetails]);

    useEffect(() => {
        setEncryption(t('btn.encryptCS'));
    }, []);

    useEffect(() => {
        if (encryption === t('btn.resetCS')) {
            setClientSecretHelpText(t('form.encryptInfo'));
            return;
        }
        setClientSecretHelpText(t('form.encryptField'));
    }, [generateButton, encryption]);

    return {
        buttonLoader,
        encryption,
        handleEncryption,
        HideInputValue,
        onGenerateToken,
        generateButton,
        handleEncryptionError,
        clientSecretHelpText,
        inputDisabled,
        generateOAuthToken,
        getGWTRPCResponse,
        onReceivingResult,
        onReceivingMessage,
        setInputDisabled,
        setEncryption,
    };
};

export default useConfigureConnectionForm;
