import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_HOURS, DEFAULT_MINUTES, SCHEDULE_HOURS_OPTIONS, SCHEDULE_INTERVAL_OPTIONS, SCHEDULE_MINUTES_OPTIONS } from 'constants/schedule';
import { IStore } from 'interfaces/IStore';
import { splitStringBy } from 'utils/splitStringBy';
import { IProcessSchedule, ISingularSchedule } from 'interfaces/IProcesses';
import { updateScheduleBlockToSend } from 'utils/updateScheduleBlockToSend';
import { setIsDaysBlockError, setIsRunsEveryError, setProcessSchedules } from 'store/actions/generalActions';

const useScheduleBlock = (
    scheduleBlockObj: { index: number; scheduleBlocks: string[] },
    setScheduleBlocks: React.Dispatch<React.SetStateAction<string[]>>,
    setCount: React.Dispatch<React.SetStateAction<number>>,
    setScheduleBlocksToSend: React.Dispatch<React.SetStateAction<IProcessSchedule['schedule']>>,
    scheduleBlocksToSend: IProcessSchedule['schedule'],
    alreadyScheduledBlocksToSend: IProcessSchedule['schedule'],
    setAlreadyScheduledBlocksToSend: React.Dispatch<React.SetStateAction<IProcessSchedule['schedule']>>,
) => {
    const { index, scheduleBlocks } = scheduleBlockObj;
    const initialState = Array(2)
        .fill(undefined)
        .map((_, i: number) => ({ name: `dropdown${i}`, value: DEFAULT_HOURS[i].value }));
    const initialStateForMinutes = Array(2)
        .fill(undefined)
        .map((_, i: number) => ({ name: `dropdown${i}`, value: DEFAULT_MINUTES[i].value }));
    const [selectedHours, setSelectedHours] = useState<{ name: string; value: string }[]>(initialState);
    const [selectedMinutes, setSelectedMinutes] = useState<{ name: string; value: string }[]>(initialStateForMinutes);
    const [selectedItem, setSelectedItem] = useState<{ value: string; description: string }>(SCHEDULE_INTERVAL_OPTIONS[0]);
    const [inputValue, setInputValue] = useState<number>(1);
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [checkAllDays, setCheckAllDays] = useState<boolean>(false);
    const [toHourOptions, setToHourOptions] = useState<{ name: string; value: string; label: string }[]>([]);
    const [toMinutesOptions, setToMinutesOptions] = useState<{ name: string; value: string; label: string }[]>([]);
    const [hourRangeError, setHourRangeError] = useState<boolean>(false);

    const alreadyScheduledBlocks = useSelector((state: IStore) => state.general.processes.selectedProcess.processSchedules.schedule);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const minutesStr = 'Minutes';
    const hoursStr = 'Hour';
    const daysStr = 'Day';

    const deleteScheduleBlock = () => {
        const filteredScheduleBlocks = scheduleBlocks.filter((el) => parseInt(el) !== index);
        const filteredScheduleBlocksToSend = scheduleBlocksToSend.filter((el) => el.index !== index);
        const filteredAlreadyScheduledBlocks = alreadyScheduledBlocksToSend.filter((el) => el.index !== index);

        setScheduleBlocks(filteredScheduleBlocks);
        setScheduleBlocksToSend(filteredScheduleBlocksToSend);
        setAlreadyScheduledBlocksToSend(filteredAlreadyScheduledBlocks);
        dispatch(setIsDaysBlockError(false));
        if (scheduleBlocks.length === 1) {
            setCount(0);
            dispatch(setProcessSchedules([]));
        }
    };

    const handleIntervalDropdownChange = (e: CustomEvent) => {
        const selectedItemIndex = SCHEDULE_INTERVAL_OPTIONS.map((el) => el.value).indexOf(e.detail.value);
        setSelectedItem(SCHEDULE_INTERVAL_OPTIONS[selectedItemIndex]);
        setInputValue(1);
        setDisplayError(false);
    };

    const isInvalidHourRange = (fromHour: string, toHour: string): boolean => {
        return parseInt(fromHour.replace('scheduleBlock.hour', '')) > parseInt(toHour.replace('scheduleBlock.hour', ''));
    };

    const handleHourFrom = (e: CustomEvent) => {
        const value = e.detail.value;
        const toOptions = generateToHourOptions(value);

        setSelectedHours([
            { name: 'dropdown0', value },
            { name: 'dropdown1', value },
        ]);
        setToHourOptions(toOptions);
    };

    const handleHourTo = (e: CustomEvent) => {
        const value = e.detail.value;
        const selectedHourFrom = selectedHours[0].value;
        const invalidHourRange = isInvalidHourRange(selectedHourFrom, value);
        setSelectedHours([selectedHours[0], { name: 'dropdown1', value: value }]);
        setHourRangeError(invalidHourRange);
    };

    const generateToHourOptions = (fromHour: string) => {
        const options = [];
        const fromHourValue = parseInt(fromHour, 10);

        for (let i = fromHourValue; i <= 23; i++) {
            const hour = i.toString().padStart(2, '0');
            options.push({ name: 'dropdown1', value: hour, label: `${hour}:00` });
        }

        return options;
    };

    const handleMinuteFrom = (e: CustomEvent) => {
        const value = e.detail.value;
        const toMinutesOptions = generateMinuteOptions();

        setSelectedMinutes([
            { name: 'dropdown2', value },
            { name: 'dropdown3', value },
        ]);

        setToMinutesOptions(toMinutesOptions);
    };

    const handleMinuteTo = (e: CustomEvent) => {
        const value = e.detail.value;
        setSelectedMinutes([selectedMinutes[0], { name: 'dropdown3', value: value }]);
    };

    const generateMinuteOptions = () => {
        const options = [];

        for (let i = 0; i <= 59; i++) {
            const minute = i.toString().padStart(2, '0');
            options.push({ name: 'dropdown2', value: minute, label: `${minute}:00` });
        }

        return options;
    };

    const handleOnBlur = (e: Event) => {
        setDisplayError(false);
        const target = e.target as HTMLInputElement;
        if (!target.value) {
            target.value = '1';
        }
    };

    const handleInputChange = (e: Event) => {
        const target = e.target as HTMLInputElement;
        setInputValue(parseInt(target.value));
        setTimeout(() => target.blur(), 500);
    };

    const handleScheduleStateAction = (value: string, label: string, inputVal: string) => {
        setSelectedItem({ value: value, description: `scheduleBlock.description.${label}` });
        setInputValue(parseInt(inputVal));
    };
    const updateHourRangeError = (fromHour: string, toHour: string) => {
        const isInvalid = isInvalidHourRange(fromHour, toHour);
        setHourRangeError(isInvalid);
    };
    const formatHoursAndMinutes = (hours: { value: string }[], minutes: { value: string }[]) => ({
        hour1: hours[0].value.replace('scheduleBlock.hour', ''),
        hour2: hours[1].value.replace('scheduleBlock.hour', ''),
        minute1: minutes[0].value.replace('scheduleBlock.minutes', ''),
        minute2: minutes[1].value.replace('scheduleBlock.minutes', ''),
    });

    const createScheduleData = (
        baseData: ISingularSchedule,
        selectedItem: string,
        inputValue: number,
        { hour1, hour2, minute1, minute2 }: { hour1: string; hour2: string; minute1: string; minute2: string },
    ) => {
        switch (selectedItem) {
            case 'Minutes':
                return { ...baseData, minutes: `0-59/${inputValue}`, hours: `${hour1}-${hour2}`, daysOfMonth: '*' };
            case 'Hour':
                return { ...baseData, hours: `${hour1}-${hour2}/${inputValue}`, minutes: `${minute1}-${minute2}`, daysOfMonth: '*' };
            case 'Day':
                return { ...baseData, daysOfMonth: `1-31/${inputValue}`, hours: hour1, minutes: minute1 };
            default:
                return null;
        }
    };

    const updateBlocksToSend = (data: ISingularSchedule, blocks: IProcessSchedule['schedule'], setBlocks: React.Dispatch<React.SetStateAction<IProcessSchedule['schedule']>>, index: number) => {
        if (data) updateScheduleBlockToSend(data, blocks, setBlocks, alreadyScheduledBlocks, index);
    };

    useEffect(() => {
        if (alreadyScheduledBlocks[index]) {
            const { minutes, hours, daysOfMonth } = alreadyScheduledBlocks[index];

            const splitMinutes = splitStringBy(minutes, '/');
            const splitHoursByInterval = splitStringBy(hours, '/');
            const splitDaysOfMonth = splitStringBy(daysOfMonth, '/');

            let splitHoursByDash: string[] = [];
            splitHoursByDash = splitStringBy(hours, '-');

            hours.substring(0, hours.indexOf('/')) && (splitHoursByDash = hours.substring(0, hours.indexOf('/')).split('-'));

            let splitMinutesByDash: string[] = [];
            splitMinutesByDash = splitStringBy(minutes, '-');

            minutes.substring(0, minutes.indexOf('/')) && (splitMinutesByDash = minutes.substring(0, minutes.indexOf('/')).split('-'));

            const selectedDropdownHours = selectedHours.map((el, i: number) => {
                if (splitHoursByDash[i]) {
                    return { ...el, value: SCHEDULE_HOURS_OPTIONS[parseInt(splitHoursByDash[i])].value };
                }
                return { ...el, value: DEFAULT_HOURS[i].value };
            });

            setSelectedHours(selectedDropdownHours);

            const selectedDropdownMinutes = selectedMinutes.map((el, i: number) => {
                if (splitMinutesByDash[i]) {
                    return { ...el, value: SCHEDULE_MINUTES_OPTIONS[parseInt(splitMinutesByDash[i])].value };
                }
                return { ...el, value: DEFAULT_MINUTES[i].value };
            });

            setSelectedMinutes(selectedDropdownMinutes);

            splitMinutes.length > 1 && handleScheduleStateAction(minutesStr, minutesStr.toLocaleLowerCase(), splitMinutes[1]);

            splitHoursByInterval.length > 1 && handleScheduleStateAction(hoursStr, hoursStr.toLocaleLowerCase(), splitHoursByInterval[1]);

            splitDaysOfMonth.length > 1 && handleScheduleStateAction(daysStr, daysStr.toLocaleLowerCase(), splitDaysOfMonth[1]);

            if (splitDaysOfMonth.length > 1 && splitHoursByInterval.length > 1) {
                handleScheduleStateAction(hoursStr, hoursStr.toLocaleLowerCase(), splitHoursByInterval[1]);
                setCheckAllDays(true);
            }
        }
    }, []);

    useEffect(() => {
        const formattedTime = formatHoursAndMinutes(selectedHours, selectedMinutes);
        updateHourRangeError(formattedTime.hour1, formattedTime.hour2);

        if (index + 1 > alreadyScheduledBlocks.length) {
            const scheduleDataToSend = scheduleBlocksToSend[index - alreadyScheduledBlocks.length] ?? scheduleBlocksToSend.find((item) => item.index === index);
            const newScheduleData = createScheduleData({ ...scheduleDataToSend }, selectedItem.value, inputValue, formattedTime);

            if (newScheduleData) {
                updateBlocksToSend(newScheduleData, scheduleBlocksToSend, setScheduleBlocksToSend, index);
            }
        } else if (alreadyScheduledBlocks[index]) {
            const alreadyScheduledData = createScheduleData({ ...alreadyScheduledBlocksToSend[index] }, selectedItem.value, inputValue, formattedTime);

            if (alreadyScheduledData) {
                updateBlocksToSend(alreadyScheduledData, alreadyScheduledBlocksToSend, setAlreadyScheduledBlocksToSend, index);
            }
        }
    }, [selectedItem.value, index, inputValue, selectedHours, selectedMinutes]);

    useEffect(() => {
        if (displayError || hourRangeError) {
            dispatch(setIsRunsEveryError(true));

            return;
        }

        dispatch(setIsRunsEveryError(false));
    }, [displayError, hourRangeError]);

    const getInputRange = () => {
        switch (selectedItem.value) {
            case minutesStr:
                return { min: 1, max: 59 };
            case hoursStr:
                return { min: 1, max: 23 };
            case daysStr:
                return { min: 1, max: 7 };
            default:
                return { min: 1, max: 59 };
        }
    };

    const { min, max } = getInputRange();

    return {
        t,
        inputValue,
        selectedHours,
        selectedMinutes,
        checkAllDays,
        deleteScheduleBlock,
        handleHourFrom,
        handleHourTo,
        handleMinuteFrom,
        handleMinuteTo,
        handleInputChange,
        handleIntervalDropdownChange,
        handleOnBlur,
        selectedItem,
        displayError,
        SCHEDULE_HOURS_OPTIONS,
        SCHEDULE_INTERVAL_OPTIONS,
        SCHEDULE_MINUTES_OPTIONS,
        toHourOptions,
        toMinutesOptions,
        min,
        max,
        hourRangeError,
    };
};

export default useScheduleBlock;
