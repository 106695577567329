import { IKeyStringValueString } from 'interfaces/IGeneral';

export const SCHEDULE_RUN_COLS = ['confirmationTable.columns.name', 'confirmationTable.columns.status', 'confirmationTable.columns.actions'];

export const PROCESSES_HISTORY_COLS = ['', 'processesHistoryTable.columns.name', 'processesHistoryTable.columns.status', 'processesHistoryTable.columns.timeStamp'];

export const PROCESS_STATUS: IKeyStringValueString = {
    'historyDropdown.complete': 'COMPLETE',
    'historyDropdown.error': 'ERROR',
    'historyDropdown.all': '',
};
