export const SCHEDULE_INTERVAL_OPTIONS = [
    {value: 'Minutes', description: 'scheduleBlock.description.minutes'},
    {value: 'Hour', description: 'scheduleBlock.description.hour'},
    {value: 'Day',  description: 'scheduleBlock.description.day'},
]

export const DEFAULT_HOURS = [
    {value: 'scheduleBlock.hour8'},
    {value: 'scheduleBlock.hour23'},
]

export const DEFAULT_MINUTES = [
    {value: 'scheduleBlock.minutes00'},
    {value: 'scheduleBlock.minutes59'},
]

export const SCHEDULE_HOURS_OPTIONS = [
    {value: 'scheduleBlock.hour0'},
    {value: 'scheduleBlock.hour1'},
    {value: 'scheduleBlock.hour2'},
    {value: 'scheduleBlock.hour3'},
    {value: 'scheduleBlock.hour4'},
    {value: 'scheduleBlock.hour5'},
    {value: 'scheduleBlock.hour6'},
    {value: 'scheduleBlock.hour7'},
    {value: 'scheduleBlock.hour8'},
    {value: 'scheduleBlock.hour9'},
    {value: 'scheduleBlock.hour10'},
    {value: 'scheduleBlock.hour11'},
    {value: 'scheduleBlock.hour12'},
    {value: 'scheduleBlock.hour13'},
    {value: 'scheduleBlock.hour14'},
    {value: 'scheduleBlock.hour15'},
    {value: 'scheduleBlock.hour16'},
    {value: 'scheduleBlock.hour17'},
    {value: 'scheduleBlock.hour18'},
    {value: 'scheduleBlock.hour19'},
    {value: 'scheduleBlock.hour20'},
    {value: 'scheduleBlock.hour21'},
    {value: 'scheduleBlock.hour22'},
    {value: 'scheduleBlock.hour23'},
]

export const SCHEDULE_MINUTES_OPTIONS = [
    { value: 'scheduleBlock.minutes00' },
    { value: 'scheduleBlock.minutes01' },
    { value: 'scheduleBlock.minutes02' },
    { value: 'scheduleBlock.minutes03' },
    { value: 'scheduleBlock.minutes04' },
    { value: 'scheduleBlock.minutes05' },
    { value: 'scheduleBlock.minutes06' },
    { value: 'scheduleBlock.minutes07' },
    { value: 'scheduleBlock.minutes08' },
    { value: 'scheduleBlock.minutes09' },
    { value: 'scheduleBlock.minutes10' },
    { value: 'scheduleBlock.minutes11' },
    { value: 'scheduleBlock.minutes12' },
    { value: 'scheduleBlock.minutes13' },
    { value: 'scheduleBlock.minutes14' },
    { value: 'scheduleBlock.minutes15' },
    { value: 'scheduleBlock.minutes16' },
    { value: 'scheduleBlock.minutes17' },
    { value: 'scheduleBlock.minutes18' },
    { value: 'scheduleBlock.minutes19' },
    { value: 'scheduleBlock.minutes20' },
    { value: 'scheduleBlock.minutes21' },
    { value: 'scheduleBlock.minutes22' },
    { value: 'scheduleBlock.minutes23' },
    { value: 'scheduleBlock.minutes24' },
    { value: 'scheduleBlock.minutes25' },
    { value: 'scheduleBlock.minutes26' },
    { value: 'scheduleBlock.minutes27' },
    { value: 'scheduleBlock.minutes28' },
    { value: 'scheduleBlock.minutes29' },
    { value: 'scheduleBlock.minutes30' },
    { value: 'scheduleBlock.minutes31' },
    { value: 'scheduleBlock.minutes32' },
    { value: 'scheduleBlock.minutes33' },
    { value: 'scheduleBlock.minutes34' },
    { value: 'scheduleBlock.minutes35' },
    { value: 'scheduleBlock.minutes36' },
    { value: 'scheduleBlock.minutes37' },
    { value: 'scheduleBlock.minutes38' },
    { value: 'scheduleBlock.minutes39' },
    { value: 'scheduleBlock.minutes40' },
    { value: 'scheduleBlock.minutes41' },
    { value: 'scheduleBlock.minutes42' },
    { value: 'scheduleBlock.minutes43' },
    { value: 'scheduleBlock.minutes44' },
    { value: 'scheduleBlock.minutes45' },
    { value: 'scheduleBlock.minutes46' },
    { value: 'scheduleBlock.minutes47' },
    { value: 'scheduleBlock.minutes48' },
    { value: 'scheduleBlock.minutes49' },
    { value: 'scheduleBlock.minutes50' },
    { value: 'scheduleBlock.minutes51' },
    { value: 'scheduleBlock.minutes52' },
    { value: 'scheduleBlock.minutes53' },
    { value: 'scheduleBlock.minutes54' },
    { value: 'scheduleBlock.minutes55' },
    { value: 'scheduleBlock.minutes56' },
    { value: 'scheduleBlock.minutes57' },
    { value: 'scheduleBlock.minutes58' },
    { value: 'scheduleBlock.minutes59' },
];


export const DAYS_DATA = [
    {value: 'daysBlock.monday'},
    {value: 'daysBlock.tuesday'},
    {value: 'daysBlock.wednesday'},
    {value: 'daysBlock.thursday'},
    {value: 'daysBlock.friday'},
    {value: 'daysBlock.saturday'},
    {value: 'daysBlock.sunday'},
]