import { IKeyStringValueString } from 'interfaces/IGeneral';
import { useDispatch } from 'react-redux';
import { setSeletedExtensionProcess } from 'store/actions/generalActions';

const useProcessSelection = (processes: IKeyStringValueString[]) => {
    const dispatch = useDispatch();

    const setDefaultProcess = () => {
        processes.length && dispatch(setSeletedExtensionProcess(processes[0]?.value ?? ''));
    };

    return { setDefaultProcess };
};

export default useProcessSelection;
