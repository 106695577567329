import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDropDownLine } from 'react-icons/ri';

import { ICustomDropdown } from 'interfaces/IModal';

import styles from 'components/common/DialogBox/DialogBox.module.scss';
import dropdownStyle from 'components/common/DropdownField/Dropdown.module.scss';

export const CustomDropdown: FC<ICustomDropdown> = ({
    options,
    selectError,
    openDropdown,
    selectedValue,
    optionClickHandler,
    setOpenDropdown,
    required = false,
    component: Component,
    label = 'dropdown.dataTransformationType',
}) => {
    const { t } = useTranslation();
    const { dropdown, dropdownField, dropdownField_icon, errorMessage } = styles;
    const { dropdownWrapperLabel_required, dropdownWrapperLabel } = dropdownStyle;

    return (
        <div className={dropdown}>
            <div className={dropdownWrapperLabel}>
                <label>{t(label)}</label>
                {required && <span className={dropdownWrapperLabel_required}>({t('form.required')})</span>}
            </div>
            <div className={dropdownField} onClick={() => setOpenDropdown(!openDropdown)}>
                <input type="text" value={t(selectedValue)} data-testid="customDropdown-input" readOnly />
                <span className={dropdownField_icon}>
                    <RiArrowDropDownLine size={28} />
                </span>
            </div>
            <div>{openDropdown && <Component {...{ optionClickHandler, options }} />}</div>
            {selectError && <p className={errorMessage}>{t('error.transformationType')}</p>}
        </div>
    );
};

export default CustomDropdown;
