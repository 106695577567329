import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Datepick from 'components/common/Datepick/Datepick';
import SearchBarSecondary from 'components/common/SearchBar/SearchBarSecondary';
import { IHistoryFilters } from 'interfaces/IProcessesHistory';
import { ExIconButton, IconButtonType } from '@boomi/exosphere';
import { HISTORY_DROPDOWN_STATUS } from 'constants/dropdown/dropdownData';
import useHistoryFilters from "../../hooks/useHistoryFilters";

import style from 'components/History/HistoryFilters.module.scss';
import Filter from "../common/Filter/Filter";

const HistoryFilters: FC<IHistoryFilters> = ({ searchQuery, setSearchQuery, setProcessStatus, setSelectedDate, getExecutionHistory, selectedDate }) => {
    const [selectedStatus] = useState('historyDropdown.all');
    const {handleDropdownAction} = useHistoryFilters(setProcessStatus);
    const { t } = useTranslation();
    const { historyFilters, historyFilters_btn } = style;

    const onDateChange = (date: Date) => {
        const reset = new Date(date);
        reset.setHours(0, 0, 0, 0);
        setSelectedDate(reset.getTime());
    };

    const handleRefreshFilters = () => {
        getExecutionHistory();
    };

    return (
        <div className={historyFilters}>
            <Datepick title {...{ onDateChange, selectedDate }} />
            <SearchBarSecondary large handleChange={(e) => setSearchQuery(e)} searchQuery={searchQuery} placeholder={t('history.searchPlaceholder')} />
            <Filter title={'dropdown.selectFilter'} {...{
                options: HISTORY_DROPDOWN_STATUS,
                selectedItem: selectedStatus,
                contained: true,
                handleChange: handleDropdownAction
            }} />
            <div className={historyFilters_btn}>
                <ExIconButton title={t('history.refresh')} icon="circular-double-arrow" type={IconButtonType.SECONDARY} onClick={handleRefreshFilters} data-testid="historyFilters_btn"></ExIconButton>
            </div>
        </div>
    );
};

export default HistoryFilters;
