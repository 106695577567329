import React from 'react';
import GenericContainer from 'containers/GenericContainer';
import { useNavigate } from 'react-router-dom';
import useEditInstall from '../../hooks/useEditInstall';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../interfaces/IStore';
import { useQueryParam } from '../../hooks/useQueryParam';
import AlertBox from 'components/common/AlertBox/AlertBox';
import DialogBox from 'components/common/DialogBox/DialogBox';
import useEditIpack from 'hooks/useEditIpack';
import useAttachedIpack from 'hooks/useAttachedIpack';
import Spinner from 'components/common/Spinner/Spinner';
import { setSelectedEnvironments } from 'store/actions/generalActions';
import useEditIpackTable from 'hooks/useEditIpackTable';
import { useTranslation } from 'react-i18next';
import EndpointError from 'components/common/EndpointError/EndpointError';
import { ButtonFlavor, ButtonType, ExButton, ExIcon, ExTable, ExTBody, ExTd, ExTh, ExTHead, ExTooltip, ExTr, IconVariant, TooltipAlignment, TooltipPosition } from '@boomi/exosphere';
import './EditIpack.scss';

const EditIpack = () => {
    const dispatch = useDispatch();
    const { id: integrationId } = useQueryParam();
    const ipackName = useSelector((state: IStore) => state.general.selectedIPack?.name);
    const { handleEditInstall, handleEditUninstallIpackInstance, handleDone } = useEditInstall();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const columns = [t('editIpack.columns.environmentName'), t('editIpack.columns.installStatus'), t('editIpack.columns.actions')];

    const columnsWithAttachmentStatus = [t('editIpack.columns.environmentName'), t('editIpack.columns.attachmentStatus'), t('editIpack.columns.installStatus'), t('editIpack.columns.actions')];
    const { handleIpackEdit, handleUnattachIPackEnv, getTableColumns } = useEditIpack();
    const { environmentsAll, isIpackAttachedToEnv, handleUninstallIntegration, isLoading: isEnvDataLoading } = useAttachedIpack();
    const { isLoading, rowDetails, isAnyEnvironmentMissingAtom, environments } = useEditIpackTable(environmentsAll);
    const { networkError } = useSelector((state: IStore) => state.general);

    const handleScheduleAndRun = () => {
        const installedEnvs = rowDetails
            .filter((item: any) => item.isInstalled)
            .map((element: any) => ({ environmentId: element.id, id: element.id, name: element.environmentName, classification: element.classification }));
        dispatch(setSelectedEnvironments(installedEnvs));
        navigate(`/edit/multi/scheduleRun?id=${integrationId}`);
    };

    const getEnvironmentsTitle = (envs: any) => <div className="generic-container-subtitle">{t('editIpack.environments', { count: envs.length })}</div>;

    const getHeaderRow = (isEnvMissingAtom: boolean) => {
        return (
            <ExTr className="edit-table-head__row">
                {getTableColumns(isEnvMissingAtom, columnsWithAttachmentStatus, columns).map((el: any) => {
                    return (
                        <ExTh key={el.id} className="edit-table-head__row-data">
                            {el}
                        </ExTh>
                    );
                })}
            </ExTr>
        );
    };
    if (networkError) {
        return (
            <GenericContainer title={t('editIpack.title')} name={ipackName}>
                <div>
                    <EndpointError message="error.500.editIpack" type="danger" />
                    <div className="edit-footer generic-container-footer generic-container-footer__actions">
                        <ExButton className="ex-mr-standard" flavor={ButtonFlavor.BASE} onClick={handleDone} data-testid="editIPackModal-done">
                            {t('btn.done')}
                        </ExButton>
                    </div>
                </div>
            </GenericContainer>
        );
    }

    return (
        <>
            <GenericContainer title={t('editIpack.title')} name={ipackName}>
                {isLoading || isEnvDataLoading ? (
                    <Spinner />
                ) : (
                    <form className="edit">
                        {!isIpackAttachedToEnv && (
                            <div className="ex-mt-medium ex-mb-medium">
                                <AlertBox type="warning" message="alert.noEnvironmentAttachedInEditIpackPage" />
                                <div className="edit-footer ex-mt-3x-large">
                                    <ExButton className="ex-mr-standard" flavor={ButtonFlavor.BASE} onClick={handleUninstallIntegration} data-testid="editIPackModal-done">
                                        {t('btn.done')}
                                    </ExButton>
                                </div>
                            </div>
                        )}
                        {isIpackAttachedToEnv && getEnvironmentsTitle(environments)}
                        {isIpackAttachedToEnv && (
                            <ExTable className="edit-table">
                                <ExTHead className="edit-table-head">{getHeaderRow(isAnyEnvironmentMissingAtom)}</ExTHead>
                                <ExTBody className="edit-table-body">
                                    {rowDetails.map((item: any) => {
                                        const { environmentName, attachmentStatus, isInstalled, installStatus, id, classification } = item;
                                        return (
                                            <ExTr key={item.id} className="edit-table__row">
                                                <ExTd className="edit-table__row-data">
                                                    <ExTooltip position={TooltipPosition.TOP} alignment={TooltipAlignment.START}>
                                                        <div>{environmentName}</div>
                                                        <div slot="anchor" className="edit-table__row-data-name">{environmentName}</div>
                                                    </ExTooltip>
                                                </ExTd>
                                                {isAnyEnvironmentMissingAtom && (
                                                    <ExTd className="edit-table__row-data">
                                                        <span>
                                                            {attachmentStatus === 'yes' ? (
                                                                <>
                                                                    <ExTooltip position={TooltipPosition.TOP} alignment={TooltipAlignment.START}>
                                                                        <div>{t('alert.atomsAttached')}</div>
                                                                        <ExIcon hideBrowserTooltip={true} icon="status-success" className="status-icon" variant={IconVariant.SECONDARY} slot="anchor"></ExIcon>
                                                                    </ExTooltip>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <ExTooltip position={TooltipPosition.TOP} alignment={TooltipAlignment.START}>
                                                                        <div>{t('alert.noEnvironmentAtomInEditIpackPage')}</div>
                                                                        <ExIcon hideBrowserTooltip={true} icon="status-warning" className="status-icon" slot="anchor" variant={IconVariant.DANGER}></ExIcon>
                                                                    </ExTooltip>
                                                                </>
                                                            )}
                                                        </span>
                                                    </ExTd>
                                                )}
                                                <ExTd className="edit-table__row-data">
                                                    <span>{installStatus}</span>
                                                </ExTd>
                                                <ExTd className="edit-table__row-data">
                                                    {isInstalled ? (
                                                        <span>
                                                            <a
                                                                className="link"
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleIpackEdit({ environmentId: id, id: id, name: environmentName, classification });
                                                                }}
                                                            >
                                                                Edit
                                                            </a>{' '}
                                                            <a
                                                                className="link"
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleUnattachIPackEnv({ environmentId: id, id: id, name: environmentName }, rowDetails);
                                                                }}
                                                            >
                                                                Uninstall
                                                            </a>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <a
                                                                className="link"
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleEditInstall({ environmentId: id, id: id, name: environmentName });
                                                                }}
                                                            >
                                                                Install
                                                            </a>
                                                        </span>
                                                    )}
                                                </ExTd>
                                            </ExTr>
                                        );
                                    })}
                                </ExTBody>
                            </ExTable>
                        )}
                        {isIpackAttachedToEnv && (
                            <div className="edit-footer generic-container-footer generic-container-footer__actions">
                                {
                                    <ExButton flavor={ButtonFlavor.BASE} onClick={handleDone} data-testid="editIPackModal-done">
                                        {t('btn.done')}
                                    </ExButton>
                                }
                                <ExButton flavor={ButtonFlavor.BASE} type={ButtonType.SECONDARY} onClick={handleScheduleAndRun} data-testid="editIPackModal-scheduleAndRun">
                                    {t('btn.scheduleAndRun')}
                                </ExButton>
                                <ExButton flavor={ButtonFlavor.RISKY} type={ButtonType.SECONDARY} onClick={handleEditUninstallIpackInstance} data-testid="editIPackModal-uninstallAll">
                                    {t('btn.uninstallAll')}
                                </ExButton>
                            </div>
                        )}
                    </form>
                )}
                <DialogBox />
            </GenericContainer>
        </>
    );
};

export default EditIpack;
