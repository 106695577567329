import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IBanner } from 'interfaces/IBanner';
import styles from './Banner.module.scss';

const Banner: FC<IBanner> = ({ title }) => {
    const { t } = useTranslation();
    const { banner, bannerTitle } = styles;

    return (
        <div className={banner} data-testid={`banner${t(title)}`}>
            <h2 className={bannerTitle}>
                <Trans i18nKey={title} />
            </h2>
        </div>
    );
};

export default Banner;
