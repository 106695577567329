import { useDispatch, useSelector } from 'react-redux';

import { ENVIRONMENT_IPACKS, IPACK_ENV_ATTACHMENTS } from '../constants/api';
import useHandleApiCall from 'hooks/useHandleApiCall';
import { getUnattach } from 'services/getDynamicQuery';
import { setInstalledData, setModalStatus, setModalType } from 'store/actions/generalActions';
import { IStore } from 'interfaces/IStore';
import { IInstalledData } from 'interfaces/IInstalled';

const useUnAttach = () => {
    const dispatch = useDispatch();
    const { makeApiCall } = useHandleApiCall();
    const { selectedEnvironments, selectedIPack } = useSelector((state: IStore) => state.general);
    const spaceGuid = useSelector((state: IStore) => state.login.spaceGuid);

    const handleUnattach = async () => {
        dispatch(setModalStatus(false));
        dispatch(setModalType('loading'));
        dispatch(setModalStatus(true));
        const response = await makeApiCall(IPACK_ENV_ATTACHMENTS);
        const selectedEnvAttachmentId: string =
            response?.data?.environments
                ?.find((item: any) => item.id === selectedEnvironments?.[0]?.environmentId)
                ?.integrationPackEnvironmentAttachment.find(
                    (item: any) => item.environmentId === selectedEnvironments?.[0]?.environmentId && item.integrationPackInstanceId === selectedIPack.integrationId,
                )?.id || '';
        const { unAttachQuery } = getUnattach(selectedEnvAttachmentId);
        await makeApiCall(unAttachQuery);
        const envInstallations = await makeApiCall(ENVIRONMENT_IPACKS(spaceGuid));
        dispatch(setInstalledData(envInstallations?.data as IInstalledData['data']));
        dispatch(setModalStatus(false));
    };

    const handleUnattachForPartialInstall = async (selectedEnvAttachmentId: string) => {
        const {unAttachQuery} = getUnattach(selectedEnvAttachmentId);
        await makeApiCall(unAttachQuery);
    };

    return {handleUnattach, handleUnattachForPartialInstall};
};

export default useUnAttach;
