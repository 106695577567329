import { IFormField } from 'interfaces/IForm';

export const INSTALLED_DATA = [
    {
        fieldType: 'Field',
        inputName: 'integrationName',
        label: '',
        required: true,
    },
];

export const SET_PRECISION_INPUT: IFormField = {
    fieldType: 'Field',
    inputName: 'decimals',
    type: 'number',
    label: 'dialogBox.setPrecision.inputLabel',
    required: true,
    description: 'dialogBox.setPrecision.inputDescription',
};

export const DATE_FORMAT_INPUT = [
    {
        inputName: 'incomingDate',
        fieldType: 'text',
        label: 'dialogBox.dateFormat.inputLabel.incomingDate',
        required: false,
        description: 'dialogBox.dateFormat.inputDescription',
    },
    {
        inputName: 'outputDate',
        fieldType: 'text',
        label: 'dialogBox.dateFormat.inputLabel.outputDate',
        required: false,
        description: 'dialogBox.dateFormat.inputDescription',
    },
];

export const NUMBER_FORMAT_INPUT = [
    {
        inputName: 'incomingNumber',
        fieldType: 'text',
        label: 'dialogBox.numberFormat.inputLabel.incomingNumber',
        required: false,
        description: 'dialogBox.numberFormat.inputDescription',
    },
    {
        inputName: 'outputNumber',
        fieldType: 'text',
        label: 'dialogBox.numberFormat.inputLabel.outputNumber',
        required: false,
        description: 'dialogBox.numberFormat.inputDescription',
    },
];

export const APPEND_INPUT = [
    {
        inputName: 'appendCharacters',
        fieldType: 'text',
        label: 'dialogBox.append.inputLabel.characters',
        required: false,
        description: '',
    },
    {
        inputName: 'appendLength',
        fieldType: 'text',
        label: 'dialogBox.append.inputLabel.length',
        required: false,
        description: 'dialogBox.append.inputDescription',
    },
];
