import { TOOLTIP_LENGTH_ALLOWED, TOOLTIP_MAX_LENGTH_ALLOWED } from 'constants/general';

export const truncateString = (inputString: string): string => {
    const maxLength = TOOLTIP_MAX_LENGTH_ALLOWED;
    if (inputString.length > maxLength) {
        return inputString.substring(0, maxLength) + '...';
    }
    return inputString;
};

export const truncateTooltipText = (inputString: string): string => {
    const maxLength = TOOLTIP_LENGTH_ALLOWED;
    if (inputString.length > maxLength) {
        return inputString.substring(0, maxLength);
    }
    return inputString;
};
