import { ISingularSchedule } from "interfaces/IProcesses";
import {PATH_STEPS} from "../constants/routes/pathSteps";

export const getSlicedPathname = (pathname: string) => {
    return pathname.includes('confirmation')
        ? pathname.slice(0, pathname.indexOf('/schedule'))
        : pathname.slice(0, pathname.indexOf('/schedule', pathname.indexOf('/schedule') + PATH_STEPS.scheduleRun.length));
};

export const getCleanedAlreadyScheduledBlocks = (alreadyScheduledBlocksToSend: any[]) => {
    return alreadyScheduledBlocksToSend.map((el) => {
        delete el.index;

        if (el.daysOfWeek === '0') {
            el.daysOfWeek = el.daysOfWeek.replace('0', '7');
        }

        if (el.daysOfWeek.includes('0,')) {
            el.daysOfWeek = el.daysOfWeek.replace('0,', '') + ',7';
        }

        return el;
    });
};

export const isAdvancedSchedule = (scheduleToCheck: ISingularSchedule) => {
    const { years, months, daysOfMonth, hours, minutes } = scheduleToCheck;
    const daysOfMonthSplit = daysOfMonth.split('/');
    const hoursSplit = hours.split('/');
    const minutesSplit = minutes.split('/');
    let returnValue = false;

    if (
        years !== '*' ||
        months !== '*' ||
        (daysOfMonthSplit[0] !== '*' && daysOfMonthSplit[0] !== '1-31') ||
        (daysOfMonthSplit[1] !== undefined && parseInt(daysOfMonthSplit[1]) > 14) ||
        (hoursSplit[1] !== undefined && parseInt(hoursSplit[1]) > 24) ||
        (minutesSplit[1] !== undefined && parseInt(minutesSplit[1]) > 60)
    ) {
        returnValue = true;
    }
    return returnValue;
};