import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParam } from './useQueryParam';
import useBasePageData from './useBasePageData';
import { IEnvironmentData, IEnvironmentElement, IEnvironments } from '../interfaces/IEnvironment';
import { ENVIRONMENTS } from '../constants/api';
import { IStore } from '../interfaces/IStore';
import useUninstall from './useUninstall';

const useAttachedIpack = () => {
    const { id } = useQueryParam();
    const [isLoading, setIsLoading] = useState(true);

    const environments = useSelector((state: IStore) => state.general.installedData);
    const { networkError } = useSelector((state: IStore) => state.general);
    const { data: environmentsAll } = useBasePageData<IEnvironmentData>(ENVIRONMENTS);
    const [isIpackAttachedToEnv, setIpackAttachedToEnv] = useState(false);
    const { handleUninstall } = useUninstall();
    const handleUninstallIntegration = () => {
        handleUninstall('/');
    };

    useEffect(() => {
        return () => {
            setIsLoading(false);
        };
    }, []);

    useEffect(() => {
        networkError && setIsLoading(false);
    }, [networkError]);

    useEffect(() => {
        if (environmentsAll?.data?.environments && environments?.environments) {
            environmentsAll.data.environments.forEach((item: IEnvironmentElement) => {
                if (
                    environments.environments.find((env: IEnvironments) => env.id === item.id) &&
                    environments.environments
                        .find((env: IEnvironments) => env.id === item.id)
                        ?.integrationPackEnvironmentAttachment.findIndex((instance) => instance.integrationPackInstanceId === id) != -1
                ) {
                    setIpackAttachedToEnv(true);
                }
            });
            setIsLoading(false);
        }
    }, [environments, environmentsAll]);

    return { environmentsAll, isIpackAttachedToEnv, handleUninstallIntegration, isLoading };
};

export default useAttachedIpack;
