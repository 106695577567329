import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import TransformDropdown from 'components/Modal/CustomDropdown';
import TransformModalChild from 'components/Modal/TransformModalChild';
import useDropdown from 'hooks/useDropdown';
import { IModal } from 'interfaces/IModal';
import { IStore } from 'interfaces/IStore';
import { TRANSFORM_MODAL_DATA } from 'constants/dropdown/dropdownData';

import styles from 'components/common/DialogBox/DialogBox.module.scss';

const TransformModal: FC<IModal> = ({ closeModal, handleModalChange }) => {
    const { t } = useTranslation();
    const { name } = useSelector((state: IStore) => state.general.selectedSourceField);
    const { selectedValue, selectError, openDropdown, setOpenDropdown, optionClickHandler, nextClickHandler } = useDropdown(handleModalChange);
    const { dialogActions, dialogActions_right, dialogTitle } = styles;

    return (
        <>
            <div className={dialogTitle}>
                <h2>{t('dialogBox.transform.title', { item: name })}</h2>
            </div>
            <TransformDropdown options={TRANSFORM_MODAL_DATA} component={TransformModalChild} {...{ selectError, selectedValue, openDropdown, setOpenDropdown, optionClickHandler }} />
            <div className={dialogActions}>
                <div className={dialogActions_right}>
                    <Button label={t('btn.cancel')} card light handleClick={() => closeModal()} data-testid="transformModal-cancel" />
                    <Button label={t('btn.next')} card handleClick={nextClickHandler} data-testid="transformModal-apply" />
                </div>
            </div>
        </>
    );
};

export default TransformModal;
