export const createDeferredPromise = () => {
    const noop: (value: (PromiseLike<string> | string)) => void = () => undefined;
  
    let complete = noop;
    let cancel = noop;
    const promise = new Promise<string>((resolve, reject) => {
      complete = resolve;
      cancel = reject;
    });
  
    return { complete, cancel, promise };
  };
  
  export default createDeferredPromise;