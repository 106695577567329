import React, { FC } from 'react';
import { ExInput } from '@boomi/exosphere';
import { useTranslation } from 'react-i18next';

import { IDatePick } from 'interfaces/IDatePick';

import 'react-datepicker/dist/react-datepicker.css';
import style from 'components/common/Datepick/Datepick.module.scss';

const Datepick: FC<IDatePick> = ({ onDateChange, selectedDate }) => {
    const { t } = useTranslation();
    const { wrapper } = style;

    const stringifySelectedDate = (date: Date) => {
        const currentDate = new Date(date).getDate();
        const month = new Date(date).getMonth() + 1;
        const monthString = month <= 9 ? `0${month}` : month;
        const dateString = currentDate <= 9 ? `0${currentDate}` : currentDate;
        return `${new Date().getFullYear()}-${monthString}-${dateString}`;
    };

    return (
        <div className={wrapper}>
            <ExInput
                type="date"
                data-testid="DatePicker"
                label={t('datepick.title')}
                max={stringifySelectedDate(new Date())}
                pattern={t('datepick.placeholderText')}
                onChange={(e: any) => onDateChange(new Date(e.target.value))}
                value={stringifySelectedDate(selectedDate)}
            ></ExInput>
        </div>
    );
};

export default Datepick;
