import { ExLoader, LoaderSize } from '@boomi/exosphere';
import React from 'react';

const Spinner = () => {
    return (
        <div className="loader-wrapper">
            <ExLoader label="" size={LoaderSize.MEDIUM} backdrop={false} className="loader" />
        </div>
    );
};

export default Spinner;
