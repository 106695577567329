import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useHandleApiCall from 'hooks/useHandleApiCall';
import { IStore } from 'interfaces/IStore';
import { genericSearch } from 'utils/genericSearch';
import { IHistoryProcess } from 'interfaces/IProcessesHistory';
import { HISTORY_DROPDOWN_EXECUTIONS } from 'constants/dropdown/dropdownData';
import { setCurrentPage, setIsSearching } from 'store/actions/generalActions';
import { getExecutionHistoryQuery } from 'services/getDynamicQuery';
import { getDisplayExecutionsHistory } from 'services/getDisplayExecutionsHistory';

const useActivityPage = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const { isSearching } = useSelector((state: IStore) => state.general.search);
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [processesHistory, setProcessesHistory] = useState<IHistoryProcess[]>([]);
    const [filteredProcessesHistory, setFilteredProcessesHistory] = useState<IHistoryProcess[]>([]);
    const [selectedExecutionsLimit, setSelectedExecutionsLimit] = useState<string>(HISTORY_DROPDOWN_EXECUTIONS[0].value);
    const [selectedDate, setSelectedDate] = useState<number>(today.getTime());
    const [processStatus, setProcessStatus] = useState<string>('');
    const { makeApiCall } = useHandleApiCall();

    const getExecutionHistory = async () => {
        const startDate = new Date(selectedDate);
        if (!isNaN(startDate.getTime())) {
            const { query } = getExecutionHistoryQuery(startDate.toISOString(), processStatus);
            const executionHistoryFromPlatform = await makeApiCall(query);
            const displayList = getDisplayExecutionsHistory(
                executionHistoryFromPlatform?.data?.executionHistory?.sort((a: any, b: any) => new Date(b.executionTime).getTime() - new Date(a.executionTime).getTime()),
            );

            setSearchQuery('');
            setProcessesHistory(displayList);
            setFilteredProcessesHistory(displayList);
            dispatch(setCurrentPage(0));
        }
    };

    const multiFilter = (arrayToFilter: IHistoryProcess[], searchString: string) => {
        const filteredResults = arrayToFilter.filter((process) => genericSearch(process, ['name'], searchString));

        setFilteredProcessesHistory(filteredResults);
    };

    useEffect(() => {
        multiFilter(processesHistory, searchQuery);
        dispatch(setCurrentPage(0));
        if (searchQuery?.length >= 1) {
            dispatch(setIsSearching(true));
            return;
        }
        dispatch(setIsSearching(false));
    }, [searchQuery]);

    useEffect(() => {
        getExecutionHistory();
    }, [selectedDate, processStatus]);

    return {
        filteredProcessesHistory,
        setSearchQuery,
        searchQuery,
        setProcessesHistory,
        selectedExecutionsLimit,
        setSelectedExecutionsLimit,
        isSearching,
        selectedDate,
        setSelectedDate,
        processStatus,
        setProcessStatus,
        getExecutionHistory,
    };
};

export default useActivityPage;
