import Activity from 'pages/Activity';
import Installed from 'pages/Installed';
import Error from 'pages/Error';
import ConfigureConnection from 'pages/Install/ConfigureConnection';
import ConfirmationPage from 'pages/Install/ConfirmationPage/ConfirmationPage';
import DataMapsInstall from 'pages/Install/DataMapsInstall';
import MultiPackInstall from 'pages/Install/MultiPackInstall';
import Properties from 'pages/Install/Properties';
import SinglePackInstall from 'pages/Install/SinglePackInstall';
import SetSchedule from 'pages/Schedule/SetSchedule';
import EndpointErrorPage from 'pages/EndpointErrorPage';
import { IRoute } from 'interfaces/IGeneral';
import { PATH_STEPS } from 'constants/routes/pathSteps';
import EditIpack from '../../pages/EditIpack/EditIpack';

const { scheduleRun, scheduleError, editError, uninstall } = PATH_STEPS;

export const ROUTES: IRoute[] = [
    {
        path: 'installed',
        element: Installed,
    },
    {
        path: 'activity',
        element: Activity,
    },
    {
        path: 'install',
        element: SinglePackInstall,
    },
    {
        path: 'install/multi',
        element: MultiPackInstall,
    },
    {
        path: 'install/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure',
        element: ConfigureConnection,
    },
    {
        path: 'install/multi/configure/properties',
        element: Properties,
    },
    {
        path: 'install/multi/configure/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/configure/properties/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/properties/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/properties/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/properties/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/configure/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configure/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configure/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/properties',
        element: Properties,
    },
    {
        path: 'install/multi/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/properties/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/properties/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/properties/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/properties/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step',
        element: ConfigureConnection,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties',
        element: Properties,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/configureDifferent/:step/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/configureDifferent/:step/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/configureDifferent/:step/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/:step/properties',
        element: Properties,
    },
    {
        path: 'install/multi/:step/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/:step/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/:step/properties/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/:step/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/:step/properties/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/:step/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/:step/properties/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/:step/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/:step/properties/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/:step/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'install/multi/:step/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/:step/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'install/multi/:step/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'install/multi/:step/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: scheduleRun,
        element: ConfirmationPage,
    },
    {
        path: 'scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'installError',
        element: EndpointErrorPage,
    },
    {
        path: scheduleError,
        element: EndpointErrorPage,
    },
    {
        path: '*',
        element: Error,
    },
    {
        path: 'edit/multi',
        element: EditIpack,
    },

    {
        path: 'edit/multi/configure',
        element: ConfigureConnection,
    },
    {
        path: 'edit/multi/configure/properties',
        element: Properties,
    },
    {
        path: 'edit/multi/configure/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'edit/multi/configure/properties/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/properties/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/properties/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/properties/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'edit/multi/configure/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/configure/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/configure/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/properties',
        element: Properties,
    },
    {
        path: 'edit/multi/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'edit/multi/properties/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/properties/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/properties/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/properties/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'edit/multi/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/:step/properties',
        element: Properties,
    },
    {
        path: 'edit/multi/:step/properties/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'edit/multi/:step/properties/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/:step/properties/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/:step/properties/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/:step/properties/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/:step/data-maps',
        element: DataMapsInstall,
    },
    {
        path: 'edit/multi/:step/data-maps/confirmation',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/:step/data-maps/scheduleRun',
        element: ConfirmationPage,
    },
    {
        path: 'edit/multi/:step/data-maps/scheduleRun/schedule',
        element: SetSchedule,
    },
    {
        path: 'edit/multi/:step/data-maps/confirmation/schedule',
        element: SetSchedule,
    },
    {
        path: editError,
        element: EndpointErrorPage,
    },
    {
        path: uninstall,
        element: EndpointErrorPage,
    },
];
