import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModalType } from 'hooks/useModalType';
import { IStore } from 'interfaces/IStore';
import { IDialogBox } from 'interfaces/IDialogBox';
import { ExDialog, ExLoader, LoaderSize,  } from '@boomi/exosphere';
import { setModalStatus, setModalType } from '../../../store/actions/generalActions';
import useDialogTitle from '../../../hooks/useDialogTitle';
import './DialogBox.scss';
export const DialogBox: FC<IDialogBox> = ({ uninstall, callback }) => {
    const { modalType, modalStatus, selectedIPack } = useSelector((state: IStore) => state.general);
    const { modal } = useModalType(modalType, selectedIPack?.name || '', uninstall, callback);
    const dispatch = useDispatch();
    const { title, headerContent , variant } = useDialogTitle();
    if (modalStatus && modalType === 'loading') {
        return (
            <div className="page-loader">
                <ExLoader label="" size={LoaderSize.LARGE} backdrop={false} inline={false}></ExLoader>
            </div>
        );
    }
    return (
        <>
            <div className="dialog__title">
                <ExDialog
                    open={modalStatus}
                    dialogTitle={title}
                    hideClose={true}
                    headerContent={headerContent}
                    variant={variant}
                    staticBackdrop={true}
                    onCancel={() => {
                        dispatch(setModalStatus(false));
                        dispatch(setModalType(''));
                    }}
                >
                    {modalStatus ? modal : ''}
                </ExDialog>
            </div>
        </>
    );
};
export default DialogBox;
