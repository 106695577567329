import React from 'react';
import { useSelector } from 'react-redux';

import DialogBox from 'components/common/DialogBox/DialogBox';
import AlertBox from 'components/common/AlertBox/AlertBox';
import EndpointError from 'components/common/EndpointError/EndpointError';
import GenericContainer from 'containers/GenericContainer';
import useConfirmationPage from 'hooks/useConfirmationPage';
import { IStore } from 'interfaces/IStore';
import styles from 'pages/Install/ConfirmationPage/ConfirmationPage.module.scss';
import useAttachedIpack from 'hooks/useAttachedIpack';
import Spinner from 'components/common/Spinner/Spinner';
import { IEnvironmentElement } from 'interfaces/IEnvironment';
import { IProcessesData } from 'interfaces/IProcesses';
import './Confirmation.scss';
import { ButtonFlavor, AlertBannerType, ButtonSize, ButtonType, ExAlertBanner, ExButton, ExIcon, ExTooltip, IconVariant, TooltipAlignment, TooltipPosition } from '@boomi/exosphere';
import EnvProcesses from './EnvProcesses';
import useConfirmationPageRenderer from 'hooks/useConfirmationPageRenderer';
export const ConfirmationPage = () => {
    const {
        t,
        ipackName,
        navigate,
        getEnvProcesses,
        pageData,
        isScheduleAndRun,
        selectedEnvironments,
        modalStatus,
        modalType,
        handleDone,
        isPageDataLoading,
        isPartialSuccess,
        displayExecutionHistory,
    } = useConfirmationPage();
    const { networkError, isEdit } = useSelector((state: IStore) => state.general);

    const { isIpackAttachedToEnv, handleUninstallIntegration, isLoading: isEnvDataLoading } = useAttachedIpack();
    const { showScheduleRunAlert, showProcessTable, getPage, isHiddenContent } = useConfirmationPageRenderer();
    const hiddenContent = isHiddenContent(modalStatus, modalType);

    const { alertContainer } = styles;
    let pagetitle = '';
    let sub_title = '';

    const page = getPage(isScheduleAndRun, isEdit, isPartialSuccess);

    switch (page) {
        case 'edit':
            pagetitle = 'editIpack.title';
            sub_title = 'editIpack.subTitle';

            break;

        case 'scheduleRun':
            pagetitle = 'scheduleAndRun.title';
            sub_title = 'installPage.successfulInstalation';

            break;

        case 'partialInstall':
            pagetitle = 'form.installAndConfigure';
            sub_title = 'partialSuccess.SubTitle';

            break;

        default:
            pagetitle = 'form.installAndConfigure';
            sub_title = 'installPage.successfulInstalation';

            break;
    }

    const renderScheduleRunAlert = (pageValue: string, isIntegrationPackAttachedToEnv: boolean) => {
        return showScheduleRunAlert(pageValue, modalStatus, isIntegrationPackAttachedToEnv) ? (
            <div className={`${alertContainer}`}>
                <AlertBox type="warning" message="alert.noEnvironmentAttachedInEditIpackPage" />
                <div className="generic-container-footer">
                    <div className="generic-container-footer__actions">
                        <ExButton type={ButtonType.PRIMARY} size={ButtonSize.DEFAULT} flavor={ButtonFlavor.BASE} onClick={handleUninstallIntegration}>
                            {t('btn.done')}
                        </ExButton>
                    </div>
                </div>
            </div>
        ) : (
            ''
        );
    };

    const renderProcessTableData = (isError: boolean | undefined, selectedEnvs: IEnvironmentElement[] | undefined, data: IProcessesData | null) => {
        if (!pageData) return;
        const processData = pageData ? pageData?.data?.integrationPackInstanceProcesses : [];
        return showProcessTable(isError, processData) ? (
            <>
                <EnvProcesses selectedEnvs={selectedEnvs} data={data} getEnvProcesses={getEnvProcesses} />
            </>
        ) : (
            <EndpointError message="error.500.confirmation" type="danger" />
        );
    };

    return (
        <>
            <GenericContainer title={pagetitle} name={ipackName}>
                {page === 'scheduleRun' && !modalStatus && (isEnvDataLoading || isPageDataLoading) ? (
                    <Spinner />
                ) : (
                    <>
                        {renderScheduleRunAlert(page, isIpackAttachedToEnv)}
                        {!hiddenContent && isIpackAttachedToEnv && (
                            <>
                                {!isScheduleAndRun && (
                                    <div className="confirmation confirmation-success">
                                        <ExAlertBanner type={AlertBannerType.SUCCESS} open={true}>
                                            {t(sub_title)}
                                        </ExAlertBanner>
                                    </div>
                                )}
                                <div className="ex-container">
                                    <div className="ex-row">
                                        <div className="ex-col-xs-7">
                                            <div>
                                                <div className="confirmation generic-container-subtitle">
                                                    {t('editIpack.success')}
                                                    <ExTooltip position={TooltipPosition.TOP} alignment={TooltipAlignment.START}>
                                                        <div> {t('editIpack.information')}</div>
                                                        <ExIcon hideBrowserTooltip={true} icon="information" label="" className="confirmation-icon" slot="anchor" variant={IconVariant.ICON}></ExIcon>
                                                    </ExTooltip>
                                                </div>
                                            </div>
                                            {renderProcessTableData(networkError, selectedEnvironments, pageData)}
                                            <div className="edit-footer generic-container-footer  generic-container-footer__actions">
                                                <ExButton flavor={ButtonFlavor.BASE} type={ButtonType.PRIMARY} size={ButtonSize.DEFAULT} onClick={() => handleDone()}>
                                                    {t('btn.done')}
                                                </ExButton>
                                                {displayExecutionHistory() && (
                                                    <ExButton
                                                        flavor={ButtonFlavor.BASE}
                                                        type={ButtonType.SECONDARY}
                                                        size={ButtonSize.DEFAULT}
                                                        data-testid="runNowModal-viewExecutionHistory"
                                                        onClick={() => {
                                                            navigate('/activity');
                                                        }}
                                                    >
                                                        {t('btn.viewExecutionHistory')}
                                                    </ExButton>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </GenericContainer>
            <DialogBox />
        </>
    );
};

export default ConfirmationPage;
