import { ArtifactState } from 'constants/artifact';
import { IStore } from 'interfaces/IStore';
import { useSelector } from 'react-redux';

const useArtifactToggle = () => {
    const { INSTALLED } = ArtifactState;
    const { artifactState } = useSelector((state: IStore) => state.general);

    const isToggleSwitchOn = () => (artifactState === INSTALLED ? true : false);

    return { isToggleSwitchOn };
};

export default useArtifactToggle;
