import { useDispatch, useSelector } from 'react-redux';

import useUninstall from 'hooks/useUninstall';
import { setNetworkError, setModalStatus, updateExtensions, setDataMaps } from 'store/actions/generalActions';
import { getUninstall, getUnattach } from 'services/getDynamicQuery';
import useHandleApiCall from './useHandleApiCall';
import { useNavigate } from 'react-router-dom';
import { IStore } from '../interfaces/IStore';
import { PATH_STEPS } from 'constants/routes/pathSteps';
import { IConnectionsArr } from 'interfaces/IConfigureConnection';
import useExtensionsSupportingData from './useExtensionsSupportingData';
import { ENVIRONMENT_IPACKS } from 'constants/api';

const useHandleResponseErrors = () => {
    const dispatch = useDispatch();
    const { handleUninstall: errorUnInstall } = useUninstall();
    const { makeApiCall } = useHandleApiCall();
    const { selectedEnvironments, isEditInstall } = useSelector((state: IStore) => state.general);
    const navigate = useNavigate();
    const { isEdit } = useSelector((state: IStore) => state.general);
    const spaceGuid = useSelector((state: IStore) => state.login.spaceGuid);
    const { updateInstalledData } = useExtensionsSupportingData();

    const handleResponseErrors = async (id: string | undefined = undefined) => {
        dispatch(setNetworkError(false));
        if (isEdit) {
            const extensionsStateArr: IConnectionsArr['result'] = [];
            dispatch(updateExtensions(extensionsStateArr));
            dispatch(setDataMaps([]));
            navigate(`/${PATH_STEPS.editError}`);
        } else {
            if (id) {
                const { uninstallQuery } = getUninstall(id);
                await makeApiCall(uninstallQuery);
                navigate('/installError');
                return;
            }
            errorUnInstall('/installError');
        }
    };

    const handleResponseErrorsForEditInstall = async () => {
        dispatch(setNetworkError(false));
        const id = selectedEnvironments?.map((element) => element.environmentId)?.toString();
        if (isEditInstall && id) {
            // UnAttach the selected env from ipack
            const { unAttachQuery } = getUnattach(id);
            await makeApiCall(unAttachQuery);
            const data = await makeApiCall(ENVIRONMENT_IPACKS(spaceGuid));
            updateInstalledData(data);
            navigate('/installError');
        }
        dispatch(setModalStatus(false));
    };

    return { handleResponseErrors, handleResponseErrorsForEditInstall };
};

export default useHandleResponseErrors;
