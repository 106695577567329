import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IModal } from 'interfaces/IModal';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';
import { resetAbortController } from '../../utils/axiosAbortInstance';

const OpeningAccessTokenModal: FC<IModal> = ({ closeModal }) => {
    const { t } = useTranslation();
    const handleOpeningCloseModal = () => {
        resetAbortController();
        closeModal();
    };

    return (
        <>
            <div>{t('dialogBox.openingAccessToken.message')}</div>

            <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large">
                <ExButton
                    className="ex-mr-small"
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={handleOpeningCloseModal}
                    data-testid="openingAccessTokenModal-close"
                >
                    {t('btn.close')}
                </ExButton>
            </div>
        </>
    );
};

export default OpeningAccessTokenModal;
