import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useHandleApiCall from 'hooks/useHandleApiCall';
import useHandleResponseErrors from 'hooks/useHandleResponseErrors';
import { IInstanceProcess } from 'interfaces/IProcesses';
import { IKeyStringValueString } from 'interfaces/IGeneral';
import { IInstalledEnv } from 'interfaces/IInstalled';
import { IStore } from 'interfaces/IStore';
import useExtensionsData from './useExtensionsData';
import { getDynamicProcessesListQuery, getInstallEnv } from 'services/getDynamicQuery';
import { useEffect } from 'react';
import { useQueryParam } from './useQueryParam';
import { setIsEditInstall, setInstanceProcesses, setModalStatus, setSelectedEnvironments, setModalType, setIsEditUninstallIpackInstance } from '../store/actions/generalActions';

const useEditInstall = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getExtensionsData } = useExtensionsData();
    const selectedIPack = useSelector((state: IStore) => state.general.selectedIPack);
    const { networkError, isEditInstall } = useSelector((state: IStore) => state.general);
    const { makeApiCall } = useHandleApiCall();
    const { id } = useQueryParam();
    const { handleResponseErrorsForEditInstall } = useHandleResponseErrors();
    const { sameEnvConfiguration } = useSelector((state: IStore) => state.general.env);

    const handleEditInstall = async (environment: IInstalledEnv) => {
        dispatch(setModalType('loading'));
        dispatch(setModalStatus(true));
        dispatch(setIsEditInstall(true));
        const envId = environment.id || '';
        // Attach IPack to environment
        const { envQuery } = getInstallEnv(id, [envId]);
        const res = await makeApiCall(envQuery);

        const envArr: IKeyStringValueString = {};
        res.data.integrationPackInstanceEnvironmentAttachmentAttach.forEach((env: IInstalledEnv) => {
            envArr[env.environmentId] = env.id as string;
        });

        const selectedEnv = res.data.integrationPackInstanceEnvironmentAttachmentAttach.map((environments: IInstalledEnv) => ({
            name: environment.name,
            id: environments.environmentId,
            environmentId: environments.id,
            classification: environments.classification ? environments.classification : 'NO classification set in BE',
        }));
        dispatch(setSelectedEnvironments(selectedEnv));

        const conditionalRouteStep = await getExtensionsData(id, [envId]);

        const { query: processQuery } = getDynamicProcessesListQuery(id);
        const processListResponse = await makeApiCall(processQuery);
        if (processListResponse?.data?.integrationPackInstanceProcesses?.length) {
            dispatch(setInstanceProcesses(processListResponse.data.integrationPackInstanceProcesses.map((item: IInstanceProcess) => ({ label: item.name, value: item.id }))));
        }
        if (!conditionalRouteStep) {
            return;
        }
        navigate(`/edit/multi${conditionalRouteStep}?id=${id}&integrationPackId=${selectedIPack.id}&envIds=${envId.toString()}&sameConfig=${sameEnvConfiguration}`);
        dispatch(setModalStatus(false));
    };

    const handleEditUninstallIpackInstance = () => {
        dispatch(setIsEditUninstallIpackInstance(true));
        dispatch(setModalStatus(true));
        dispatch(setModalType(''));
    };

    const handleDone = () => {
        navigate('/');
    };

    useEffect(() => {
        networkError && isEditInstall && handleResponseErrorsForEditInstall();
    }, [networkError]);

    return { handleEditInstall, handleEditUninstallIpackInstance, handleDone };
};

export default useEditInstall;
