import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExEmptyState } from '@boomi/exosphere';
import { EMPTY_STATE } from 'constants/modal';
import { IBlankStateProps } from '../../../interfaces/IModal';

export const BlankState = (props: IBlankStateProps) => {
    const { t } = useTranslation();

    const { icon, name, label } = EMPTY_STATE[props.type];

    return (
        <div data-testid="blankState">
            <ExEmptyState text={t(name)} icon={icon} label={t(label)}></ExEmptyState>
        </div>
    );
};
export default BlankState;
