import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IStore } from 'interfaces/IStore';

export const useConditionalRoutes = () => {
    const { pathname, search } = useLocation();
    const { extensionsStatus } = useSelector((state: IStore) => state.general);
    const step = useSelector((state: IStore) => state.general.env?.step) || 0;
    const { isConnection, isProperties, isDataMaps } = extensionsStatus || { isConnection: false, isProperties: false, isDataMaps: false };
    const nextStep = step + 1;

    const getConfigureConnectionPaths = () => {
        const mapsPath = isDataMaps ? 'data-maps' : 'confirmation';
        const intermediatePath = isProperties ? 'properties' : mapsPath;
        const dataMapsPath = isDataMaps ? `${pathname}/data-maps${search}` : `/install/multi/configureDifferent/${nextStep}${search}`;
        const propertiesPath = isProperties ? `${pathname}/properties${search}` : dataMapsPath;

        return {
            intermediatePath,
            propertiesPath,
        };
    };

    const getPropertiesPaths = () => {
        const nextPathStep = isDataMaps ? 'data-maps' : 'confirmation';
        const afterPropertiesStepCofig = isConnection ? `configureDifferent/${nextStep}` : `${nextStep}/properties`;
        const afterPropertiesStep = isDataMaps ? `${pathname}/data-maps` : `/install/multi/${afterPropertiesStepCofig}`;

        return {
            nextPathStep,
            afterPropertiesStep,
        };
    };

    const getDataMapsPaths = () => {
        const intermediatePathStep = isProperties ? `${nextStep}/properties` : `${nextStep}/data-maps`;

        return isConnection ? `configureDifferent/${nextStep}` : intermediatePathStep;
    };

    return { getConfigureConnectionPaths, getPropertiesPaths, getDataMapsPaths };
};

export default useConditionalRoutes;
