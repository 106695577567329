import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from 'pages/Activity.module.scss';

import useActivityPage from 'hooks/useActivityPage';
import GenericContainer from 'containers/GenericContainer';
import HistoryFilters from 'components/History/HistoryFilters';
import ProcessesTable from 'components/common/ProcessesTable/ProcessesTable';
import HistoryTableRow from 'components/History/HistoryTableRow';
import Spinner from 'components/common/Spinner/Spinner';
import EndpointError from 'components/common/EndpointError/EndpointError';
import { IStore } from 'interfaces/IStore';
import { PROCESSES_HISTORY_COLS } from 'constants/processesTableCols';
import { NUMBER_OF_EXECUTION_HISTORY } from '../constants/api';
import DialogBox from '../components/common/DialogBox/DialogBox';

const Activity = () => {
    const { activity } = styles;
    const { filteredProcessesHistory, setSearchQuery, searchQuery, selectedExecutionsLimit, setSelectedExecutionsLimit, setSelectedDate, setProcessStatus, getExecutionHistory, selectedDate } =
        useActivityPage();

    const { isLoading, networkError } = useSelector((state: IStore) => state.general);
    const { t } = useTranslation();

    const renderProcessTable = (loading: boolean, historyData: any, historyCols: any, tableRow: any, executionLimit: any) => {
        return !loading ? <ProcessesTable data={historyData} tableCols={historyCols} component={tableRow} itemsPerPage={Number(executionLimit)} /> : <Spinner />;
    };

    return (
        <>
            <GenericContainer name={t('label.activity')} title={'activityPage.title'}>
            <div className={`${activity} generic-container-subtitle`}>
                    <div className={styles.description}> {t('activityPage.description', { numberOfExecutionHistoryResults: NUMBER_OF_EXECUTION_HISTORY })}</div>
                </div>
                <hr className="divider-line" />
                {!networkError ? (
                    <>
                        <HistoryFilters
                            {...{
                                searchQuery,
                                setSearchQuery,
                                setProcessStatus,
                                setSelectedDate,
                                selectedExecutionsLimit,
                                setSelectedExecutionsLimit,
                                getExecutionHistory,
                                selectedDate: new Date(selectedDate),
                            }}
                        />
                        {renderProcessTable(isLoading, filteredProcessesHistory, PROCESSES_HISTORY_COLS, HistoryTableRow, selectedExecutionsLimit)}
                    </>
                ) : (
                    <EndpointError message="error.500.history" type="danger" />
                )}
            </GenericContainer>
            <DialogBox />
        </>
    );
};

export default Activity;
