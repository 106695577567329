import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useAccordionToggle = (noOfItems: number, process: string) => {
    const [accordionListStatus, setAccordionListStatus] = useState<boolean[]>([]);

    useEffect(() => {
        setAccordionListStatus(
            Array(noOfItems)
                .fill(false)
                .map((item: boolean, index: number) => (index === 0 ? true : item)),
        );
    }, [process, noOfItems]);

    const handleAccordionToggle = (e: CustomEvent, i: number) => {
        const open = e.detail.detail.open ?? false;
        open && toast.dismiss();
        setAccordionListStatus(accordionListStatus.map((item: boolean, index: number) => (index === i ? open : item)));
    };

    return { accordionListStatus, handleAccordionToggle };
};

export default useAccordionToggle;
