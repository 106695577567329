import { PLATFORM_REDIRECT } from 'constants/general';
import JWT from 'utils/JWT';
import { store } from '../store/store';
import { resetLoginState, setAccountId, setJwt, setSpaceAccountId, setTokenId, setTokenSecret, setUserId } from '../store/actions/loginActions';
import { resetState } from 'store/actions/generalActions';

export const getCookieByName = (name: string): string | null => {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(';')
            .map((c) => c.trim())
            .filter((cookie) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
};

export const deleteCookie = (name: string) => {
    document.cookie = name + '=;domain=.' + getDomainFromUrl(window.location.href) + ';path=/;max-age=0';
};

export const generateJWT = async (platformHost: string, accountCookie: string, SPACE_GUID = '') => {
    const { accountId, spaceAccountId } = store.getState().login;
    const response = await JWT.getJwt(platformHost, accountCookie === 'SPACE_ACCOUNT_ID' ? spaceAccountId : accountId, SPACE_GUID);
    store.dispatch(setJwt(response));
};

export const checkTokenIdTokenSecret = (redirectUrl: string, SPACE_GUID = '') => {
    const tokenId = getTokenId();
    const tokenSecret = getTokenSecret();
    if (!tokenId || tokenId === undefined || !tokenSecret || tokenSecret === undefined) {
        redirectToPlatform(redirectUrl);
    }

    generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, 'SPACE_ACCOUNT_ID', SPACE_GUID);
};

export const generateJwtByCookie = async (spaceAccountId: string | null, SPACE_GUID = '') => {
    if (spaceAccountId) {
        await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, 'SPACE_ACCOUNT_ID', SPACE_GUID);

        return;
    }

    store.dispatch(setSpaceAccountId(''));
    await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, 'ACCOUNT_ID', SPACE_GUID);
};

export const redirectToPlatform = (redirectUrl: string) => {
    invalidateTokens();
    window.location.replace(PLATFORM_REDIRECT(redirectUrl));
};
export const getTokenId = () => {
    const currentTokenIdFromRedux = store.getState().login.tokenId;
    let tokenValue = getCookieByName('TOKEN_ID');
    if (tokenValue != null) {
        store.dispatch(setTokenId(tokenValue));
        deleteCookie('TOKEN_ID');
    } else {
        tokenValue = currentTokenIdFromRedux;
    }
    return tokenValue || undefined;
};

export const getTokenSecret = () => {
    const currentTokenSecretFromRedux = store.getState().login.tokenSecret;
    let tokenValue = getCookieByName('TOKEN_SECRET');
    if (tokenValue != null) {
        store.dispatch(setTokenSecret(tokenValue));
        deleteCookie('TOKEN_SECRET');
    } else {
        tokenValue = currentTokenSecretFromRedux;
    }
    return tokenValue || undefined;
};

export const getAccountId = () => {
    const accountIdFromRedux = store.getState().login.accountId;
    let accountId = getCookieByName('ACCOUNT_ID');
    if (accountId != null) {
        store.dispatch(setAccountId(accountId));
        deleteCookie('ACCOUNT_ID');
    } else {
        accountId = accountIdFromRedux;
    }
    return accountId;
};

export const getUserId = () => {
    const userIdFromRedux = store.getState().login.userId;
    let userId = getCookieByName('USER_ID');
    if (userId != null) {
        store.dispatch(setUserId(userId));
        deleteCookie('USER_ID');
    } else {
        userId = userIdFromRedux;
    }
    return userId;
};

export const invalidateTokens = () => {
    deleteCookie('TOKEN_ID');
    deleteCookie('TOKEN_SECRET');
    deleteCookie('USER_ID');
    deleteCookie('ACCOUNT_ID');
    store.dispatch(resetLoginState(undefined));
    store.dispatch(resetState(undefined));
};

export const getDomainFromUrl = (urlString: string) => {
    const url = new URL(urlString);
    const parts = url.hostname.split('.');
    return parts.slice(-2).join('.');
};
