import React from 'react';

import DropdownField from 'components/common/DropdownField/DropdownField';
import SearchBar from 'components/common/SearchBar/SearchBar';
import { IGenericActionToolbar } from 'interfaces/IGenericActionToolbar';
import { DROPDOWN_DATA } from 'constants/dropdown/dropdownData';

import styles from 'components/common/ActionToolbar/ActionToolbar.module.scss';

const ActionToolbar: React.FC<IGenericActionToolbar> = ({ hasSort = false, data }) => {
     const { actionToolbar } = styles;
 
     return ( 
         <div className={`containerWrapper ${actionToolbar}`}>
             <SearchBar data={data as []} placeholder='search.integrations' />
             {hasSort && <DropdownField options={DROPDOWN_DATA} />}
         </div>
     )
}

export default ActionToolbar;
