/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'interfaces/IStore';
import { setDataMaps } from 'store/actions/generalActions';
import { genericSearch } from 'utils/genericSearch';
import { ICheckedValues, IExtendedMappings, ISourceField } from 'interfaces/IGenericTable';
import { useQueryParam } from './useQueryParam';
import { IEnvironmentElement } from '../interfaces/IEnvironment';

const useMapModal = () => {
    const { dataMaps, selectedSourceField } = useSelector((state: IStore) => state.general);
    const { selectedDataMap, extensionsProcessess } = useSelector((state: IStore) => state.general);
    const { xpath } = useSelector((state: IStore) => state.general.selectedSourceField) || { xpath: '/' };
    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env) || { sameEnvConfiguration: false, step: 0 };
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { envIds } = useQueryParam();
    const envs = envIds?.split(',');
    const { selectedProcess } = extensionsProcessess;

    const stepDataMap = dataMaps?.find((item: any) => {
        const dataMapItem = item?.find((mapItem: any) =>
            sameEnvConfiguration ? mapItem.Result.processId === selectedProcess : mapItem.Result.processId === selectedProcess && mapItem.Result.environmentId === envs[step],
        );
        if (dataMapItem) {
            return item;
        }
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [checkedValues, setCheckedValues] = useState<{ [key: string]: IExtendedMappings }>({});
    const [disableMaps, setDisableMaps] = useState<boolean>(true);

    const tableData = stepDataMap?.find((mapItem: any) => mapItem.Result.processId === selectedProcess && mapItem.Result.mapId === selectedDataMap);
    const destinationProfileNodes = tableData?.Result?.Map.DestinationProfile.Node || [];
    const extendedMappings = tableData?.Result?.Map?.ExtendedMappings?.Mapping || [];

    const availableDestinationFields = destinationProfileNodes.filter((des: any) => {
        const extendedDes = extendedMappings.find((item: any) => item.toXPath === des.xpath);

        return !(extendedDes && extendedDes.fromXPath !== xpath);
    });
    const searchResults = availableDestinationFields.filter((item: ISourceField) => genericSearch(item, ['name'], searchQuery));

    const handeSearch = (value: string) => {
        setSearchQuery(value);
    };

    const handleOnChange = (rows: IEnvironmentElement[]) => {
        const checkedValuesData: any = {};
        rows.forEach((row: IEnvironmentElement) => {
            checkedValuesData[row.xpath] = { fromXPath: xpath, toXPath: row.xpath };
        });
        setCheckedValues(checkedValuesData);
    };

    const handleApply = () => {
        toast.info<React.ReactNode>(t('toast.info'), { position: 'bottom-right', hideProgressBar: true });

        const newDataMaps = dataMaps.map((dataItem: any) => {
            const dataMapItem = dataItem?.find((mapItem: any) =>
                sameEnvConfiguration ? mapItem.Result.processId === selectedProcess : mapItem.Result.processId === selectedProcess && mapItem.Result.environmentId === envs[step],
            );
            if (!dataMapItem) {
                return dataItem;
            }

            return dataItem.map((item: any) => {
                if (item.Result.mapId !== selectedDataMap) {
                    return item;
                }

                const existingExtendedMappings = item.Result.Map.ExtendedMappings || {};
                const existingExtendedMappingsMapping = existingExtendedMappings.Mapping || [];
                const currentMappings = existingExtendedMappingsMapping.filter((extended: any) => extended.fromXPath !== xpath);

                return {
                    ...item,
                    Result: {
                        ...item.Result,
                        Map: {
                            ...item.Result.Map,
                            ExtendedMappings: {
                                ...existingExtendedMappings,
                                Mapping: [...currentMappings, ...Object.values(checkedValues)],
                            },
                        },
                    },
                };
            });
        });
        dispatch(setDataMaps(newDataMaps));
    };

    useEffect(() => {
        const values: ICheckedValues = {};
        Object.keys(destinationProfileNodes).forEach((key) => {
            const destinationProfileNode = destinationProfileNodes[Number(key)];
            const extendedItem = extendedMappings.find((item: any) => item.fromXPath === xpath && item.toXPath === destinationProfileNode.xpath);
            if (extendedItem?.toXPath) {
                values[extendedItem.toXPath] = extendedItem;
            }
        });

        setCheckedValues(values);
        setDisableMaps(true);
    }, []);

    useEffect(() => {
        if (Object.keys(checkedValues).length !== 0) setDisableMaps(false);
    }, [checkedValues]);

    const noSearchResults = !!searchResults.filter((el: any) => el.name && el.xpath).length;

    return { disableMaps, checkedValues, name: selectedSourceField?.name, searchQuery, searchResults, handleOnChange, handleApply, handeSearch, t, noSearchResults };
};

export default useMapModal;
