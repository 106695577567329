import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import TableWithCheckbox from 'components/Form/TableWithCheckbox/TableWithCheckbox';
import GenericContainer from 'containers/GenericContainer';
import RadioGroup from 'components/Form/RadioGroup/RadioGroup';
import DefaultInputField from 'components/Form/DefaultInputField/DefaultInputField';
import DialogBox from 'components/common/DialogBox/DialogBox';
import useGenericInstall from 'hooks/useGenericInstall';
import { IGenericInstall } from 'interfaces/IGeneral';
import { setModalStatus, setModalType } from 'store/actions/generalActions';
import { INSTALLED_DATA } from 'constants/formData';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton, ExLoader, LoaderSize } from '@boomi/exosphere';

import 'containers/GenericContainer.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const GenericInstall: FC<IGenericInstall> = ({ isMulti = false }) => {
    const dispatch = useDispatch();
    const { onSubmit, handleSubmit, selectedIPack, register, errors, setValue, clearErrors, t, environments, isLoading } = useGenericInstall();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    if (!environments) {
        return null;
    }

    const nameInputDefaultValue = selectedIPack?.name ? selectedIPack.name : '';

    const handleUninstall = () => {
        if (pathname === '/install' || pathname === '/install/multi') {
            navigate('/');
        } else {
            dispatch(setModalStatus(true));
            dispatch(setModalType(''));
        }
    };

    return (
        <>
            {isLoading && !nameInputDefaultValue ? (
                <div className="browse-loader">
                    <ExLoader label="" size={LoaderSize.LARGE}></ExLoader>
                </div>
            ) : (
                <GenericContainer title={t('installPage.title')} name={selectedIPack?.name}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {isMulti && (
                            <>
                                <div className="generic-container-subtitle">{t('installPage.inputLabel')}</div>
                                <DefaultInputField
                                    {...{ register, setValue, errors }}
                                    field={INSTALLED_DATA[0]}
                                    defaultValue={nameInputDefaultValue}
                                    validationOptions={{ required: true, minLength: 3 }}
                                />
                            </>
                        )}
                        <TableWithCheckbox
                            title="form.selectEnv"
                            required={false}
                            emptyErrorLabel="alert.noEnvironments"
                            {...{ register, setValue, errors, clearErrors, data: environments }}
                            headerName="tableWithCheckbox.environmentName"
                        >
                            {isMulti && <RadioGroup title="form.radioGroup.sameConfiguration.title" yesLabel="form.radioGroup.sameConfiguration.yes" noLabel="form.radioGroup.sameConfiguration.no" />}
                        </TableWithCheckbox>
                        <div className="generic-container-footer">
                            <div className="generic-container-footer__actions">
                                <ExButton
                                    role="submit"
                                    slot="footer"
                                    type={ButtonType.PRIMARY}
                                    flavor={ButtonFlavor.BASE}
                                    size={ButtonSize.DEFAULT}
                                    onClick={() => handleSubmit(onSubmit)()}
                                    data-testid="btn-continue"
                                >
                                    {t('btn.continue')}
                                </ExButton>
                                <ExButton slot="footer" type={ButtonType.SECONDARY} flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} onClick={handleUninstall} data-testid="btn-cancel">
                                    {t('btn.cancel')}
                                </ExButton>
                            </div>
                        </div>
                    </form>
                </GenericContainer>
            )}
            <DialogBox />
        </>
    );
};

export default GenericInstall;
