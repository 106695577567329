import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useHandleApiCall from 'hooks/useHandleApiCall';
import { getUninstall } from 'services/getDynamicQuery';
import { useQueryParam } from 'hooks/useQueryParam';
import { setModalStatus } from 'store/actions/generalActions';

const useUninstall = () => {
    const navigate = useNavigate();
    const { id } = useQueryParam();
    const dispatch = useDispatch();
    const { makeApiCall } = useHandleApiCall();

    const handleUninstall = async (navigatePath: string) => {
        // Uninstall IPack
        const { uninstallQuery } = getUninstall(id);
        await makeApiCall(uninstallQuery);

        // Redirect and close modal
        dispatch(setModalStatus(false));
        
        navigate(navigatePath);
    };

    return { handleUninstall };
};

export default useUninstall;
