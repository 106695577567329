import React, { FC } from 'react';

import { IModal } from 'interfaces/IModal';

import circularSpinner from 'assets/loader/circularSpinner.svg';

const LoadingModal: FC<IModal> = () => {
    return (
        <img src={circularSpinner} alt="circular spinner" />
    );
};

export default LoadingModal;
