import React from 'react';
import { useTranslation } from 'react-i18next';

import { ICustomDropdown } from 'interfaces/IModal';
import styles from 'components/common/DialogBox/DialogBox.module.scss';

const TransformModalChild: React.FC<ICustomDropdown> = ({ optionClickHandler, options }) => {
    const { dropdownOptionsCategory, dropdownOptionsCategory_line, dropdownOptions, dropdownOptionsItem } = styles;
    const { t } = useTranslation();

    return (
        <div className={dropdownOptions} data-testid="customDropdown-options">
            <ul>
                {Object.entries(options).map(([category, optionsList]) => (
                    <li key={category}>
                        <div className={dropdownOptionsCategory}>
                            <span>{t(category)}</span>
                            <div className={dropdownOptionsCategory_line} />
                        </div>
                        <ul>
                            {optionsList.map((option: string) => (
                                <li key={option} className={dropdownOptionsItem} onClick={() => optionClickHandler(option)}>
                                    {t(option)}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TransformModalChild;
