import axios from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import Auth from 'utils/Auth';
import createDeferredPromise from 'utils/CreateDeferredPromise';
import { Buffer } from 'buffer';
import { redirectToPlatform } from './JWThelper';
import { getRedirectString } from './Login';

class JWT {
    public static async getJwt(platformHost: string, accountId: string, SPACE_GUID = ''): Promise<string> {
        const now = Auth.getAuthDate();
        const awsAuth = Auth.getAWSToken(now);
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'text/plain',
            Authorization: awsAuth,
            'X-Amz-Date': now,
        };
        const deferredPromise = createDeferredPromise();

        try {
            const { data } = await axios.get(`/auth/jwt/generate/${accountId}`, {
                headers,
                skipAuthRefresh: true,
            } as AxiosAuthRefreshRequestConfig);
            deferredPromise.complete(data);
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error?.response?.status == 401 || error?.response?.status == 403) {
                    const encodedRedirectString = Buffer.from(getRedirectString(SPACE_GUID)).toString('base64');
                    redirectToPlatform(encodedRedirectString);
                }
                deferredPromise.cancel(error.message);
            } else {
                deferredPromise.cancel('An unexpected error occurred while fetching JWT');
            }
        }

        return deferredPromise.promise;
    }
}

export default JWT;
