import React, {useEffect, useState} from 'react';
import {historyStatusOptions} from 'constants/dropdown/dropdownData';
import {PROCESS_STATUS} from 'constants/processesTableCols';


const useHistoryFilters = (setProcessStatus: React.Dispatch<React.SetStateAction<string>>) => {
    const [historyFiltersStatus, setHistoryFiltersStatus] = useState<any>({
        [historyStatusOptions.complete]: false,
        [historyStatusOptions.error]: false,
    })

    const handleDropdownAction = (e: any) => {
        if (e.detail.category === historyStatusOptions.complete) {
            setHistoryFiltersStatus((prev: any) => ({
                ...prev,
                [historyStatusOptions.complete]: e.detail.newCategory,
            }));
        }
        if (e.detail.category === historyStatusOptions.error) {
            setHistoryFiltersStatus((prev: any) => ({
                ...prev,
                [historyStatusOptions.error]: e.detail.newCategory,
            }));
        }
    };

    useEffect(() => {
        const {[historyStatusOptions.complete]: complete, [historyStatusOptions.error]: error} = historyFiltersStatus;
        if (complete && error) {
            setProcessStatus(PROCESS_STATUS['historyDropdown.all']);
        } else if (complete) {
            setProcessStatus(PROCESS_STATUS['historyDropdown.complete']);
            return
        } else if (error) {
            setProcessStatus(PROCESS_STATUS['historyDropdown.error']);
            return
        } else (
            setProcessStatus(PROCESS_STATUS['historyDropdown.all'])
        )
    }, [historyFiltersStatus]);


    return ({handleDropdownAction});
};

export default useHistoryFilters;
