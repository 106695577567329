import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IButton } from 'interfaces/IButton';

import style from './Button.module.scss';

/**
 * Button generator component.
 * By default all btns's are dark (primary).
 * @light is used for light btns
 * @card is used for card btns
 * @danger is used for danger btns
 * @label is used to pass the string translation
 * @fillSpace fills extra available space, boolean value
 * @singleBtn used when we have only one btn in order to move it to right and add a min-width, boolean
 * @refresh used for refresh btns
 */
const Button: FC<IButton> = ({
    label,
    light = false,
    card = false,
    danger = false,
    fillSpace = false,
    singleBtn = false,
    refresh = false,
    buttonType = 'button',
    handleClick,
    testId,
    disabled = false
}) => {
    const { t } = useTranslation();
    const { btnPrimary, btnLight, btn, btnCardLight, btnCardPrimary, btnFillSpace, btnSingle, btnDanger, btnRefresh } = style;
    const btnType = !light ? btnPrimary : btnLight;
    const btnCardType = !light ? btnCardPrimary : btnCardLight;
    const btnModel = !card ? btnType : btnCardType;

    return (
        <button
            className={`
                ${btn} 
                ${btnModel} 
                ${fillSpace && btnFillSpace} 
                ${singleBtn && btnSingle}
                ${danger && btnDanger}
                ${refresh && btnRefresh}
                `}
            onClick={handleClick}
            type={buttonType}
            data-testid={testId}
            disabled= {disabled}
        >
            {t(label)}
        </button>
    );
};

export default Button;
