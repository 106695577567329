import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useHandleApiCall from './useHandleApiCall';
import { ICardItem } from 'interfaces/ICardContainer';
import {
    setDataMaps,
    setDifferentEnvStep,
    setExtensionsStatus,
    setIsEditInstall,
    setIsIpackEdit,
    setModalStatus,
    setModalType,
    setSameEnvConfiguration,
    setSelectedEnvironments,
    setSelectedIPack,
    updateExtensions,
} from 'store/actions/generalActions';
import { IStore } from 'interfaces/IStore';
import { ENVIRONMENTS } from 'constants/api';
import { IInstalledEnv } from 'interfaces/IInstalled';
import { IConnectionsArr } from 'interfaces/IConfigureConnection';

const useArtifactCard = (item: ICardItem, isSingleInstall: boolean) => {
    const { installedData } = useSelector((state: IStore) => state.general);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { makeApiCall } = useHandleApiCall();

    const handleInstall = () => {
        const redirectRoute = isSingleInstall ? '/install' : '/install/multi';
        const extensionsStateArr: IConnectionsArr['result'] = [];
        dispatch(setSelectedEnvironments([]));
        dispatch(setDataMaps([]));
        dispatch(setSameEnvConfiguration(false));
        dispatch(setDifferentEnvStep(0));
        dispatch(setIsIpackEdit(false));
        dispatch(setIsEditInstall(false));
        dispatch(updateExtensions(extensionsStateArr));

        dispatch(
            setExtensionsStatus({
                isDataMaps: false,
                isConnection: false,
                isProperties: false,
            }),
        );
        dispatch(setSelectedIPack(item));
        navigate(redirectRoute);
    };

    const getSelectedEnvs = async () => {
        const matchingEnvsInstanceIds: { environmentId: string; integrationPackInstanceId?: string }[] = [];
        installedData?.environments?.forEach((env) => {
            const result = env.integrationPackEnvironmentAttachment.filter((el) => el.integrationPackInstanceId === item.integrationId);
            if (result.length) matchingEnvsInstanceIds.push(...result);
        });
        const response: { data: { environments: IInstalledEnv[] } } = await makeApiCall(ENVIRONMENTS);
        return response?.data?.environments.filter((env) => matchingEnvsInstanceIds.find((el) => el.environmentId === env.id));
    };

    const handleScheduleRunClick = async () => {
        const selectedEnvironments = await getSelectedEnvs();
        dispatch(setSelectedEnvironments(selectedEnvironments));
        dispatch(setSelectedIPack(item));
        navigate(`/scheduleRun?id=${item.integrationId}`);
    };
    const handleEditClick = async () => {
        const selectedEnvironments = await getSelectedEnvs();
        dispatch(setSelectedEnvironments(selectedEnvironments));
        dispatch(setSelectedIPack(item));
        navigate(`/edit/multi?id=${item.integrationId}`);
    };

    const handleSingleIpackDeleteButton = () => {
        dispatch(setSelectedIPack(item));
        dispatch(setModalStatus(true));
        dispatch(setModalType(''));
    };

    const handleTooltipModal = (ipack: ICardItem = { id: '', installationType: '' }) => {
        dispatch(setSelectedIPack(ipack));
        dispatch(setModalStatus(true));
        dispatch(setModalType('tooltip'));
    };

    return { handleInstall, handleEditClick, handleScheduleRunClick, handleTooltipModal, handleSingleIpackDeleteButton };
};

export default useArtifactCard;
