import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IModal } from 'interfaces/IModal';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';
import { useSelector } from 'react-redux';
import { IStore } from '../../interfaces/IStore';
import { resetAbortController } from '../../utils/axiosAbortInstance';

const AccessTokenFailedModal: FC<IModal> = ({ closeModal }) => {
    const { t } = useTranslation();
    const { data} = useSelector((state: IStore) => state.general.accessTokenFailure);

    const errorMessage = data ? data : t('dialogBox.accessTokenFailed.error');

    const handleFailedCloseModal = () => {
        resetAbortController();
        closeModal();
    }

    return (
        <>
            <div>{t('dialogBox.accessTokenFailed.message', {errorMessage})}</div>

            <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large">
                <ExButton
                    className="ex-mr-small"
                    flavor={ButtonFlavor.BRANDED}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={handleFailedCloseModal}
                    data-testid="accessTokenFailedModal-close"
                >
                    {t('btn.close')}
                </ExButton>
            </div>
        </>
    );
};

export default AccessTokenFailedModal;
