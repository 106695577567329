import { generateJWT, redirectToPlatform } from '../utils/JWThelper';
import axios from 'axios';
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { store } from '../store/store';
import { Buffer } from 'buffer';
import { useDispatch } from 'react-redux';
import { resetState, setModalStatus, setModalType } from 'store/actions/generalActions';
import { resetLoginState, setAccountSearchCriteria } from 'store/actions/loginActions';
import { getRedirectString } from 'utils/Login';

const useLogout = () => {
    const spaceGuid = store.getState().login.spaceGuid;
    const dispatch = useDispatch();

    const handleLogout = async () => {
        dispatch(setModalType('loading'));
        dispatch(setModalStatus(true));
        const headers = {
            Authorization: `Bearer ${store.getState().login.jwt}`,
        };
        const { spaceAccountId } = store.getState().login;
        try {
            await axios.post(
                `/restrpc/account/${spaceAccountId}/handler/com.boomi.platform.gwt.login.LogoutHandler`,
                {
                    context: 'ATOMSPHERE',
                    accountId: spaceAccountId,
                    allSessions: false,
                    sessionIdToTerminate: store.getState().login.tokenId,
                    clientUserAgent: window.navigator.userAgent,
                },
                {
                    headers,
                } as AxiosAuthRefreshRequestConfig,
            );
            dispatch(resetLoginState(''));
            dispatch(resetState(''));
            dispatch(setAccountSearchCriteria(''));
            handlePlatformRedirect();
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error?.response?.status == 401) {
                    await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, spaceAccountId ? 'SPACE_ACCOUNT_ID' : 'ACCOUNT_ID', spaceGuid);
                    //if the call to lgout fails due to expired jwt, fetch a new jwt and retry the whole thing again
                    await handleLogout();
                }
            } else {
                console.log('Failed to invalidate token');
            }
        } finally {
            dispatch(setModalStatus(false));
        }
    };

    const handlePlatformRedirect = () => {
        const encodedRedirectString = Buffer.from(getRedirectString(spaceGuid)).toString('base64');
        redirectToPlatform(encodedRedirectString);
    };

    return { handleLogout };
};

export default useLogout;
