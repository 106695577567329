import React, { FC } from 'react';
import { IButtonIcon } from 'interfaces/IButton';
import style from './ButtonIcon.module.scss';

const ButtonIcon: FC<IButtonIcon> = ({ icon, handleClick, label, buttonType = 'button', testId, large }) => {
    const { wrapper, withLabelWrapper, largeBtn, btnContent } = style;

    const getWrapperClass = (labelValue = '') => (labelValue ? withLabelWrapper : wrapper);

    return (
        <button type={buttonType} className={large ? largeBtn : getWrapperClass(label)} onClick={handleClick} data-testid={testId}>
            <div className={btnContent}>
                {icon}
                {label && <p>{label}</p>}
            </div>
        </button>
    );
};
export default ButtonIcon;
