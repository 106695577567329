import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useHandleResponseErrors from 'hooks/useHandleResponseErrors';

import { PATH_STEPS } from 'constants/routes/pathSteps';
import { IStore } from 'interfaces/IStore';
import { setIsSearching, setModalStatus, setModalType, setIsEditInstall, setIsIpackEdit, setArtifactState } from 'store/actions/generalActions';
import { useQueryParam } from './useQueryParam';
import { ExtensionsUpdateStatus } from '../constants/extensions';
import { genericSearch } from 'utils/genericSearch';
import { IProcess, IProcessesData } from 'interfaces/IProcesses';
import useExtensionsDataMapsInstall from './useExtensionsDataMapsInstall';

import useExtensionUpdate from './useExtensionUpdate';
import { ArtifactState } from 'constants/artifact';
import { getDynamicProcessesListQuery } from 'services/getDynamicQuery';
import useHandleApiCall from './useHandleApiCall';

const useConfirmationPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { extensionsDataMapsInstallData } = useExtensionsDataMapsInstall();
    const { envIds, id } = useQueryParam();
    const { handleResponseErrors } = useHandleResponseErrors();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isInstallationComplete, setIsInstallationComplete] = useState(false);
    const { query } = getDynamicProcessesListQuery(id);
    const selectedIPack = useSelector((state: IStore) => state.general.selectedIPack);
    const { isEdit } = useSelector((state: IStore) => state.general);
    const [isPageDataLoading, setIsPageDataLoading] = useState(false);
    const { generateConnectionsPropertiesPayLoad, generateDataMapsPayLoad } = useExtensionUpdate();
    const { selectedEnvironments, modalStatus, modalType, networkError, isEditInstall } = useSelector((state: IStore) => state.general);
    const { name: ipackName } = useSelector((state: IStore) => state.general.selectedIPack) || { name: '' };
    const lastPathElements = pathname.split('/').filter((item: string) => item.length);
    const isScheduleAndRun = lastPathElements[lastPathElements.length - 1] === PATH_STEPS.scheduleRun;
    const { makeApiCall } = useHandleApiCall();
    const [pageData, setPageData] = useState<IProcessesData | null>(null);

    const handleSearch = (value: string) => {
        setSearchQuery(value);
    };

    const handleDone = () => {
        if (isEditInstall || isEdit || lastPathElements[0] === 'edit') {
            dispatch(setIsEditInstall(false));
            dispatch(setIsIpackEdit(false));
            navigate(`/edit/multi?id=${selectedIPack.integrationId}`);
        } else {
            dispatch(setArtifactState(ArtifactState.INSTALLED));
            navigate('/');
            dispatch(setIsIpackEdit(false));
            dispatch(setIsEditInstall(false));
        }
    };

    const getEnvProcesses = (environmentId: string) => {
        const itemsList = pageData ? pageData?.data?.integrationPackInstanceProcesses : [];
        return itemsList.filter((item: IProcess) => item.processSchedules.envId === environmentId);
    };

    const getSearchResults = (list: IProcess[]) => {
        return list.filter((process: IProcess) => genericSearch(process, ['name'], searchQuery));
    };

    const extensionsDataMapsInstall = async () => {
        dispatch(setModalStatus(true));
        dispatch(setModalType('loading'));
        const envs = envIds?.split(',');
        if (envs && selectedEnvironments?.length && !isScheduleAndRun) {
            const extensionsQueries = generateConnectionsPropertiesPayLoad();
            const dataMapsQueries = generateDataMapsPayLoad();

            const environments = selectedEnvironments.map((selectedEnv) => ({
                name: selectedEnv.name,
                id: selectedEnv.id,
                environmentId: selectedEnv.environmentId as string,
                classification: selectedEnv.classification,
            }));
            await extensionsDataMapsInstallData(environments, envs, extensionsQueries, dataMapsQueries);
            setIsInstallationComplete(true);
        }
        const processList = await makeApiCall(query);
        setPageData(processList);
    };

    const displayExecutionHistory = () => !isEditInstall && !isEdit && !isPartialSuccess;

    const isPartialSuccess =
        selectedEnvironments?.map((element) => element.installStatus).some((installStatus) => installStatus === ExtensionsUpdateStatus.FAILURE) &&
        selectedEnvironments?.map((element) => element.installStatus).some((installStatus) => installStatus === ExtensionsUpdateStatus.SUCCESS);

    useEffect(() => {
        const isSearching = searchQuery?.length >= 1;
        dispatch(setIsSearching(isSearching));
    }, [searchQuery]);

    useEffect(() => {
        (async () => {
            setIsPageDataLoading(true);
            await extensionsDataMapsInstall();
            dispatch(setModalStatus(false));
        })();
    }, []);

    useEffect(() => {
        pageData && setIsPageDataLoading(false);
    }, [pageData]);

    useEffect(() => {
        const shouldHandleErrors = networkError && isPartialSuccess && isInstallationComplete;
        if (shouldHandleErrors) {
            !isScheduleAndRun && handleResponseErrors();
            setIsPageDataLoading(false);
        }
    }, [networkError, isPartialSuccess, isInstallationComplete, isScheduleAndRun]);

    return {
        searchQuery,
        handleSearch,
        handleDone,
        getSearchResults,
        getEnvProcesses,
        ipackName,
        t,
        pageData,
        isScheduleAndRun,
        selectedEnvironments,
        modalStatus,
        modalType,
        navigate,
        isPageDataLoading,
        displayExecutionHistory,
        isPartialSuccess,
    };
};

export default useConfirmationPage;
