import { IHistoryExecution, IHistoryProcess } from 'interfaces/IProcessesHistory';

export const getDisplayExecutionsHistory = (itemsList: IHistoryExecution[]): IHistoryProcess[] => {
    return itemsList.map((element: IHistoryExecution) => {
        const {
            executionId,
            processName,
            status,
            executionTime,
            message,
            executionDuration,
            executionType,
            inboundDocumentCount,
            inboundDocumentSize,
            inboundErrorDocumentCount,
            outboundDocumentCount,
            outboundDocumentSize,
        } = element;

        return {
            id: executionId,
            name: processName,
            status: status,
            timeStamp: executionTime,
            message: message,
            details: [
                {
                    name: 'processesHistoryTable.processDetails.counters.duration',
                    value: `${Number(executionDuration[1]) / 1000}s`,
                },
                {
                    name: 'processesHistoryTable.processDetails.counters.type',
                    value: executionType,
                },
                {
                    name: 'processesHistoryTable.processDetails.counters.inboundDocuments',
                    value: `${inboundDocumentCount} (${Number(inboundDocumentSize[1]) / 1024}k)`,
                },
                {
                    name: 'processesHistoryTable.processDetails.counters.inboundErrors',
                    value: inboundErrorDocumentCount,
                },
                {
                    name: 'processesHistoryTable.processDetails.counters.outboundDocuments',
                    value: `${outboundDocumentCount} (${Number(outboundDocumentSize[1]) / 1024}k)`,
                },
            ],
        };
    });
};
