import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useBasePageData from './useBasePageData';
import useHandleApiCall from 'hooks/useHandleApiCall';
import { ENVIRONMENT_IPACKS } from 'constants/api';
import { IStore } from 'interfaces/IStore';
import { IEnvironmentItem, IInstalled, IInstalledData } from 'interfaces/IInstalled';
import { ICardItem } from 'interfaces/ICardContainer';
import { getUninstall } from 'services/getDynamicQuery';
import { setDataMaps, setExtensions, setInstalledData, setIsIpackEdit, setModalStatus, setNetworkError, setSearchResults, setSelectedIPack } from 'store/actions/generalActions';
import { useLocation } from 'react-router-dom';
import { useGuidParam } from './useQueryParam';

const useInstalled = (): IInstalled => {
    const dispatch = useDispatch();
    const spaceGuid = useSelector((state: IStore) => state.login.spaceGuid);
    const { pathname } = useLocation();
    const { guid } = useGuidParam();
    const { data } = useBasePageData<IInstalledData>(ENVIRONMENT_IPACKS(guid && pathname === '/' ? guid : spaceGuid));
    const [installedDataLocal, setInstalledDataLocal] = useState<ICardItem[]>([]);
    const { isSearching, searchResults } = useSelector((state: IStore) => state.general.search);
    const selectedIpack = useSelector((state: IStore) => state.general.selectedIPack);
    const { networkError, isLoading: isDataLoading } = useSelector((state: IStore) => state.general);
    const { makeApiCall } = useHandleApiCall();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const installedIPacks: ICardItem[] = [];
        data?.data?.integrationPackInstances.forEach((element: IEnvironmentItem) => {
            data?.data.space.sharedIpacks?.some((ipack: ICardItem) => {
                if (ipack.id === element.integrationPack?.id) {
                    installedIPacks.push({
                        ...ipack,
                        name: element.integrationPackOverrideName || ipack.name,
                        integrationId: element.id || ipack.id,
                    });
                }
            });
        });
        setInstalledDataLocal(installedIPacks);
        dispatch(setInstalledData(data?.data as IInstalledData['data']));
        setIsLoading(false);
    }, [data]);

    const handleUninstall = async () => {
        // Uninstall IPack
        const { uninstallQuery } = getUninstall(selectedIpack?.integrationId as string);
        const { data: uninstallData } = await makeApiCall(uninstallQuery);

        if (uninstallData?.integrationPackInstanceUninstall && uninstallData.integrationPackInstanceUninstall === selectedIpack.integrationId) {
            const filteredIpacks = installedDataLocal.filter((el) => el.integrationId !== selectedIpack?.integrationId);
            const filteredSearchResults = searchResults.filter((item) => item?.integrationId !== selectedIpack?.integrationId);

            dispatch(setModalStatus(false));
            setInstalledDataLocal(filteredIpacks);
            dispatch(setSearchResults(filteredSearchResults));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        dispatch(setExtensions([]));
        dispatch(setDataMaps([]));
        dispatch(setModalStatus(false));
        dispatch(setIsIpackEdit(false));
        dispatch(setNetworkError(false));
        setIsLoading(true);
        dispatch(setSelectedIPack({ id: '', installationType: '' }));
    }, []);

    useEffect(() => {
        networkError && setIsLoading(false);
    }, [networkError]);

    useEffect(() => {
        isDataLoading && setIsLoading(true);
    }, [isDataLoading]);

    return {
        cardData: installedDataLocal,
        searchResults: isSearching ? searchResults : installedDataLocal,
        handleUninstall,
        isLoading,
    };
};

export default useInstalled;
