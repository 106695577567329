import React, { FC } from 'react';

import Button from 'components/common/Button/Button';
import DefaultInputField from 'components/Form/DefaultInputField/DefaultInputField';
import Dropdown from 'components/common/DropdownField/Dropdown';
import useTransformAppend from 'hooks/useTransformAppend';
import { setModalType } from 'store/actions/generalActions';
import { IModal } from 'interfaces/IModal';
import { MOCK_DATA } from 'constants/dropdown/dropdownData';
import { IFormField } from 'interfaces/IForm';

import styles from 'components/common/DialogBox/DialogBox.module.scss';

const AppendModal: FC<IModal> = ({ closeModal }) => {
    const { t, dispatch, name, register, errors, setValue, appendOption, appendOptions, inputFieldList, handleOnChange } = useTransformAppend(); 
    const { dialogTitle, dialogText, dialogSections, dialogActions, dialogActions_left, dialogActions_right } = styles

    return (
        <>
            <div className={dialogTitle}>
                <h2>{t('dialogBox.append.title', { item: name })}</h2>
            </div>           
            <p className={dialogText}>{t('dialogBox.append.description')}</p>

            <div className={dialogSections}>
                <Dropdown
                    title='dialogBox.append.dropdown.label'
                    options={appendOptions}
                    selectedItem={appendOption}
                    handleChange={handleOnChange}
                    fullWidth
                    contained
                />
                {inputFieldList.map((input: IFormField ) => (
                    <DefaultInputField
                        key={input.label}
                        {...{defaultValue: '', register, errors, setValue}}
                    field = {input}                
                    />
                ))}
                <Dropdown
                    title='dialogBox.description'
                    options={MOCK_DATA}
                    selectedItem={MOCK_DATA[0].value}
                    handleChange={() => undefined}
                    contained
                    fullWidth
                />
            </div>            
            <div className={dialogActions}>
                <div className={dialogActions_left}>
                    <Button
                        label={t('btn.back')}
                        card
                        light
                        handleClick={() => dispatch(setModalType('transform'))}
                        data-testid="appendModal-back"
                    />
                </div>
                <div className={dialogActions_right}>
                    <Button
                        label={t('btn.cancel')}
                        card
                        light
                        handleClick={() => closeModal()}
                        data-testid="appendModal-cancel"
                    />
                    <Button
                        label={t('btn.apply')}
                        card
                        handleClick={() => undefined}
                        data-testid="appendModal-apply"
                    />
                </div>
                
            </div>
        </>
    );
};

export default AppendModal;
