import { IConfigureField, IConnection, IEnvironmentExtensions } from 'interfaces/IConfigureConnection';
import { SECRET_FIELDS } from 'constants/formFields';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNewConnectionsData = (sameEnvConfiguration: boolean | undefined, extensions: any, step: number, data: any) => {
    const extensionsObject = sameEnvConfiguration ? 0 : step;

    return extensions.map((item: IEnvironmentExtensions, index: number) => {
        if (index !== extensionsObject) {
            return item;
        }

        return {
            ...item,
            connections: {
                ...item.connections,
                connection: item.connections.connection.map((el: IConnection, i: number) => {
                    return {
                        ...el,
                        field: el.field.map((field: IConfigureField) => {
                            const fieldData = data[i]?.[field.id];

                            switch (true) {
                                case fieldData === '':
                                    return {
                                        ...field,
                                        useDefault: true,
                                        value: '',
                                    };

                                case !fieldData:
                                    return {
                                        ...field,
                                        useDefault: true,
                                    };

                                case SECRET_FIELDS.includes(field.id):
                                    return {
                                        ...field,
                                        value: fieldData,
                                        encryptedValueSet: true,
                                        usesEncryption: true,
                                        useDefault: false,
                                    };

                                case field.id === 'oauthOptions/OAuth2Config/authorizationParameters' || field.id === 'oauthOptions/OAuth2Config/accessTokenParameters':
                                    return {
                                        ...field,
                                        value: JSON.stringify(fieldData),
                                        useDefault: false,
                                    };

                                default:
                                    return {
                                        ...field,
                                        value: fieldData,
                                        useDefault: false,
                                    };
                            }
                        }),
                    };
                }),
            },
        };
    });
};
