import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IConnection, IExtendedConfigureConnectionData } from 'interfaces/IConfigureConnection';
import useModalStatusScroll from 'hooks/useModalStatusScroll';
import { setOAuthConnectorDetails } from 'services/getDynamicQuery';
import { IStore } from 'interfaces/IStore';
import { reorderFields } from 'utils/reorderConnectionFields';
import { setOauthTokenDetails } from '../store/actions/generalActions';

const useExtendedConfigureConnection = ({
    setConnection,
    oauthConnectorDetails,
    modalStatus,
    id,
    makeApiCall,
    environmentAttachments,
    processEnvironmentAttachments,
    process,
    getConnectionData,
}: IExtendedConfigureConnectionData) => {

    const dispatch = useDispatch();
    const { spaceGuid } = useSelector((state: IStore) => state.login);

    useModalStatusScroll(modalStatus);

    const { oauthTokenGeneratedStatus } = useSelector((state: IStore) => state.general || {});

    const saveOAuthConnectorDetails = async (data: any) => {
        const catalogsvcPayload = { ...data };
        catalogsvcPayload.oauthConnectorDetails = catalogsvcPayload?.['0'];
        catalogsvcPayload.catalogGuid = spaceGuid;
        catalogsvcPayload.isTokenGenerated = oauthTokenGeneratedStatus ? oauthTokenGeneratedStatus : false;
        const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
        await makeApiCall(oAuthConnectorDetailsMutation, true);
    };

    useEffect(() => {
        environmentAttachments && processEnvironmentAttachments(environmentAttachments);
    }, [environmentAttachments]);

    useEffect(() => {
        if (process.length) {
            const connectionData: IConnection[] = getConnectionData(process);
            connectionData && setConnection(reorderFields([...connectionData]));
        }
    }, [process]);

    useEffect(() => {
        dispatch(
            setOauthTokenDetails({
                connectionId: '',
                envId: '',
                generatedStatus: false,
                processId: '',
            }),
        );
        oauthConnectorDetails && dispatch(setOauthTokenDetails(oauthConnectorDetails));
    }, [oauthConnectorDetails]);

    return { saveOAuthConnectorDetails };
};

export default useExtendedConfigureConnection;
