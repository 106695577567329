import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircle, BsExclamationOctagon } from 'react-icons/bs';
import { IModal } from 'interfaces/IModal';
import { IStore } from 'interfaces/IStore';
import { setModalStatus } from 'store/actions/generalActions';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton, ExIcon, ExLoader, IconVariant, LoaderSize } from '@boomi/exosphere';
import styles from 'components/common/DialogBox/DialogBox.module.scss';

const RunNowModal: FC<IModal> = ({ closeModal }) => {
    const { isLoading } = useSelector((state: IStore) => state.general);
    const { selectedProcess, runStatus } = useSelector((state: IStore) => state.general.processes);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const textElements = {
        icon: runStatus?.isSuccess ? BsCheckCircle : BsExclamationOctagon,
        title: runStatus?.isSuccess ? 'dialogBox.runNow.title.success' : 'dialogBox.runNow.title.failed',
        description: runStatus?.isSuccess ? 'dialogBox.runNow.description.success' : 'dialogBox.runNow.description.failed',
    };
    const { dialog_body } = styles;
    return (
        <>
            {isLoading ? (
                <ExLoader size={LoaderSize.MEDIUM} />
            ) : (
                <>
                    <div className={dialog_body}>{t(textElements.description, { processName: `${selectedProcess?.name || ''}`, backendMessage: `${runStatus?.errorMessage || ''}` })}</div>
                    <ExIcon hideBrowserTooltip={true} slot="icon" icon="status-success" variant={IconVariant.DEFAULT} />

                    <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large">
                        <ExButton className="ex-mr-small" flavor={ButtonFlavor.BASE} type={ButtonType.SECONDARY} size={ButtonSize.LARGE} onClick={() => closeModal()} data-testid="runNowModal-done">
                            {t('btn.cancel')}
                        </ExButton>
                        <ExButton
                            flavor={ButtonFlavor.BASE}
                            type={ButtonType.PRIMARY}
                            size={ButtonSize.LARGE}
                            data-testid="runNowModal-viewExecutionHistory"
                            onClick={() => {
                                dispatch(setModalStatus(false));
                                navigate('/activity');
                            }}
                        >
                            {t('btn.viewExecutionHistory')}
                        </ExButton>{' '}
                    </div>
                </>
            )}
        </>
    );
};

export default RunNowModal;
