import React, { FC } from 'react';
import { ExAccordion, AccordionVersion, ExAccordionItem, ExAlertBanner, AlertBannerType } from '@boomi/exosphere';
import ConfirmationTable from 'components/common/ConfirmationTable/ConfirmationTable';
import { IEnvironmentElement } from 'interfaces/IEnvironment';
import AlertBox from 'components/common/AlertBox/AlertBox';
import { ExtensionsUpdateStatus } from 'constants/extensions';
import styles from 'pages/Install/ConfirmationPage/ConfirmationPage.module.scss';
import { IEnvironmentProcesses } from 'interfaces/IConfirmationTable';
import { useTranslation } from 'react-i18next';

const EnvProcesses: FC<IEnvironmentProcesses> = ({ selectedEnvs, data, getEnvProcesses }) => {
    const { t } = useTranslation();
    const { alertContainer } = styles;

    const handleProcessTableForEnvFailureOrNoAtomAttahced = (envInstallStatus: ExtensionsUpdateStatus | undefined, env: IEnvironmentElement) => {
        return envInstallStatus === ExtensionsUpdateStatus.FAILURE ? (
            <div className="alert-banner">
                <ExAlertBanner type={AlertBannerType.ERROR} open={true}>
                    {t('partialSuccess.EnvfailureMessage', { envName: env.name })}
                </ExAlertBanner>
            </div>
        ) : (
            <div className={alertContainer}>
                <AlertBox type="warning" message="alert.noAtomAttached" messageVariable={env.name} />
            </div>
        );
    };
    return (
        <>
            {selectedEnvs?.map((env, index: number) => {
                const processesList = getEnvProcesses(env.id);
                const envInstallStatus = env.installStatus;

                return (
                    <div key={env.id}>
                        <ExAccordion version={AccordionVersion.BORDER}>
                            {data?.data?.integrationPackInstanceProcesses && (
                                <>
                                    {processesList?.length ? (
                                        <ExAccordionItem open={index === 0} label={t('partialSuccess.environmentName', { envName: env.name })}>
                                            <>
                                                <ConfirmationTable data={processesList} {...{ env }} />
                                            </>
                                        </ExAccordionItem>
                                    ) : (
                                        <ExAccordionItem label={t('partialSuccess.environmentName', { envName: env.name })}>
                                            {' '}
                                            {handleProcessTableForEnvFailureOrNoAtomAttahced(envInstallStatus, env)}
                                        </ExAccordionItem>
                                    )}
                                </>
                            )}
                        </ExAccordion>
                    </div>
                );
            })}
        </>
    );
};

export default EnvProcesses;
