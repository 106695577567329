import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IProcessDetails, IProcessRowDetails } from 'interfaces/IProcessesTable';

import style from 'components/History/TableRowDetails.module.scss';

const TableRowDetails: FC<IProcessRowDetails> = ({ processDetails, errorStatus, errorMessage }) => {
    const { t } = useTranslation();
    const { container, details, detailsItem, detailsItem_name, detailsItem_value, message, messageName, messageContent } = style;

    return (
        <div className={container}>
            <div className={details}>
                {processDetails.map((el: IProcessDetails) => (
                    <div key={el.value} className={detailsItem}>
                        <span className={detailsItem_name}>{t(el.name)}</span>
                        <span className={detailsItem_value}>{el.value}</span>
                    </div>
                ))}
            </div>
            {!errorStatus && (
                <div className={message}>
                    <span className={messageName}>{t('processesHistoryTable.processDetails.message')}</span>
                    <div className={messageContent}>
                        <p>{errorMessage}</p>
                        <p>{t('processesHistoryTable.processDetails.errorTip')}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TableRowDetails;
