import { useDispatch } from 'react-redux';
import { IInstalledData } from '../interfaces/IInstalled';
import { setInstalledData } from '../store/actions/generalActions';

const useExtensionsSupportingData = () => {
    const dispatch = useDispatch();

    const updateInstalledData = (data: IInstalledData | null) => {
        data?.data && dispatch(setInstalledData(data.data));
    };

    return { updateInstalledData };
};

export default useExtensionsSupportingData;
