import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { genericSearch } from 'utils/genericSearch';
import { ISearchBar } from 'interfaces/ISearchBar';
import { setIsSearching, setSearchQuery, setSearchResults } from 'store/actions/generalActions';
import { IStore } from 'interfaces/IStore';

import styles from 'components/common/SearchBar/SearchBar.module.scss';

export const SearchBar: FC<ISearchBar> = ({ data, placeholder, large = false }) => {
    const { searchQuery } = useSelector((state: IStore) => state.general.search);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { searchBarWrapper, searchBarWrapper_title, searchBar, searchBarLarge, searchBar_input } = styles;

    const getSearchResults = () => {
        return data?.filter((item: never) => genericSearch(item, ['name'], searchQuery || ''));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(setSearchQuery(e.target.value));
    };

    useEffect(() => {
        const searchResults = getSearchResults();        

        if (searchQuery?.length >= 1) {
            dispatch(setIsSearching(true));
            dispatch(setSearchResults(searchResults as []));

            return;
        }
        dispatch(setIsSearching(false));
        dispatch(setSearchResults([]));
    }, [searchQuery]);

    useEffect(() => {
        dispatch(setIsSearching(false));
        dispatch(setSearchResults([]));
        dispatch(setSearchQuery(''));
    }, []);

    return (
        <div className={searchBarWrapper}>
            {large && <p className={searchBarWrapper_title}>{t('search.title')}</p>}
            <div className={`${searchBar} ${large && searchBarLarge}`}>
                <input className={searchBar_input} type="text" onChange={handleInputChange} placeholder={t(`${placeholder}`)} data-testid="searchbar" value={searchQuery} />
                <FiSearch size={20} />
            </div>
        </div>
    );
};

export default SearchBar;
