import axios from 'axios';
import { generateJWT } from './JWThelper';
import { store } from 'store/store';

const APIInstance = axios.create({
    baseURL: `${window.__RUNTIME_CONFIG__.REACT_APP_GRAPHQL_URL}/graphql`,
    headers: {
        'Content-Type': 'application/json',
    },
});

APIInstance.interceptors.request.use(
    (config: any) => {
        const jwt = store.getState().login.jwt;
        if (jwt) {
            config.headers['Authorization'] = `Bearer ${jwt}`;
            config.headers['SPACE_GUID'] = store.getState().login.spaceGuid;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

APIInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err?.response?.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
                const { spaceAccountId } = store.getState().login;
                await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, spaceAccountId ? 'SPACE_ACCOUNT_ID' : 'ACCOUNT_ID', err?.config?.headers?.SPACE_GUID || '');
                return APIInstance(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }

        return Promise.reject(err);
    },
);

export default APIInstance;
