// helper function - search data by specific field(s)
export const genericSearch = <T>(data: T, searchFields: Array<keyof T>, query: string): boolean => {
    if (query === '') {
        return true;
    }

    return searchFields.some((property) => {
        const value = data[property];

        if (typeof value === 'string' || typeof value === 'number') {
            return value.toString().toLowerCase().includes(query.toLowerCase());
        }

        return false;
    });
};

export const baseSearch = (field: string | number, searchQuery: string) => {
    return field.toString().toLowerCase().includes(searchQuery.toLowerCase());
};

export const artifactSearchProperties = ['name'];
