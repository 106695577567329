import { IStore } from 'interfaces/IStore';
import { useSelector } from 'react-redux';

const useDisplayEnvName = () => {
    const { selectedEnvironments, isEdit } = useSelector((state: IStore) => state.general);
    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env);
    let envName = 'All environments';

    if (!selectedEnvironments) {
        return { envName };
    }

    if (!sameEnvConfiguration && !isEdit) {
        return { envName: selectedEnvironments[step].name };
    }

    // dynamic display of Connection - All environments || Connection - Specific Env
    envName = selectedEnvironments?.length === 1 ? selectedEnvironments[0].name : 'All environments';

    return { envName };
};

export default useDisplayEnvName;
