/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IKeyStringValueString } from 'interfaces/IGeneral';
import { IDataMapsTable, IExtendedFunctions, ISourceField, ISourceProfileData, ITableItem } from 'interfaces/IGenericTable';
import { setModalStatus, setModalType, setSelectedDataMap, setSelectedSourceField } from 'store/actions/generalActions';
import { filterOptions } from '../constants/dropdown/dropdownData';
import useMappingTable from './useMappingTable';

export const useGenericTable = (data: IDataMapsTable) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [tableData, setTableData] = useState<ITableItem[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState('dropdown.allFields');
    const [filteredData, setFilteredData] = useState<ITableItem[]>([]);
    const [filters, setFilters] = useState<any>({
        [filterOptions.mapped]: false,
        [filterOptions.notMapped]: false,
    });
    const { getMappedItems } = useMappingTable();

    const handleMapClick = (params: any) => {
        const el: ISourceField = params.data;
        dispatch(setModalType('map'));
        dispatch(setModalStatus(true));
        dispatch(setSelectedSourceField(el));
        dispatch(setSelectedDataMap(data.Result.mapId || ''));
    };

    const handleTransformClick = (params: any) => {
        const el: ISourceField = params.data;
        dispatch(setModalType('transform'));
        dispatch(setModalStatus(true));
        dispatch(setSelectedSourceField(el));
    };

    const handleSearch = (value: string) => {
        setSearchQuery(value);
    };

    const handleDropdownAction = (e: any) => {
        if (e.detail.category === filterOptions.mapped)
            setFilters((prev: any) => ({
                ...prev,
                [filterOptions.mapped]: e.detail.newCategory,
            }));
        if (e.detail.category === filterOptions.notMapped)
            setFilters((prev: any) => ({
                ...prev,
                [filterOptions.notMapped]: e.detail.newCategory,
            }));
    };

    const filteredTableData = (mapData: IDataMapsTable) => {
        const mapTableData = JSON.parse(JSON.stringify(mapData));
        if (mapTableData?.Result?.Map?.SourceProfile?.Node) {
            mapTableData.Result.Map.SourceProfile.Node = mapTableData?.Result?.Map?.SourceProfile?.Node.filter((nodeItem: ISourceProfileData) => nodeItem.name);
        }
        return mapTableData;
    };

    useEffect(() => {
        setFilters({});
        setSearchQuery('');
        setSelectedItem('');
        setFilteredData(tableData);
    }, []);

    useEffect(() => {
        const { [filterOptions.mapped]: mapped, [filterOptions.notMapped]: notMapped } = filters;
        const newFilteredData: ITableItem[] = [];
        tableData.forEach((item) => {
            if (mapped && notMapped) {
                newFilteredData.push(item);
            }

            if (mapped && !item.mappedElem && mapped && !item.mappedFunction) {
                return;
            }

            if (notMapped && (item.mappedElem || item.mappedFunction)) {
                return;
            }
            newFilteredData.push(item);
        });
        setFilteredData(newFilteredData);
    }, [filters]);

    useEffect(() => {
        const functionsElement = data.Result?.Map?.ExtendedFunctions;
        let mappedFunctions: IKeyStringValueString = {};

        if (functionsElement?.Function) {
            functionsElement.Function.forEach((func: IExtendedFunctions) => {
                mappedFunctions = {
                    ...mappedFunctions,
                    [func.id]: func.type,
                };
            });
        }

        let mappedDestinationProfile: any = {};
        if (data?.Result?.Map?.DestinationProfile) {
            data.Result.Map.DestinationProfile.Node.forEach((destinationProfileNode: any) => {
                mappedDestinationProfile = {
                    ...mappedDestinationProfile,
                    [destinationProfileNode.xpath]: destinationProfileNode.name,
                };
            });
        }

        const newMappedItems: ITableItem[] = getMappedItems(filteredTableData(data), mappedFunctions, mappedDestinationProfile);

        setTableData(newMappedItems);
        setFilteredData(newMappedItems);
    }, [data]);

    return {
        searchQuery,
        selectedItem,
        tableData: filteredData,
        handleMapClick,
        handleTransformClick,
        t,
        handleSearch,
        handleDropdownAction,
    };
};
