import { IConnection } from 'interfaces/IConfigureConnection';

export const reorderFields = (connections: IConnection[], fieldIdToMoveLast = 'oauthOptions/OAuth2Config/credentials/@accessToken'): IConnection[] => {
    return connections.map(connection => {
        const sortedFields = connection.field.filter(field => field.id !== fieldIdToMoveLast);
        const fieldToMoveLast = connection.field.find(field => field.id === fieldIdToMoveLast);

        if (fieldToMoveLast) {
            sortedFields.push(fieldToMoveLast);
        }

        return {
            ...connection,
            field: sortedFields
        };
    });
};
