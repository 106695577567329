import { IStore } from 'interfaces/IStore';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSearching, setSearchQuery, setSearchResults } from 'store/actions/generalActions';
import { genericSearch } from 'utils/genericSearch';

const useSearch = (data: [], searchProperties: string[]) => {
    const { searchQuery } = useSelector((state: IStore) => state.general.search);
    const dispatch = useDispatch();

    const getSearchResults = () => {
        return data?.filter((item: any) => genericSearch(item, searchProperties, searchQuery || ''));
    };

    useEffect(() => {
        const searchResults = getSearchResults();
        if (searchQuery?.length >= 1) {
            dispatch(setIsSearching(true));
            dispatch(setSearchResults(searchResults as []));
            return;
        }
        dispatch(setIsSearching(false));
        dispatch(setSearchResults([]));
    }, [searchQuery, data]);

    const clearSearchState = () => {
        dispatch(setIsSearching(false));
        dispatch(setSearchResults([]));
        dispatch(setSearchQuery(''));
    };

    return { clearSearchState };
};

export default useSearch;
