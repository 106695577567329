import { ImageTypes } from 'constants/general';

export const trimFileNameFromLogo = (url: string): string => {
    const strBase64 = 'base64';
    const fileNameSegment = strBase64 + ';filename=';
    let fileName = '';
    Object.values(ImageTypes).forEach((type: string) => {
        if (url.indexOf(type) != -1) {
            const fileNameStartPosition = url.indexOf(fileNameSegment);
            const fileNameEndPostion = url.lastIndexOf(type);
            fileName = url.substring(fileNameStartPosition + strBase64.length, fileNameEndPostion + type.length);
        }
    });
    return url.split(fileName).join('');
};
