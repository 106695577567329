import { ButtonFlavor, ExButton, ExIcon, ExInput, ExLoader, ExMenuItem, IconVariant, LoaderSize } from '@boomi/exosphere';
import BlankState from 'components/common/BlankState/BlankState';
import { IKeyStringValueString } from 'interfaces/IGeneral';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AccountDropdown.module.scss';
import './AccountDropdown.scss';
import { ACCOUNTS_RANGE, fetchAccountsWithinRange } from 'utils/Login';

type Props = {
    onChange: any;
    searchPlaceholder: string;
    listHeader: string;
    itemNotFound: string;
    accountList: Array<any>;
    onSearch: any;
    displaySearchHeader: boolean;
    isDataLoading: boolean;
    searchKeyword: string;
};

function AccountDropdown({ accountList, onChange, searchPlaceholder, listHeader, onSearch, displaySearchHeader, isDataLoading, searchKeyword = '' }: Props) {
    const [isListOpen, setIsListOpen] = useState(false);
    const [keyword, setKeyword] = useState(searchKeyword);
    const searchField = useRef(null);
    const [showAccountRangeLabel, setShowAccountRangeLabel] = useState(true);
    const { t } = useTranslation();

    const toggleList = () => {
        setIsListOpen(!isListOpen);
        setKeyword('');
    };

    const onInputChange = (e: any) => {
        setKeyword(e.target.value);
    };

    const onAccountSelect = (value: any) => {
        onChange(value);
        toggleList();
    };

    const getAccountLabel = ({ label, value }: IKeyStringValueString) => (label ? label.slice(0, 1) : value.slice(0, 1));

    const { ddList, ddListSearchBar, ddWrapper, listItemContent, listItemContentHead, listItemContentName, listItemContentValue, searchable, ddListHeader } = styles;
    const renderAccountList = (accounts: any) => {
        return accounts.length ? (
            <div className={ddListSearchBar}>
                {fetchAccountsWithinRange(accounts, keyword).map((item: any) => (
                    <ExMenuItem key={item.value} onClick={() => onAccountSelect(item.value)} data-testid={item.value}>
                        <div className={`${listItemContent}`}>
                            <span className={`${listItemContentHead}`}>{getAccountLabel(item)}</span>
                            <span>
                                {item.label && <div className={`${listItemContentName}`}>{item.label}</div>}
                                <span className={`${listItemContentValue}`}>ID: {item.value}</span>
                            </span>
                        </div>{' '}
                    </ExMenuItem>
                ))}
            </div>
        ) : (
            <div className="account-list_empty-state">
                <BlankState type="noAccountsFound" />
            </div>
        );
    };

    const handleAccountSearch = () => {
        onSearch(keyword);
        setShowAccountRangeLabel(!keyword.length);
    };

    return (
        <div className={`${ddWrapper}`}>
            <div className={`${ddList} ${searchPlaceholder ? searchable : ''}`}>
                <form
                    onKeyUp={(e) => {
                        if (e.key === 'Enter' || e.code === 'Enter') {
                            e.preventDefault();
                            handleAccountSearch();
                        }
                    }}
                    data-testid="login-form"
                >
                    <div className={`${ddListHeader} account-list__search`}>
                        {searchPlaceholder && (
                            <div className="account-list__search-wrap">
                                <div className="account-list__search-label">{t('login.searchInputLabel')}</div>
                                <ExInput
                                    ref={searchField}
                                    className={`${ddListSearchBar} account-list__search-input`}
                                    placeholder={searchPlaceholder}
                                    onInput={onInputChange}
                                    label=""
                                    clearable={true}
                                    data-testid="acct-search-input"
                                    value={keyword}
                                >
                                    <ExIcon hideBrowserTooltip={true} icon="magnifying-glass" variant={IconVariant.ICON} slot="prefix"></ExIcon>
                                </ExInput>
                            </div>
                        )}
                        <ExButton className="account-list__search-btn" onClick={handleAccountSearch} flavor={ButtonFlavor.BRANDED}>
                            {t('login.searchBtnLabel')}
                        </ExButton>
                    </div>
                </form>
                {isDataLoading ? (
                    <div className={ddListSearchBar}>
                        <ExLoader size={LoaderSize.MEDIUM} inline={true} label={t('login.loaderLabel')}></ExLoader>
                    </div>
                ) : (
                    <div>
                        {displaySearchHeader && (
                            <div>
                                <div>
                                    {accountList && (
                                        <div>
                                            <div>{listHeader}</div>
                                            {renderAccountList(accountList)}
                                        </div>
                                    )}
                                </div>
                                {!!(accountList?.length >= ACCOUNTS_RANGE && showAccountRangeLabel) && (
                                    <div className="account-list_count-label">{t('accountDropdown.noOfAccountsDisplayed', { range: ACCOUNTS_RANGE, total: accountList.length })}</div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default AccountDropdown;
