import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsExclamationTriangle } from 'react-icons/bs';

import Button from 'components/common/Button/Button';

import style from './ErrorContainer.module.scss';

export const ErrorContainer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { wrapper, wrapperTitle, wrapperDescription } = style;

    return (
        <div className={wrapper}>
            <BsExclamationTriangle />
            <p className={wrapperTitle}>{t('error.404.title')}</p>
            <p className={wrapperDescription}>{t('error.404.description')}</p>
            <Button light card label="error.btn" handleClick={() => navigate('/')}/>
        </div>
    );
};

export default ErrorContainer;
