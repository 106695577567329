import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GenericContainer from 'containers/GenericContainer';
import { IStore } from 'interfaces/IStore';
import { PATH_STEPS } from 'constants/routes/pathSteps';
import { setIsEditInstall, setIsEditUninstallFromLastEnv, setIsEditUninstallIpackInstance, setIsIpackEdit, setModalStatus, setNetworkError } from 'store/actions/generalActions';

import genericContainerStyle from 'containers/GenericContainer.module.scss';
import style from 'pages/Install/ConfirmationPage/ConfirmationPage.module.scss';
import { ButtonFlavor, ButtonSize, ExButton, ExIcon, IconVariant } from '@boomi/exosphere';
import DialogBox from 'components/common/DialogBox/DialogBox';

const EndpointErrorPage = () => {
    const dispatch = useDispatch();
    const { name: ipackName } = useSelector((state: IStore) => state.general.selectedIPack) || { name: '' };
    const { isEditInstall, selectedIPack, isEdit, isEditUninstallIpackInstance, isEditUninstallFromLastEnv } = useSelector((state: IStore) => state.general);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const lastPathElements = pathname.split('/');
    const { wrapperActions_container } = genericContainerStyle;
    const { subtitleWithIcon, processDescription, subtitleWithIcon_icon } = style;
    const page = lastPathElements[lastPathElements.length - 1];

    const handleDone = () => {
        dispatch(setModalStatus(false));
        if (isEditInstall) {
            dispatch(setNetworkError(false));
            dispatch(setIsEditInstall(false));
            navigate(`/edit/multi?id=${selectedIPack.integrationId}`);
        } else if (isEdit) {
            dispatch(setNetworkError(false));
            dispatch(setIsIpackEdit(false));
            const navPath = selectedIPack.integrationId?.length ? `/edit/multi?id=${selectedIPack.integrationId}` : '/';
            navigate(navPath);
        } else if (isEditUninstallIpackInstance || isEditUninstallFromLastEnv) {
            navigate('/');
            dispatch(setIsEditUninstallFromLastEnv(false));
            dispatch(setIsEditUninstallIpackInstance(false));
        } else {
            navigate('/');
        }
    };

    const getDisplayData = () => {
        switch (page) {
            case PATH_STEPS.scheduleError:
                return {
                    pageTitle: 'scheduleErrorPage.title',
                    pageSubtitle: 'scheduleErrorPage.failedSchedule',
                    pageDescription: 'scheduleErrorPage.description',
                    buttonLabel: 'Done',
                };

            case PATH_STEPS.uninstall:
                return {
                    pageTitle: 'editUninstallIpackInstance.title',
                    pageSubtitle: 'editUninstallIpackInstance.subtitle',
                    pageDescription: 'editUninstallIpackInstance.description',
                    buttonLabel: 'Done',
                };

            case PATH_STEPS.editError:
                return {
                    pageTitle: 'editErrorPage.title',
                    pageSubtitle: 'editErrorPage.subtitle',
                    pageDescription: 'editErrorPage.description',
                    buttonLabel: 'editErrorPage.buttonLabel',
                };
            default:
                return {
                    pageTitle: 'form.installAndConfigure',
                    pageSubtitle: 'installErrorPage.failedInstalation',
                    pageDescription: 'installErrorPage.description',
                    buttonLabel: 'Done',
                };
        }
    };

    const { pageTitle, pageSubtitle, pageDescription, buttonLabel } = getDisplayData();

    return (
        <>
            <GenericContainer name={ipackName} title={pageTitle}>
                <div className={subtitleWithIcon}>
                    <ExIcon hideBrowserTooltip={true} icon="status-fail" variant={page === PATH_STEPS.uninstall ? IconVariant.SECONDARY : IconVariant.DANGER} className={subtitleWithIcon_icon} />
                    <div className="generic-container-subtitle">{t(pageSubtitle)}</div>
                </div>
                <p className={processDescription}>{t(pageDescription)}</p>
                <div>
                    <div className={`${wrapperActions_container} generic-container-footer generic-container-footer__actions`}>
                        <ExButton flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} onClick={handleDone}>
                            {t(buttonLabel)}
                        </ExButton>
                    </div>
                </div>
                <DialogBox />
            </GenericContainer>
        </>
    );
};
export default EndpointErrorPage;
