import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExPagination } from '@boomi/exosphere';

import BlankState from 'components/common/BlankState/BlankState';
import usePagination from 'hooks/usePagination';
import { IProcessesTable } from 'interfaces/IProcessesTable';

import style from 'components/common/ProcessesTable/ProcessesTable.module.scss';
import HistoryTableRow from 'components/History/HistoryTableRow';

export const ProcessesTable: FC<IProcessesTable> = ({ data, tableCols }) => {
    const { t } = useTranslation();
    const { paginatedData, pageSize, selectedPage, handlePaginationChange, pageSizeOptions, maxPageNum } = usePagination(data, '', [10, 25, 50]);

    const { table, tableColumns, tableColumns_column } = style;
    return (
        <>
            <div className={table}>
                <div className={tableColumns}>
                    {tableCols.map((el: string) => {
                        return (
                            <div className={tableColumns_column} key={el}>
                                {t(el)}
                            </div>
                        );
                    })}
                </div>
                {paginatedData.length > 0 ? paginatedData.map((item: any) => <HistoryTableRow key={item.id} item={item} />) : <BlankState type="noIntegrationFound" />}
                <div className="ex-mt-2x-large ex-mb-5x-large">
                    <ExPagination
                        pageSize={pageSize}
                        pageSizeOptions={pageSizeOptions}
                        selectedPage={selectedPage}
                        totalItems={data.length}
                        maxPageNum={maxPageNum}
                        onChange={(e: CustomEvent<any>) => handlePaginationChange(e)}
                    />
                </div>
            </div>
        </>
    );
};

export default ProcessesTable;
