/* We are using AbortController to stop the axios post call when user clicks on closeModal
* This might be not a great solution, we will explore other options later.
* Link - https://axios-http.com/docs/cancellation
 */

import axios from 'axios';

let abortController: AbortController | undefined;

// Function to create a new AbortController instance
export function createAbortController() {
    abortController = new AbortController();
}

// Function to reset AbortController (abort any ongoing request)
export function resetAbortController() {
    abortController?.abort();
}

// Create a new axios instance with abortController's signal
const axiosAbortInstance = axios.create();

export default axiosAbortInstance;

export { abortController }