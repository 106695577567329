
import useHandleResponseErrors from 'hooks/useHandleResponseErrors';

const useDataMapsValidatorAndErrorHandler = () => {
    const { handleResponseErrors, handleResponseErrorsForEditInstall } = useHandleResponseErrors();



    const handleUseDataMapsErrorResponse = (isEditInstall: boolean, networkError: boolean | undefined): void => {
        if (isEditInstall) {
            networkError && handleResponseErrorsForEditInstall();
        } else {
            networkError && handleResponseErrors();
        }
    };
    return { handleUseDataMapsErrorResponse };
};
export default useDataMapsValidatorAndErrorHandler;
