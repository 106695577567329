import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useHandleApiCall from 'hooks/useHandleApiCall';
import { generateJWT, generateJwtByCookie, getAccountId, getTokenId, getTokenSecret, getUserId, redirectToPlatform } from 'utils/JWThelper';
import { SPACE_ACCOUNTS } from 'constants/api';
import { IStore } from 'interfaces/IStore';
import { Buffer } from 'buffer';
import { store } from '../store/store';
import { setModalStatus, setModalType } from 'store/actions/generalActions';
import { getRedirectString } from 'utils/Login';
import { setJwt, setSpaceAccountId } from 'store/actions/loginActions';
import { IKeyStringValueString } from 'interfaces/IGeneral';

export const useLoginForm = () => {
    const { makeApiCall } = useHandleApiCall();
    const navigate = useNavigate();
    const mounted = useRef(false);
    const dispatch = useDispatch();

    const [accountList, setAccountList] = useState([]);
    const [filteredAccountList, setFilteredAccountList] = useState([]);
    const { spaceGuid, spaceAccountId } = useSelector((state: IStore) => state.login);
    const [isLoading, setLoading] = useState(false);
    const [isAccountsLoading, setIsAccountsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleAccountSelection = async (accountId: string) => {
        try {
            dispatch(setModalStatus(true));
            dispatch(setModalType('loading'));
            dispatch(setSpaceAccountId(accountId));

            await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, 'SPACE_ACCOUNT_ID', spaceGuid);
            navigate('/');
        } catch (error) {
            dispatch(setSpaceAccountId(''));
        } finally {
            dispatch(setModalStatus(false));
        }
    };

    const getAccountList = async (accSearchQuery: string) => {
        setLoading(true);
        const existingJWT = store.getState().login.jwt;
        const accountId = getAccountId();
        getUserId();

        if (!accountId) {
            handlePlatformRedirect();
        }

        if (!existingJWT) {
            if (!spaceAccountId && (!accountId || accountId === 'undefined')) {
                dispatch(setSpaceAccountId(''));
                handlePlatformRedirect();
                return;
            }
            const tokenId = getTokenId();
            const tokenSecret = getTokenSecret();
            if (spaceAccountId && (!tokenId || tokenId === 'undefined' || !tokenSecret || tokenSecret === 'undefined')) {
                handlePlatformRedirect();
                return;
            }

            dispatch(setSpaceAccountId(''));
            await generateJwtByCookie(spaceAccountId, spaceGuid);
        }

        const response = await makeApiCall(SPACE_ACCOUNTS(accSearchQuery));

        if (!response) {
            dispatch(setJwt(''));
        }

        if (!response?.data?.spaceAccounts) {
            setAccountList([]);
            setFilteredAccountList([]);
            setLoading(false);
            return;
        }

        if (response.data.spaceAccounts.length > 1) {
            setLoading(false);
            setAccountList(response.data.spaceAccounts);
            setFilteredAccountList(response.data.spaceAccounts);
            return;
        }

        dispatch(setSpaceAccountId(response.data.spaceAccounts[0]?.id));
        await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, 'SPACE_ACCOUNT_ID', spaceGuid);
        setLoading(false);
        navigate('/');
    };

    const handlePlatformRedirect = () => {
        const encodedRedirectString = Buffer.from(getRedirectString(spaceGuid)).toString('base64');
        redirectToPlatform(encodedRedirectString);
    };

    const filterValue = (value: string, searchString: string) => value.toLowerCase().replace(/ /g, '').includes(searchString.toLowerCase().replace(/ /g, ''));

    const onSearch = async (criteria: string) => {
        setIsAccountsLoading(true);
        setSearchQuery(criteria);
        if (accountList.length) {
            setFilteredAccountList(accountList.filter((account: IKeyStringValueString) => filterValue(account.id, criteria) || filterValue(account.name, criteria)));
        }
        setIsAccountsLoading(false);
    };

    useEffect(() => {
        (async () => {
            getAccountList('');
            if (!mounted.current) {
                mounted.current = true;
            }
            dispatch(setModalStatus(false));
        })();
    }, []);

    return { isLoading, accountList: filteredAccountList, mounted, handleAccountSelection, handlePlatformRedirect, onSearch, isAccountsLoading, searchQuery };
};
