import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IDropdownField } from 'interfaces/IDropdownField';
import { setDropdownOption, setSearchQuery } from 'store/actions/generalActions';

import Dropdown from './Dropdown';

export const DropdownField: FC<IDropdownField> = ({
    options,
    callback,
    title = 'dropdown.title',
    contained = false,
    fullWidth = false,
    required = false,
}) => {
    const [selectedItem, setSelectedItem] = useState<string>(options[0].value);
    const dispatch = useDispatch();

    const handleChange = (e: CustomEvent): void => {
        const value = e.detail.value;
        setSelectedItem(value);
        dispatch(setDropdownOption(value));
        dispatch(setSearchQuery(''));
        callback?.();
    };

    useEffect(() => {
        dispatch(setDropdownOption(selectedItem));

        return () => {
            dispatch(setDropdownOption(''));
        };
    }, []);

    return <Dropdown {...{ options, title, contained, fullWidth, required, selectedItem, handleChange }} />;
};

export default DropdownField;
