import { AnyAction } from 'redux';
import { ILoginReducer } from 'interfaces/ILoginReducer';
import { SET_SPACE_GUID, SET_TOKEN_ID, SET_TOKEN_SECRET, SET_JWT, RESET_LOGIN_STATE, SET_ACCOUNT_SEARCH_CRITERIA, SET_ACCOUNT_ID, SET_USER_ID, SET_SPACE_ACCOUNT_ID } from 'store/actions/actionTypes';

const initialState = {
    spaceGuid: '',
    tokenId: '',
    tokenSecret: '',
    jwt: '',
    accountSearchCriteria: '',
    userId: '',
    accountId: '',
    spaceAccountId: '',
};

export default (state: ILoginReducer = initialState, action: AnyAction = { type: '', payload: null }) => {
    switch (action.type) {
        case SET_SPACE_GUID: {
            return {
                ...state,
                spaceGuid: action.payload,
            };
        }
        case SET_TOKEN_ID: {
            return {
                ...state,
                tokenId: action.payload,
            };
        }
        case SET_TOKEN_SECRET: {
            return {
                ...state,
                tokenSecret: action.payload,
            };
        }
        case SET_JWT: {
            return {
                ...state,
                jwt: action.payload,
            };
        }
        case RESET_LOGIN_STATE: {
            return {
                ...state,
                spaceGuid: '',
                tokenId: '',
                tokenSecret: '',
                jwt: '',
                userId: '',
                accountId: '',
                spaceAccountId: '',
            };
        }
        case SET_ACCOUNT_SEARCH_CRITERIA: {
            return { ...state, accountSearchCriteria: action.payload };
        }

        case SET_ACCOUNT_ID: {
            return { ...state, accountId: action.payload };
        }
        case SET_USER_ID: {
            return { ...state, userId: action.payload };
        }
        case SET_SPACE_ACCOUNT_ID: {
            return { ...state, spaceAccountId: action.payload };
        }
        default:
            return state;
    }
};
