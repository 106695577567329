import React from 'react';

import GenericInstall from 'components/common/GenericInstall';

export const MultiPackInstall = () => {
    return (
        <GenericInstall isMulti />
    );
};

export default MultiPackInstall;
