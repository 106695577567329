import { useDispatch, useSelector } from 'react-redux';
import useHandleApiCall from 'hooks/useHandleApiCall';
import { IStore } from 'interfaces/IStore';
import { ExtensionsUpdateStatus } from '../constants/extensions';

import useUnattach from './useUnattach';

import useHandleEnvInstallStatus from './useHandleEnvInstallStatus';
import { setSelectedEnvironments } from 'store/actions/generalActions';
import useHandleInstallError from './useHandleInstallError';
import useHandleResponseErrors from './useHandleResponseErrors';
import { IEnvironmentElement } from 'interfaces/IEnvironment';
import { ENVIRONMENT_IPACKS } from 'constants/api';
import useExtensionsSupportingData from './useExtensionsSupportingData';

const useExtensionsDataMapsInstall = () => {
    const { handleEnvInstallStatus } = useHandleEnvInstallStatus();
    const { handleAPIPromise, makeApiCall } = useHandleApiCall();
    const { handleUnattachForPartialInstall } = useUnattach();
    const dispatch = useDispatch();
    const { handleInstallError } = useHandleInstallError();
    const { handleResponseErrorsForEditInstall } = useHandleResponseErrors();

    const { selectedEnvironments, isEdit, isEditInstall } = useSelector((state: IStore) => state.general);
    const spaceGuid = useSelector((state: IStore) => state.login.spaceGuid);
    const { updateInstalledData } = useExtensionsSupportingData();

    const extensionsDataMapsInstallData = async (
        environments: {
            name: string;
            id: string;
            environmentId: string;
            classification: string;
        }[],
        envs: string[],
        extensionsQueries: any,
        dataMapsQueries: any,
    ) => {
        if (envs && selectedEnvironments?.length) {
            let envData = [...environments];
            for (const env of envs) {
                const extensionQuery = extensionsQueries[env];
                const dataMapQuery = dataMapsQueries[env];
                try {
                    extensionQuery && (await Promise.all(extensionQuery.map((item: string) => handleAPIPromise(item))));
                    dataMapQuery && (await handleAPIPromise(dataMapQuery));
                    envData = handleEnvInstallStatus(envData, env, ExtensionsUpdateStatus.SUCCESS);
                } catch (err: any) {
                    envData = handleEnvInstallStatus(envData, env, ExtensionsUpdateStatus.FAILURE);
                    await handleUnattach(envData as IEnvironmentElement[], env);
                }
            }
            dispatch(setSelectedEnvironments(envData));
            const data = await makeApiCall(ENVIRONMENT_IPACKS(spaceGuid));
            updateInstalledData(data);
            if (isEditInstall) {
                !isInstallationSuccessful(envData as IEnvironmentElement[]) && (await handleResponseErrorsForEditInstall());
            } else {
                await handleInstallError(envData);
            }
        }
    };

    const handleUnattach = async (envData: IEnvironmentElement[], env: string) => {
        for (const selectedEnv of envData) {
            selectedEnv.id === env && !isEdit && !isEditInstall && (await handleUnattachForPartialInstall(selectedEnv.environmentId ?? ''));
        }
    };

    const isInstallationSuccessful = (selectedEnvs: IEnvironmentElement[] | undefined) =>
        selectedEnvs?.map((element) => element.installStatus).some((installStatus) => installStatus === ExtensionsUpdateStatus.SUCCESS);

    return { extensionsDataMapsInstallData, isInstallationSuccessful };
};
export default useExtensionsDataMapsInstall;
