import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ButtonEvent } from 'interfaces/IGeneral';
import { setDropdownOption } from 'store/actions/generalActions';

const useDropdown = (handleModalChange: ((event: ButtonEvent) => void) | undefined) => {
    const defaultSelectOption = 'dropdown.select';
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>(defaultSelectOption);
    const [selectError, setSelectError] = useState<boolean>(false);
    const dispatch = useDispatch();

    const optionClickHandler = (payload: string) => {
        dispatch(setDropdownOption(payload));
        setSelectedValue(payload);
        setSelectError(false);
        setOpenDropdown(false);
    };

    const nextClickHandler = (event: ButtonEvent) => {
        if (selectedValue === defaultSelectOption) {
            setSelectError(true);

            return;
        }

        if (handleModalChange) {
            handleModalChange(event);
        }
    };

    return {
        defaultSelectOption,
        openDropdown,
        setOpenDropdown,
        selectedValue,
        selectError,
        setSelectError,
        optionClickHandler,
        nextClickHandler,
    };
};

export default useDropdown;
