import React from 'react';

import DialogBox from 'components/common/DialogBox/DialogBox';
import Spinner from 'components/common/Spinner/Spinner';
import ConfigureConnectionForm from './ConfirmationPage/ConfigureConnectionForm';
import GenericContainer from 'containers/GenericContainer';
import useConfigureConnection from 'hooks/useConfigureConnection';
import { IConfigureField, IConnection } from 'interfaces/IConfigureConnection';
import { truncateString, truncateTooltipText } from 'utils/truncateString';
import { extractConnectionParameter } from 'utils/extractConnectionParameter';

import Dropdown from 'components/common/DropdownField/Dropdown';
import { AccordionVersion, ButtonFlavor, ButtonSize, ButtonType, ExAccordion, ExAccordionItem, ExButton, ExTooltip, TooltipAlignment, TooltipPosition } from '@boomi/exosphere';
import { TOOLTIP_MAX_LENGTH_ALLOWED } from 'constants/general';

export const ConfigureConnection = () => {
    const {
        connection,
        selectedIPack,
        onSubmit,
        envName,
        modalStatus,
        isLoading,
        register,
        handleSubmit,
        errors,
        setValue,
        handleUninstall,
        t,
        processes,
        setSelectedProcess,
        process,
        getValues,
        reset,
        getConnectionFormData,
        getCancelBtnLabel,
        getExtensionPageTitle,
        stepChangeWithOneExtension,
        setDefaultProcess,
        watch,
        handleAccordionToggle,
        openScheduleAccordions,
        setOpenScheduleAccordions
    } = useConfigureConnection();

    return (
        <>
            <GenericContainer
                name={selectedIPack?.name}
                title={getExtensionPageTitle()}
                titleVariable={selectedIPack?.installationType.toLowerCase() === 'single' ? 'Single' : 'Multi'}
                titleVariable2={` : Connections- ${envName || ''}`}
            >
                {!isLoading && !stepChangeWithOneExtension ? (
                    <div className="ex-container">
                        <div className="ex-row">
                            <div className="ex-col-xs-7">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {Boolean(processes.length) && (
                                        <>
                                            <div className="generic-container-subtitle">{t('processDropdown.title')}</div>
                                            <Dropdown
                                                options={processes}
                                                title=""
                                                contained={true}
                                                handleChange={(e) => {
                                                    getConnectionFormData({ ...getValues() }, process);
                                                    setSelectedProcess(e);
                                                    reset();
                                                    setOpenScheduleAccordions([0]);
                                                }}
                                                selectedItem={process}
                                                className="processDropdown"
                                                placeholder={t('processDropdown.connectionPlaceholder')}
                                                callback={setDefaultProcess}
                                                showTooltip={true}
                                            />
                                        </>
                                    )}
                                    {Boolean(process.length) && <div className="generic-container-subtitle">{t('installPage.connections')}</div>}
                                    {!!connection && !!process.length && (
                                        <ExAccordion version={AccordionVersion.BORDER}>
                                            {connection.map((connectionItem: IConnection, formIndex: number) => {
                                                function getAccordionItemDiv() {
                                                    const connectionId = connectionItem.id;

                                                    return (
                                                        <div>
                                                            {connectionItem.field.map((field: IConfigureField, index: number) => {
                                                                const { value, id, type, usesEncryption } = field;
                                                                const labelString = extractConnectionParameter(id);
                                                                const constructedField = {
                                                                    inputName: `${formIndex}.${id}`,
                                                                    label: labelString,
                                                                    fieldType: type,
                                                                    hiddenValue: usesEncryption,
                                                                    fieldValue: { ...getValues() },
                                                                };
                                                                return (
                                                                    <ConfigureConnectionForm
                                                                        key={constructedField.inputName}
                                                                        field={constructedField}
                                                                        value={value}
                                                                        connectionId={connectionId}
                                                                        processId={process}
                                                                        {...{
                                                                            defaultValue: value || '',
                                                                            register,
                                                                            errors,
                                                                            setValue,
                                                                            index,
                                                                            watch,
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                }

                                                return connectionItem.name.length > TOOLTIP_MAX_LENGTH_ALLOWED ? (
                                                    <ExTooltip
                                                        key={connectionItem.id}
                                                        position={TooltipPosition.TOP}
                                                        alignment={TooltipAlignment.START}
                                                        className="componentExTooltip"
                                                        data-testid="connections-tooltip"
                                                    >
                                                        <div className="componentExTooltipDiv"> {truncateTooltipText(connectionItem.name)}</div>
                                                        <ExAccordionItem
                                                            label={`${truncateString(connectionItem.name)}`}
                                                            slot="anchor"
                                                            open={openScheduleAccordions.includes(formIndex)}
                                                            onClick={() => handleAccordionToggle(formIndex)}
                                                        >
                                                            {getAccordionItemDiv()}
                                                        </ExAccordionItem>
                                                    </ExTooltip>
                                                ) : (
                                                    <ExAccordionItem
                                                        key={connectionItem.id}
                                                        label={`${truncateString(connectionItem.name)}`}
                                                        open={openScheduleAccordions.includes(formIndex)}
                                                        onClick={() => handleAccordionToggle(formIndex)}
                                                    >
                                                        {getAccordionItemDiv()}
                                                    </ExAccordionItem>
                                                );
                                            })}
                                        </ExAccordion>
                                    )}
                                    <div className="generic-container-footer">
                                        <div className="generic-container-footer__actions">
                                            <ExButton
                                                role="submit"
                                                slot="footer"
                                                type={ButtonType.PRIMARY}
                                                flavor={ButtonFlavor.BASE}
                                                size={ButtonSize.DEFAULT}
                                                onClick={() => handleSubmit(onSubmit)()}
                                                data-testid="btn-continue"
                                            >
                                                {t('btn.continue')}
                                            </ExButton>
                                            <ExButton slot="footer" type={ButtonType.SECONDARY} flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} onClick={handleUninstall} data-testid="btn-cancel">
                                                {t(getCancelBtnLabel())}
                                            </ExButton>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Spinner />
                )}
            </GenericContainer>
            {modalStatus && <DialogBox />}
        </>
    );
};

export default ConfigureConnection;
