import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';

import styles from 'components/common/BlankState/BlankState.module.scss';

export const UnavailableItems = () => {
    const { t } = useTranslation();

    const { blankState_small, blankState_description, blankState_icon } = styles;

    return (
        <div className={blankState_small} data-testid="blankState">
            <div className={blankState_icon}>
                <FiSearch />
            </div>
            <div className={blankState_description}>
                <p>{t('alert.noAvailableFields')}</p>
            </div>
        </div>
    );
};

export default UnavailableItems;
