import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useBasePageData from 'hooks/useBasePageData';
import useHandleApiCall from 'hooks/useHandleApiCall';
import useExtensionsData from 'hooks/useExtensionsData';
import useHandleResponseErrors from 'hooks/useHandleResponseErrors';
import { setInstanceProcesses, setIsEditInstall, setIsIpackEdit, setModalStatus, setModalType, setSelectedEnvironments } from 'store/actions/generalActions';
import { ENVIRONMENTS } from 'constants/api';
import { getDynamicProcessesListQuery, getInstallEnv, getInstallID, getUnattach } from 'services/getDynamicQuery';
import { IStore } from 'interfaces/IStore';
import { IEnvironmentData } from 'interfaces/IEnvironment';
import { IInstalledEnv } from 'interfaces/IInstalled';
import { IKeyStringValueString } from 'interfaces/IGeneral';
import { useEffect, useState } from 'react';
import { useQueryParam } from './useQueryParam';

const useGenericInstall = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedIPack = useSelector((state: IStore) => state.general.selectedIPack);
    const isLoading = useSelector((state: IStore) => state.general.isLoading);
    const { sameEnvConfiguration } = useSelector((state: IStore) => state.general.env);
    const { networkError, selectedEnvironments, isEditInstall, isEdit } = useSelector((state: IStore) => state.general);
    const { data: environments } = useBasePageData<IEnvironmentData>(ENVIRONMENTS);
    const { makeApiCall } = useHandleApiCall();
    const { getExtensionsData } = useExtensionsData();
    const { handleResponseErrors, handleResponseErrorsForEditInstall } = useHandleResponseErrors();
    const [instanceId, setInstanceId] = useState<string>('');
    const { id: paramId } = useQueryParam();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
    } = useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInstall = async (query: string, environmentsArr: { id: string; name: string }[], data: any) => {
        dispatch(setModalType('loading'));
        dispatch(setModalStatus(true));
        // Install IPack
        const response = await makeApiCall(query);

        const { id, integrationPackId } = response.data.integrationPackInstanceInstall;
        setInstanceId(id);

        const environmentsArrIds = environmentsArr.map((env: { id: string; name: string }) => env?.id);
        // Attach IPack to environment
        const { envQuery } = getInstallEnv(id, environmentsArrIds);
        const envResponse = await makeApiCall(envQuery);

        // Save env in redux and add generated environment id to the env array
        const envArr: IKeyStringValueString = {};
        envResponse.data.integrationPackInstanceEnvironmentAttachmentAttach.forEach((env: IInstalledEnv) => {
            envArr[env.environmentId] = env.id as string;
        });

        const selectedEnv = data.environments.map((environment: IInstalledEnv) => ({
            name: environment.name,
            id: environment.id,
            environmentId: envArr[environment.id as string],
            classification: environment.classification ? environment.classification : 'NO classification set in BE',
        }));

        dispatch(setSelectedEnvironments(selectedEnv));

        const envId = envResponse.data.integrationPackInstanceEnvironmentAttachmentAttach.map((el: IInstalledEnv) => el.environmentId);

        const conditionalRouteStep = await getExtensionsData(id, envId);

        if (selectedIPack?.installationType === 'SINGLE') {
            navigate(`${location.pathname}/confirmation?id=${id}&integrationPackId=${integrationPackId}&envIds=${envId.toString()}`);
            return;
        }

        if (!conditionalRouteStep) {
            return;
        }

        const { query: processQuery } = getDynamicProcessesListQuery(id);
        const processListResponse = await makeApiCall(processQuery);
        if (processListResponse?.data?.integrationPackInstanceProcesses?.length) {
            dispatch(setInstanceProcesses(processListResponse.data.integrationPackInstanceProcesses.map((item: any) => ({ label: item.name, value: item.id }))));
        }

        navigate(`${location.pathname}${conditionalRouteStep}?id=${id}&integrationPackId=${integrationPackId}&envIds=${envId.toString()}&sameConfig=${sameEnvConfiguration}`);

        dispatch(setModalStatus(false));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const useOnSubmit = (data: any) => {
        if (!data.environments.length) {
            setError('environments', { type: 'focus' }, { shouldFocus: true });
            return;
        }

        const { query } = getInstallID(data.integrationName || '', selectedIPack?.id || '');

        handleInstall(query, data.environments, data);
    };

    const sortedEnv = environments?.data.environments.sort((a, b) => (a.name > b.name ? 1 : -1));

    useEffect(() => {
        if (isEditInstall) {
            networkError && handleResponseErrorsForEditInstall();
        } else {
            networkError && handleResponseErrors(instanceId);
        }
    }, [networkError]);

    const getCancelBtnLabel = () => (isEdit || isEditInstall ? 'btn.cancel' : 'btn.cancelAndUninstall');

    const getExtensionPageTitle = () => (isEdit || isEditInstall ? 'editIpack.title' : 'installPage.installHeader');

    const handleUninstall = async () => {
        if (isEdit) {
            dispatch(setIsIpackEdit(false));
            navigate(`/edit/multi?id=${paramId}`);
            return;
        } else if (isEditInstall) {
            dispatch(setModalStatus(true));
            const envId = selectedEnvironments?.map((element) => element.environmentId)?.toString();
            dispatch(setIsEditInstall(false));
            const { unAttachQuery } = getUnattach(envId || '');
            await makeApiCall(unAttachQuery);
            navigate(`/edit/multi?id=${selectedIPack.integrationId}`);
            dispatch(setModalStatus(false));
            return;
        }
        dispatch(setModalStatus(true));
        dispatch(setModalType(''));
    };

    useEffect(() => {
        dispatch(setModalStatus(false));
    }, []);

    return {
        onSubmit: useOnSubmit,
        handleSubmit,
        handleResponseErrors,
        selectedIPack,
        register,
        errors,
        setValue,
        clearErrors,
        t,
        environments: sortedEnv,
        getCancelBtnLabel,
        handleUninstall,
        getExtensionPageTitle,
        isLoading,
    };
};

export default useGenericInstall;
