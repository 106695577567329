import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useLogout from 'hooks/useLogout';
import { IModal } from 'interfaces/IModal';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';
import styles from 'components/common/DialogBox/DialogBox.module.scss';

const LogoutModal: FC<IModal> = ({ closeModal }) => {
    const { t } = useTranslation();
    const { handleLogout } = useLogout();
    const { dialog_body } = styles;

    return (
        <>
            <div className={dialog_body}>{t('dialogBox.logout.message')}</div>

            <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large ">
                <ExButton
                    className="ex-mr-small"
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={() => {
                        closeModal();
                    }}
                    data-testid="logout-cancel"
                >
                    {t('btn.cancel')}
                </ExButton>

                <ExButton
                    flavor={ButtonFlavor.RISKY}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.LARGE}
                    data-testid="logout"
                    onClick={async () => {
                        await handleLogout();
                    }}
                >
                    {t('btn.logout')}
                </ExButton>
            </div>
        </>
    );
};

export default LogoutModal;
