export const PLATFORM_REDIRECT = (encodedRedirectString: string) => `${window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL}/login.html?signinRedirect=${encodedRedirectString}`;

export enum ImageTypes {
    JPEG = '.jpeg',
    PNG = '.png',
    SVG = '.svg',
    JPG = '.jpg',
}

export const TOOLTIP_MAX_LENGTH_ALLOWED = 65;
export const TOOLTIP_LENGTH_ALLOWED = 256;

