import useHandleApiCall from './useHandleApiCall';
import { useEffect, useState } from 'react';
import { IUseBasePageData } from 'interfaces/IGeneral';

const useBasePageData = <TDataType>(reqQuery: string): IUseBasePageData<TDataType | null> => {
    const [data, setData] = useState<TDataType | null>(null);
    const { makeApiCall } = useHandleApiCall();
    const getPageData = async () => {
        const response = await makeApiCall(reqQuery);

        if (response) {
            setData(response);
        }
    };

    useEffect(() => {
        getPageData();
    }, []);

    return { data };
};

export default useBasePageData;
