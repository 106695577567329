import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BlankState from 'components/common/BlankState/BlankState';
import EndpointError from 'components/common/EndpointError/EndpointError';
import { ICardContainer, ICardItem } from 'interfaces/ICardContainer';
import { IStore } from 'interfaces/IStore';
import { ENDPOINT_ERROR_MESSAGE } from 'constants/endpointErrorMessages';
import usePagination from 'hooks/usePagination';

import './CardContainer.scss';
import ArtifactCard from '../components/ArtifactCard/ArtifactCard';
import { ExPagination } from '@boomi/exosphere';

export const CardContainer: FC<ICardContainer> = ({ data, artifactState }) => {
    const { networkError, search } = useSelector((state: IStore) => state.general);
    const { pathname } = useLocation();
    const pagePath = pathname?.split('/').pop() || '';
    const { paginatedData, pageSize, selectedPage, handlePaginationChange, pageSizeOptions } = usePagination(data, artifactState);
    const blankState = search?.isSearching ? 'noSearchResults' : 'noIntegrationFound';

    if (networkError) {
        return <EndpointError message={ENDPOINT_ERROR_MESSAGE[pagePath]} type="danger" />;
    }

    return (
        <>
            <div>
                {data.length > 0 ? (
                    <div>
                        <div className="ex-row">
                            {paginatedData.map((paginatedItem: ICardItem) => (
                                <ArtifactCard key={paginatedItem.integrationId || paginatedItem.id} item={paginatedItem} artifactState={artifactState} />
                            ))}
                        </div>
                        <div className="ex-mt-2x-large ex-mb-5x-large">
                            <ExPagination
                                pageSize={pageSize}
                                pageSizeOptions={pageSizeOptions}
                                selectedPage={selectedPage}
                                totalItems={data.length}
                                onChange={(e: CustomEvent<any>) => handlePaginationChange(e)}
                                className="ex-mt-medium ex-mb-5x-large"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="empty-state">
                        <BlankState type={blankState} />
                    </div>
                )}
            </div>
        </>
    );
};

export default CardContainer;
