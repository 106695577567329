import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UninstallModal from 'components/Modal/UninstallModal';
import useUninstall from 'hooks/useUninstall';
import { ButtonEvent } from 'interfaces/IGeneral';
import { IStore } from 'interfaces/IStore';
import { setModalStatus, setModalType, setSelectedIPack } from 'store/actions/generalActions';
import { MODAL_TYPES } from 'constants/modal';

export const useModalType = (type: string, selectedIPack: string, uninstall?: () => void, callback?: () => void) => {
    const dispatch = useDispatch();
    const { option } = useSelector((state: IStore) => state.general.dropdown);
    const { handleUninstall } = useUninstall();
    const { modalType } = useSelector((state: IStore) => state.general);

    const closeModal = () => {
        if (modalType === 'tooltip') {
            dispatch(
                setSelectedIPack({
                    id: '',
                    installationType: '',
                }),
            );
        }
        dispatch(setModalStatus(false));
        dispatch(setModalType(''));
    };

    const handleModalChange = (event: ButtonEvent) => {
        const optionType = option.replace('dropdown.', '');

        dispatch(setModalType(optionType));
        event.currentTarget.blur();
        callback?.();
    };

    const handleOutsideClick = () => {
        if (modalType === 'tooltip') {
            dispatch(
                setSelectedIPack({
                    id: '',
                    installationType: '',
                }),
            );
            dispatch(setModalStatus(false));
        }
    };

    const Component = MODAL_TYPES[type];

    return {
        modal: Component ? (
            <Component {...{ handleModalChange, closeModal, selectedIPack }} />
        ) : (
            <UninstallModal {...{ closeModal, selectedIPack, handleIPackUninstall: uninstall || handleUninstall }} />
        ),
        handleOutsideClick,
    };
};

export default useModalType;
