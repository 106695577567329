import { IProcessSchedule, ISingularSchedule } from 'interfaces/IProcesses';

export const updateScheduleBlockToSend = (objToModify: ISingularSchedule, scheduleBlocksToSend: IProcessSchedule['schedule'], setState: React.Dispatch<React.SetStateAction<IProcessSchedule['schedule']>>, alreadyScheduledBlocks: IProcessSchedule['schedule'], index: number) => {
    const updatedState = [...scheduleBlocksToSend];
    if (scheduleBlocksToSend[index - alreadyScheduledBlocks.length] === undefined) {
        const scheduleData = scheduleBlocksToSend.filter((item) => item.index === index);
        const indexOfScheduleData = scheduleBlocksToSend.indexOf(scheduleData[0]);
        updatedState.splice(indexOfScheduleData, 1, objToModify);
    } else {
        updatedState.splice(index - alreadyScheduledBlocks.length, 1, objToModify);
    }

    setState(updatedState);
};
