import { AnyAction } from 'redux';

import { IGeneralReducer } from 'interfaces/IGeneralReducer';
import {
    SET_IS_SEARCHING,
    SET_SEARCH_RESULTS,
    SET_IS_LOADING,
    SET_SELECTED_IPACK,
    SET_SELECTED_ENVIRONMENTS,
    SET_MODAL_STATUS,
    SET_DROPDOWN_OPTION,
    SET_SEARCH_QUERY,
    SET_SAME_ENV_CONFIGURATION,
    SET_DIFFERENT_ENV_STEP,
    SET_NETWORK_ERROR,
    SET_MODAL_TYPE,
    SET_SELECTED_SOURCE_FIELD,
    SET_FILTERED_TABLE,
    SET_DATA_MAPS,
    SET_SELECTED_PROCESS,
    SET_EXTENSIONS_STATUS,
    SET_RADIO_GROUP,
    SET_EXTENSIONS,
    UPDATE_EXTENSIONS,
    SET_CURRENT_PAGE,
    SET_PROCESS_RUN_STATUS,
    SET_PROCESS_SCHEDULES,
    SET_INSTALLED_DATA,
    SET_IS_RUNS_EVERY_ERROR,
    SET_IS_DAYS_BLOCK_ERROR,
    SET_ACCOUNT_LIST,
    RESET_GENERAL_STATE,
    SET_EXTENSION_PROCESSES,
    SET_SELECTED_EXTENSION_PROCESS,
    SET_INSTANCE_PROCESSES,
    SET_SELECTED_DATA_MAP,
    SET_IS_EDIT_INSTALL,
    SET_IS_IPACK_EDIT,
    SET_IS_EDIT_UNINSTAL_IPACK_INSTANCE,
    SET_IS_EDIT_UNINSTALL_FROM_LAST_ENV,
    SET_BROWSE_SWITCH_STATUS,
    SET_RUN_NOW_CONFIRMATION_MODAL_STATUS,
    SET_FAILED_MODAL_TYPE,
    SET_AUTHORIZATION_PARAMETER_VALUE,
    SET_ACCESS_TOKEN_PARAMETER_VALUE,
    SET_TOKEN_GENERATED_STATUS,
    SET_OAUTH_TOKEN_DETAILS,
} from 'store/actions/actionTypes';
import { ArtifactState } from 'constants/artifact';
import { setExtensionData, updateState } from 'utils/ReducerUtils';

const initialState = {
    search: { isSearching: false, searchResults: [], searchQuery: '', currentPage: 0 },
    dropdown: { option: '' },
    isLoading: true,
    isEditUninstallIpackInstance: false,
    isEditUninstallFromLastEnv: false,
    isEditInstall: false,
    modalStatus: false,
    runNowConfirmationModalStatus: false,
    modalType: 'delete',
    selectedIPack: { id: '', installationType: '' },
    env: { sameEnvConfiguration: true, step: 0 },
    networkError: false,
    selectedSourceField: { name: '', xpath: '' },
    dataMaps: [],
    extensionsStatus: {
        isDataMaps: false,
        isConnection: false,
        isProperties: false,
    },
    extensions: [],
    radioGroup: false,
    processes: {
        selectedProcess: {
            id: '',
            name: '',
            envID: '',
            scheduled: false,
            integrationPack: [],
            envClassification: '',
            envName: '',
            processSchedules: { schedule: [], atomId: '', envId: '', id: '', processId: '', retry: { maxRetry: 0, schedule: [] } },
        },
        runStatus: { isSuccess: true, errorMessage: '' },
        isScheduleError: {
            isRunsEveryError: false,
            isDaysBlockError: false,
        },
    },
    installedData: { space: { sharedIpacks: [] }, environments: [], integrationPackInstances: [] },
    accountList: [],
    extensionsProcessess: {
        selectedProcess: '',
        processes: [],
        instanceProcesses: [],
    },
    selectedDataMap: '',
    isEdit: false,
    artifactState: ArtifactState.NOT_INSTALLED,
    generateStatus: true,
    accessTokenFailure: {
        data: '',
    },
    authorizationParameterValue: [],
    accessTokenParameterValue: [],
    oauthTokenGeneratedStatus: false,
    oauthTokenDetails: [],
};

export default (state: IGeneralReducer = initialState, action: AnyAction = { type: '', payload: null }) => {
    switch (action.type) {
        case SET_IS_SEARCHING:
            return {
                ...state,
                search: {
                    ...state.search,
                    isSearching: action.payload,
                },
            };
        case SET_SEARCH_RESULTS:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchResults: [...action.payload],
                },
            };
        case SET_SEARCH_QUERY:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchQuery: action.payload,
                },
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                search: {
                    ...state.search,
                    currentPage: action.payload,
                },
            };
        case SET_DROPDOWN_OPTION:
            return {
                ...state,
                dropdown: {
                    option: action.payload,
                },
            };
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_IS_EDIT_UNINSTAL_IPACK_INSTANCE:
            return {
                ...state,
                isEditUninstallIpackInstance: action.payload,
            };
        case SET_IS_EDIT_UNINSTALL_FROM_LAST_ENV:
            return {
                ...state,
                isEditUninstallFromLastEnv: action.payload,
            };
        case SET_IS_EDIT_INSTALL:
            return {
                ...state,
                isEditInstall: action.payload,
            };
        case SET_SELECTED_IPACK:
            return {
                ...state,
                selectedIPack: action.payload,
            };
        case SET_SELECTED_ENVIRONMENTS:
            return {
                ...state,
                selectedEnvironments: action.payload,
            };
        case SET_SAME_ENV_CONFIGURATION:
            return {
                ...state,
                env: {
                    ...state.env,
                    sameEnvConfiguration: action.payload,
                },
            };
        case SET_DIFFERENT_ENV_STEP:
            return {
                ...state,
                env: {
                    ...state.env,
                    step: action.payload,
                },
            };
        case SET_FILTERED_TABLE:
            return {
                ...state,
                dataMaps: {
                    ...state.dataMaps,
                    filteredTable: action.payload,
                },
            };
        case SET_SELECTED_PROCESS: {
            return {
                ...state,
                processes: {
                    ...state.processes,
                    selectedProcess: action.payload,
                },
            };
        }
        case SET_PROCESS_RUN_STATUS: {
            return {
                ...state,
                processes: {
                    ...state.processes,
                    runStatus: action.payload,
                },
            };
        }
        case SET_PROCESS_SCHEDULES: {
            return {
                ...state,
                processes: {
                    ...state.processes,
                    selectedProcess: {
                        ...state.processes.selectedProcess,
                        processSchedules: {
                            ...state.processes.selectedProcess.processSchedules,
                            schedule: action.payload,
                        },
                    },
                },
            };
        }
        case SET_EXTENSIONS_STATUS:
            return {
                ...state,
                extensionsStatus: action.payload,
            };
        case SET_RADIO_GROUP: {
            return {
                ...state,
                radioGroup: action.payload,
            };
        }
        case SET_EXTENSIONS: {
            return {
                ...state,
                extensions: setExtensionData(state, action),
            };
        }
        case UPDATE_EXTENSIONS: {
            return {
                ...state,
                extensions: action.payload,
            };
        }
        case SET_IS_RUNS_EVERY_ERROR: {
            return {
                ...state,
                processes: {
                    ...state.processes,
                    isScheduleError: {
                        ...state.processes.isScheduleError,
                        isRunsEveryError: action.payload,
                    },
                },
            };
        }
        case SET_IS_DAYS_BLOCK_ERROR: {
            return {
                ...state,
                processes: {
                    ...state.processes,
                    isScheduleError: {
                        ...state.processes.isScheduleError,
                        isDaysBlockError: action.payload,
                    },
                },
            };
        }
        case SET_INSTALLED_DATA: {
            return {
                ...state,
                installedData: action.payload,
            };
        }
        case SET_ACCOUNT_LIST: {
            return {
                ...state,
                accountList: action.payload,
            };
        }
        case SET_EXTENSION_PROCESSES: {
            return {
                ...state,
                extensionsProcessess: {
                    ...state.extensionsProcessess,
                    processes: action.payload,
                },
            };
        }
        case SET_SELECTED_EXTENSION_PROCESS: {
            return {
                ...state,
                extensionsProcessess: {
                    ...state.extensionsProcessess,
                    selectedProcess: action.payload,
                },
            };
        }
        case SET_INSTANCE_PROCESSES: {
            return {
                ...state,
                extensionsProcessess: {
                    ...state.extensionsProcessess,
                    instanceProcesses: action.payload,
                },
            };
        }
        case SET_FAILED_MODAL_TYPE:
        case SET_AUTHORIZATION_PARAMETER_VALUE:
        case SET_ACCESS_TOKEN_PARAMETER_VALUE:
        case SET_NETWORK_ERROR:
        case SET_MODAL_TYPE:
        case SET_SELECTED_SOURCE_FIELD:
        case SET_DATA_MAPS:
        case SET_SELECTED_DATA_MAP:
        case SET_IS_IPACK_EDIT:
        case SET_MODAL_STATUS:
        case SET_RUN_NOW_CONFIRMATION_MODAL_STATUS:
        case SET_BROWSE_SWITCH_STATUS:
        case SET_OAUTH_TOKEN_DETAILS:
        case SET_TOKEN_GENERATED_STATUS:
            return updateState(state, action);
        case RESET_GENERAL_STATE: {
            return initialState;
        }
        default:
            return state;
    }
};
