import { splitStringBy } from '../utils/splitStringBy';

const useCheckedDays = (days: { value: string }[], numOfCheckedDays: number, setCheckboxError: (value: ((prevState: boolean) => boolean) | boolean) => void) => {
    const checkedDaysArr = Array(7);

    const initialState = Array(days.length)
        .fill(undefined)
        .map((_, i: number) => (i > 0 && i <= numOfCheckedDays ? true : false));

    const getCheckedDaysArray = (daysOfWeek: string) => {
        // check to see if sunday is coming from BE as day 7, cron recommends to use 0 for Sunday -> required manipulation done here
        const modifiedDaysOfWeek = daysOfWeek.includes('7') ? '0,' + daysOfWeek?.replace(',7', '') : '';

        const splitDayOfWeek = splitStringBy(modifiedDaysOfWeek.length ? modifiedDaysOfWeek : daysOfWeek, ',');

        checkedDaysArr.fill(undefined).forEach((_, i) => {
            checkedDaysArr[i] = splitDayOfWeek.indexOf(i.toString()) !== -1 ? true : false;
        });

        return checkedDaysArr;
    };
    const setChkboxError = (daysChecked: boolean[]) => {
        if (daysChecked.every((el) => el === false)) {
            setCheckboxError(true);
        }
    };
    const getDaysOfWeek = (selectedDays: boolean[]) => {
        return selectedDays.map((day, i: number) => day && i);
    };

    const getFilteredDaysOfWeek = (daysOfWeek: (false | number)[]) => {
        return daysOfWeek.filter((el) => typeof el === 'number');
    };
    return { getCheckedDaysArray, getDaysOfWeek, getFilteredDaysOfWeek, initialState, setChkboxError };
};
export default useCheckedDays;
