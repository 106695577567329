import React from 'react';

import GenericInstall from 'components/common/GenericInstall';

export const SinglePackInstall = () => {
    return <GenericInstall />
};

export default SinglePackInstall;

