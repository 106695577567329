import React, { FC } from 'react';

import AlertBox from 'components/common/AlertBox/AlertBox';
import { IEndpointError } from 'interfaces/IEndpointError';

import style from 'components/common/EndpointError/EndpointError.module.scss';

const EndpointError: FC<IEndpointError> = ({ message, type }) => {
    const { container } = style;

    return (
        <div className={container}>
            <AlertBox message={message} type={type}/>
        </div>
    )
}

export default EndpointError;