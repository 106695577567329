import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from 'components/common/Layout/Layout';
import Browse from 'pages/Browse/Browse';
import Login from 'pages/Login';
import { ROUTES } from 'constants/routes/routes';
import Logout from 'pages/Logout';

const SwitchRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout/>} />
            <Route path="/" element={<Layout />}>
                <Route index element={<Browse />} />
                {ROUTES.map((route) => (
                    <Route key={route.path} path={route.path} element={<route.element />} />
                ))}
            </Route>
        </Routes>
    );
};

export default SwitchRoutes;
