import { store } from 'store/store';

export const getRedirectString = (spaceGuid: string) => {
    const accountSearchCriteria = store.getState().login.accountSearchCriteria;
    return `${window.location.origin}/login?guid=${spaceGuid}${accountSearchCriteria?.length ? '&account_id=' + accountSearchCriteria : ''}`;
};

export const ACCOUNTS_RANGE = 10;

export const fetchAccountsWithinRange = (accounts: any, searchString: string) => {
    const range = accounts.length <= ACCOUNTS_RANGE ? accounts.length : ACCOUNTS_RANGE;
    return searchString.length ? accounts : accounts.slice(0, range);
};