const useExtensionStatus = () => {
    const getExtensionStatus = (data: any, extensionType: string) => {
        if (extensionType == 'CONNECTION') {
            return (
                data?.result &&
                data.result.some((item: any) => {
                    return item.connections?.connection;
                })
            );
        } else if (extensionType === 'PROCESS_PROPERTY') {
            return (
                data?.result &&
                data.result.some((item: any) => {
                    return item.processProperties?.ProcessProperty;
                })
            );
        } else if (extensionType === 'DATA_MAPS') {
            return (
                data?.response &&
                data.response.some((item: any) => {
                    return item?.Result?.mapId;
                })
            );
        }
        return false;
    };

    return { getExtensionStatus };
};

export default useExtensionStatus;
