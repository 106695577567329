import { IDataMaps } from 'interfaces/IDataMaps';
import { useDispatch } from 'react-redux';

import { setDataMaps } from 'store/actions/generalActions';

const useDataMapsPageDataHandler = () => {
    const dispatch = useDispatch();

    const handlePageData = (dataMaps: any, pageData: IDataMaps | null) => {
        if (pageData && !dataMaps.length) {
            let mapData: any[] = [];
            pageData?.data?.integrationPackEnvironmentAttachments?.forEach((item) => {
                item?.environmentMapExtension?.forEach((environmentMapExtensions: any) => {
                    const extensionsArr = environmentMapExtensions && JSON.parse(environmentMapExtensions)?.response;
                    if (extensionsArr?.length) {
                        mapData = [...mapData, extensionsArr];
                    }
                });
            });

            dispatch(setDataMaps(mapData));
        }
    };

    return { handlePageData };
};
export default useDataMapsPageDataHandler;
