import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IGenericContainer } from 'interfaces/IGeneral';
import './GenericContainer.scss';

const GenericContainer: FC<IGenericContainer> = ({ children, title, subtitle, titleVariable, titleVariable2 = '', name }) => {
    const { t } = useTranslation();

    return (
        <div className="generic-container ex-pt-x-large ex-pl-2x-large ex-pr-2x-large ex-pr-2x-large ex-pb-2x-large">
            <div className="generic-container-header ex-mb-x-large">
                {name && <div className="generic-container-header__name">{name}</div>}
                <h1 className="generic-container-header__title">{t(title, { titleVariable, titleVariable2 })}</h1>
                {subtitle && <div className="generic-container-header__subtitle">{t(subtitle)}</div>}
            </div>
            <div className="generic-container-body">{children}</div>
        </div>
    );
};

export default GenericContainer;
