const useConfirmationPageRenderer = () => {
    const showScheduleRunAlert = (pageValue: string, modalStatus: boolean, isIntegrationPackAttachedToEnv: boolean) => pageValue === 'scheduleRun' && !modalStatus && !isIntegrationPackAttachedToEnv;

    const showProcessTable = (isError: boolean | undefined, processData: any) => !isError && processData.length;

    const getPage = (isScheduleAndRun: boolean, isEdit: boolean, isPartialSuccess: boolean | undefined) => {
        let page = '';
        if (isScheduleAndRun) {
            page = 'scheduleRun';
        } else if (isEdit) {
            page = 'edit';
        } else if (isPartialSuccess) {
            page = 'partialInstall';
        }
        return page;
    };

    const isHiddenContent = (modalStatus: boolean, modalType: string) => Boolean(modalStatus) && modalType === 'loading';

    return { showScheduleRunAlert, showProcessTable, getPage, isHiddenContent };
};

export default useConfirmationPageRenderer;
