import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISearchBarSecondary } from 'interfaces/ISearchBarSecondary';
import { ExIcon, ExInput, IconVariant } from '@boomi/exosphere';
import styles from 'components/common/SearchBar/SearchBar.module.scss';

export const SearchBarSecondary: FC<ISearchBarSecondary> = ({ handleChange, searchQuery }) => {
    const { t } = useTranslation();
    const { searchBarWrapper, searchBarWrapper_title } = styles;

    return (
        <div className={searchBarWrapper}>
            <ExInput
                placeholder={t('search.title')}
                type="text"
                clearable={true}
                className={searchBarWrapper_title}
                value={searchQuery}
                data-testid="search-input"
                onInput={(e: Event) => handleChange((e.target as HTMLInputElement).value)}
                label={t('search.title')}
            >
                <ExIcon hideBrowserTooltip={true} icon="magnifying-glass" label="search" variant={IconVariant.ICON} slot="prefix"></ExIcon>
            </ExInput>
        </div>
    );
};

export default SearchBarSecondary;
