import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { IStore } from 'interfaces/IStore';
import { setDifferentEnvStep, setRadioGroup, setSameEnvConfiguration } from 'store/actions/generalActions';
import { useEffect } from 'react';
import { RadioType } from 'constants/radio';

export const useRadioGroup = (checkedNo: boolean) => {
    const { sameEnvConfiguration } = useSelector((state: IStore) => state.general.env);
    const { radioGroup } = useSelector((state: IStore) => state.general);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const dataMapsPath = pathname.includes('data-maps');

    const handleYesRadio = () => {
        dispatch(setRadioGroup(true));

        if (!dataMapsPath) {
            dispatch(setSameEnvConfiguration(true));
        }
    };

    const handleNoRadio = () => {
        dispatch(setRadioGroup(false));

        if (!dataMapsPath) {
            dispatch(setDifferentEnvStep(0));
        }

        if (sameEnvConfiguration && !dataMapsPath) {
            dispatch(setSameEnvConfiguration(false));
        }
    };

    useEffect(() => {
        if (!dataMapsPath) {
            !sameEnvConfiguration && dispatch(setSameEnvConfiguration(true));
            dispatch(setDifferentEnvStep(0));
        }
        dispatch(setRadioGroup(!checkedNo));

        return () => {
            dispatch(setRadioGroup(false));
        };
    }, []);

    const handleRadioChange = (e: CustomEvent) => (e?.detail?.value === RadioType.YES ? handleYesRadio() : handleNoRadio());

    return { radioGroup, handleYesRadio, handleNoRadio, t, handleRadioChange };
};
