import { IConfigureConnection } from 'interfaces/IConfigureConnection';

export const parseConnectionResponse = (extensionsPack: any) => {
    let connectionExtensions =
        extensionsPack?.length &&
        extensionsPack.find((item: any) => {
            const parsedData = JSON.parse(item);
            if (parsedData?.result?.find((extData: any) => extData?.connections?.connection)) {
                return item;
            }
        });
    connectionExtensions = connectionExtensions ? JSON.parse(connectionExtensions) : {};
    return connectionExtensions;
};

export const parsePropertiesResponse = (extensionsPack: any) => {
    let propertyExtensions =
        extensionsPack?.length &&
        extensionsPack.find((item: any) => {
            const parsedData = JSON.parse(item);
            if (parsedData?.result?.find((extData: any) => extData?.processProperties?.ProcessProperty)) {
                return item;
            }
        });
    propertyExtensions = propertyExtensions ? JSON.parse(propertyExtensions) : {};
    return propertyExtensions;
};

export const parseDataMapsResponse = (mapExtension: any) => {
    let dataMapsExtension =
        mapExtension?.length &&
        mapExtension.find((item: any) => {
            const parsedData = item?.length && JSON.parse(item);
            if (parsedData?.response?.find((results: any) => results?.Result)) {
                return item;
            }
        });
    dataMapsExtension = dataMapsExtension ? JSON.parse(dataMapsExtension) : {};
    return dataMapsExtension;
};

export const getConnectionStep = (sameEnvConfiguration: boolean, step: number) => (sameEnvConfiguration ? '/configure' : `/configureDifferent/${step}`);

export const getPropertyStep = (sameEnvConfiguration: boolean, step: number) => (sameEnvConfiguration ? '/properties' : `/${step}/properties`);

export const getDataMapsStep = (sameEnvConfiguration: boolean, step: number) => (sameEnvConfiguration ? '/data-maps' : `/${step}/data-maps`);

export const getNextRoute = (isConnection: boolean, configureStep: string, secondStep: string) => (isConnection ? configureStep : secondStep);

export const isExtensionExists = (environmentExtensions: any, pageData: IConfigureConnection | null, envStep: number) =>
    !environmentExtensions || !pageData || pageData.data.integrationPackEnvironmentAttachments[envStep].environmentExtensions[0] === '';
