import { IStore } from 'interfaces/IStore';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { setIsLoading, setNetworkError } from 'store/actions/generalActions';
import { generateJWT } from 'utils/JWThelper';
import API from '../utils/APIInstance';
import { useGuidParam } from './useQueryParam';
import { setAccountSearchCriteria, setSpaceGuid } from '../store/actions/loginActions';
import { store } from '../store/store';
import createDeferredPromise from 'utils/CreateDeferredPromise';

const useHandleApiCall = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { spaceGuid, spaceAccountId } = useSelector((state: IStore) => state.login);
    const location = useLocation();
    const { guid: paramsGuid, account_id: accountSearchCriteria } = useGuidParam();

    const handleAPIPromise = async (reqData: string, isOAuthConnectorDetailsCall = false) => {
        const deferredPromise = createDeferredPromise();

        let guid = '';
        if (location.pathname === '/' || location.pathname === '/login') {
            guid = paramsGuid;
            if (guid?.length) {
                dispatch(setSpaceGuid(guid));
            } else {
                guid = spaceGuid;
            }
        }

        setAccSearchCriteriaFromParams(spaceAccountId);

        try {
            if(!isOAuthConnectorDetailsCall) {
                dispatch(setIsLoading(true));
            }
            dispatch(setNetworkError(false));
            const existingJWT = store.getState().login.jwt;
            if (!spaceAccountId && location.pathname !== '/login') {
                navigate('/login');
                return;
            }
            if (!existingJWT) {
                await generateJWT(window.__RUNTIME_CONFIG__.REACT_APP_PLATFORM_URL, 'SPACE_ACCOUNT_ID', guid);
            }

            const response: any = await API({
                method: 'post',
                data: JSON.stringify({ query: reqData }),
                headers: {
                    SPACE_GUID: guid,
                },
            });

            if (response.data.errors || response.errors) {
                throw new Error('Error occured while fetching the data', { cause: response.data.errors || response.errors });
            }
            deferredPromise.complete(response.data);
        } catch (error: any) {
            dispatch(setNetworkError(true));
            deferredPromise.cancel(error);
        } finally {
            if(!isOAuthConnectorDetailsCall) {
                dispatch(setIsLoading(false));
            }
        }
        return deferredPromise.promise;
    };

    const makeApiCall = async (reqData: string, isOAuthConnectorDetailsCall = false): Promise<any> => {
        try {
            return await handleAPIPromise(reqData, isOAuthConnectorDetailsCall);
        } catch (error) {
            // handle expire jwt && backend error
        }
    };

    const setAccSearchCriteriaFromParams = (spaceAccId: string | null) => {
        if (location.pathname === '/' && !spaceAccId) {
            if (accountSearchCriteria !== undefined) {
                dispatch(setAccountSearchCriteria(accountSearchCriteria));
            } else {
                dispatch(setAccountSearchCriteria(''));
            }
        }
    };

    return { makeApiCall, handleAPIPromise };
};

export default useHandleApiCall;
