import React, { FC } from 'react';

import { IRadioGroup } from 'interfaces/IRadioGroup';
import { useRadioGroup } from 'hooks/useRadioGroup';
import { ExRadio, ExRadioGroup } from '@boomi/exosphere';
import { RadioType } from 'constants/radio';

/**
 * Radio Buttons group component.
 * By default the checked option is "Yes".
 * To have the "No" option dfault checked add @checkedNo prop.
 * @hasExtraContent prop is required in order to display the @children (if provided) when "Yes" option is checked.
 */

const RadioGroup: FC<IRadioGroup> = ({ title, yesLabel, noLabel, hasExtraContent = false, checkedNo = false, children = false }) => {
    const { radioGroup, t, handleRadioChange } = useRadioGroup(checkedNo);

    return (
        <>
            <div className="ex-mt-medium">
                <ExRadioGroup fieldset={true} onChange={handleRadioChange} value={radioGroup ? RadioType.YES : RadioType.NO} label={t(title)}>
                    <ExRadio value={RadioType.YES}>{t(yesLabel)}</ExRadio>
                    <ExRadio value={RadioType.NO} className="ex-mt-x-small">
                        {t(noLabel)}
                    </ExRadio>
                </ExRadioGroup>
            </div>
            {radioGroup && hasExtraContent && <div>{children}</div>}
        </>
    );
};

export default RadioGroup;
