export const DROPDOWN_DATA = [
    { value: 'dropdown.dateFormat' },
    { value: 'dropdown.numberFormat' },
    { value: 'dropdown.setPrecision' },
    { value: 'dropdown.append' },
    { value: 'dropdown.stringToLowercase' },
    { value: 'dropdown.trimWhitespace' },
];

export const MOCK_DATA = [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }, { value: 'Option 4' }];

export const DROPDOWN_FILTER = [{ value: 'dropdown.mapped' }, { value: 'dropdown.notMapped' }];

export const filterOptions = {
    mapped: 'Mapped',
    notMapped: 'Not Mapped',
};

export const HISTORY_DROPDOWN_STATUS = [{value: 'historyDropdown.complete'}, {value: 'historyDropdown.error'}];

export const historyStatusOptions = {
    complete: 'Complete',
    error: 'Error'
}

export const TRANSFORM_MODAL_DATA = {
    date: ['dropdown.dateFormat'],
    number: ['dropdown.numberFormat', 'dropdown.setPrecision'],
    string: ['dropdown.append', 'dropdown.stringToLowercase', 'dropdown.trimWhitespace'],
};

export const LOGIN_ACCOUNT_DATA = [
    {
        name: 'Dropbox',
        id: 'dropbox-LASJDG',
    },
    {
        name: 'California State University',
        id: 'calstate-ASDBMF',
    },
    {
        name: 'Chai, Inc.',
        id: 'chaiinc-VBMFTG',
    },
    {
        name: 'Acme Inc. Finance',
        id: 'acmeincfinance-LKJNMD',
    },
    {
        name: 'Acme Inc. HR',
        id: 'acmeinchr-FRGHJK ',
    },
    {
        name: 'Acme Inc. Marketing',
        id: 'acmeinchr-JJSKSU ',
    },
];

export const HISTORY_DROPDOWN_EXECUTIONS = [{ value: '10' }, { value: '25' }, { value: '50' }];
