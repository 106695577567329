import { IKeyStringValueString } from 'interfaces/IGeneral';
import { IDataMapsTable, ITableItem } from 'interfaces/IGenericTable';

const useMappingTable = () => {
    const getMappedItems = (data: IDataMapsTable, mappedFunctions: IKeyStringValueString, mappedDestinationProfile: any) => {
        const newMappedItems: ITableItem[] = [];
        data?.Result?.Map?.SourceProfile?.Node.forEach((item) => {
            const extendedMappingElement = data.Result.Map.ExtendedMappings;
            let newItem: ITableItem = { name: item.name, xpath: item.xpath };
            if (!extendedMappingElement?.Mapping) {
                newMappedItems.push(newItem);
                return;
            }

            extendedMappingElement.Mapping.forEach((mappedElem: any) => {
                if (mappedElem.fromXPath !== item.xpath) {
                    return;
                }

                const mappedFunctionVal = mappedFunctions[mappedElem?.toFunction as string];
                if (mappedFunctionVal) {
                    newItem = getMappedFunctionData(newItem, mappedFunctionVal);
                    return;
                }

                if (!mappedElem.toXPath) {
                    return;
                }

                const parsedMappedElem = { ...mappedElem, name: mappedDestinationProfile[mappedElem.toXPath] };
                const mappedElement = newItem.mappedElem ? [...newItem.mappedElem, parsedMappedElem] : [parsedMappedElem];

                newItem = { ...newItem, mappedElem: mappedElement };
            });

            newMappedItems.push(newItem);
        });
        return newMappedItems;
    };

    const getMappedFunctionData = (tableItem: ITableItem, mappedFunctionVal: string) => {
        const mappedFunc = tableItem.mappedFunction ? [...tableItem.mappedFunction, mappedFunctionVal] : [mappedFunctionVal];
        return { ...tableItem, mappedFunction: mappedFunc };
    };

    return { getMappedItems };
};

export default useMappingTable;
