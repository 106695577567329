import MapModal from 'components/Modal/MapModal';
import StringToLowercaseModal from 'components/Modal/StringToLowercaseModal';
import TransformModal from 'components/Modal/TransformModal';
import SetPrecisionModal from 'components/Modal/SetPrecisionModal';
import TrimWhitespaceModal from 'components/Modal/TrimWhitespaceModal';
import DateFormatModal from 'components/Modal/DateFormatModal';
import NumberFormatModal from 'components/Modal/NumberFormatModal';
import AppendModal from 'components/Modal/AppendModal';
import RunNowModal from 'components/Modal/RunNowModal/RunNowModal';
import LoadingModal from 'components/Modal/LoadingModal';
import UninstallModal from 'components/Modal/UninstallModal';
import UnattachModal from 'components/Modal/UnattachModal';
import LogoutModal from 'components/Modal/LogoutModal';
import { IModalTypes, IModalTitles, IBlankState } from 'interfaces/IModal';
import TooltipModal from 'components/Modal/TooltipModal';
import RunNowConfirmationModal from 'components/Modal/RunNowConfirmationModal';
import OpeningAccessTokenModal from 'components/Modal/OpeningAccessTokenModal';
import InitializingOAuthTokenModal from 'components/Modal/InitializingOAuthTokenModal';
import AccessTokenSuccessModal from 'components/Modal/AccessTokenSuccessModal';
import AccessTokenFailedModal from 'components/Modal/AccessTokenFailedModal';

export const MODAL_TYPES: IModalTypes = {
    map: MapModal,
    transform: TransformModal,
    stringToLowercase: StringToLowercaseModal,
    setPrecision: SetPrecisionModal,
    trimWhitespace: TrimWhitespaceModal,
    dateFormat: DateFormatModal,
    numberFormat: NumberFormatModal,
    append: AppendModal,
    runNow: RunNowModal,
    loading: LoadingModal,
    delete: UninstallModal,
    unattach: UnattachModal,
    tooltip: TooltipModal,
    logout: LogoutModal,
    runNowConfirmation: RunNowConfirmationModal,
    openingAccessToken: OpeningAccessTokenModal,
    initializingOAuthToken: InitializingOAuthTokenModal,
    accessTokenSucess: AccessTokenSuccessModal,
    accessTokenFailed: AccessTokenFailedModal,
};
export const MODAL_TITLES: IModalTitles = {
    map: 'dialogBox.map.sourceField',
    transform: 'dialogBox.transform.title',
    stringToLowercase: 'dialogBox.stringToLowercase.title',
    setPrecision: 'dialogBox.setPrecision.title',
    trimWhitespace: 'dialogBox.trimWhitespace.title',
    dateFormat: 'dialogBox.dateFormat.title',
    numberFormat: 'dialogBox.numberFormat.title',
    append: 'dialogBox.append.title',
    runNow: '',
    loading: 'label.loading',
    delete: 'dialogBox.uninstall.title',
    unattach: 'dialogBox.unattach.title',
    tooltip: '',
    logout: 'dialogBox.logout.title',
    runNowConfirmation: 'dialogBox.runNowConfirmation.title',
    openingAccessToken: 'dialogBox.openingAccessToken.title',
    initializingOAuthToken: 'dialogBox.initializingOAuthToken.title',
    accessTokenSucess: 'dialogBox.accessTokenSucess.title',
    accessTokenFailed: 'dialogBox.accessTokenFailed.title',
};

export const EMPTY_STATE: IBlankState = {
    noSearchResults: {
        icon: 'magnifying-glass',
        name: 'blankState.search.description',
        label: 'blankState.search.message',
    },
    noIntegrationFound: {
        icon: 'status-warning',
        name: 'blankState.description',
        label: 'blankState.message',
    },
    noAccountsFound: {
        icon: 'status-warning',
        label: 'blankState.accountSearch',
        name: '',
    },
};

export const MODAL_STATE: IModalTitles = {
    openingAccessToken: 'dialogBox.openingAccessToken.headercontent',
    initializingOAuthToken: 'dialogBox.initializingOAuthToken.headercontent',
    accessTokenSucess: 'dialogBox.accessTokenSucess.headercontent',
    accessTokenFailed: 'dialogBox.accessTokenFailed.headercontent',
};
