import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useBasePageData from 'hooks/useBasePageData';
import { ISpaceName } from 'interfaces/IGeneral';
import { SPACE_DATA } from 'constants/api';
import { useGuidParam } from 'hooks/useQueryParam';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from 'interfaces/IStore';
import './Header.scss';
import { setModalStatus, setModalType } from 'store/actions/generalActions';
import { ExIcon, ExNavigationDrawer, ExLeftMenubar, ExLeftmenubarLink, ExButton, ButtonType, ButtonFlavor } from '@boomi/exosphere';
import { trimFileNameFromLogo } from 'utils/SpaceUtil';

export const Header = () => {
    const spaceGuid = useSelector((state: IStore) => state.login.spaceGuid);
    const { guid } = useGuidParam();
    const { data } = useBasePageData<ISpaceName>(SPACE_DATA(guid ? guid : spaceGuid));
    const [mobileMenuIsVisible, setMobileMenuIsVisible] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let spaceLogo = <span>{data?.data?.space.name}</span>;
    if (data?.data?.space?.theme?.logoUrl) {
        spaceLogo = <img src={trimFileNameFromLogo(data?.data?.space.theme.logoUrl ?? '')} alt={data?.data?.space.theme.logoAltTxt} />;
    }

    const handleLogoutAction = () => {
        dispatch(setModalStatus(true));
        dispatch(setModalType('logout'));
    };

    const handleHamburgerMenuToggle = () => {
        setMobileMenuIsVisible(true);
    };

    const handleClosePanel = () => {
        setMobileMenuIsVisible(false);
    };

    const handleNavigate = (route: string) => {
        if (route === 'logout') {
            handleLogoutAction();
        } else {
            navigate(route);
        }
        setMobileMenuIsVisible(false);
    };
    const handleActivityClick = () => {
        handleNavigate('/activity');
        dispatch(setModalStatus(false));
    };
    const isActive = (path: string) => path === location.pathname;

    return (
        <div className="header-wrapper">
            <div className="ex-ml-2x-large ex-mr-2x-large">
                <div className="ex-row ex-py-small">
                    <div className="ex-col-xs-6">
                        <div className="ex-box ex-size-1">
                            <div className="ex-box-row header-logo">
                                <Link to="/" data-testid="logo">
                                    {spaceLogo}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <nav className="ex-col-xs-6">
                        <div className="ex-box-row header-right">
                            <ul className="header__nav">
                                <li>
                                    <ExButton
                                        type={isActive('/') ? ButtonType.PRIMARY : ButtonType.TERTIARY}
                                        onClick={() => handleNavigate('/')}
                                        flavor={isActive('/') ? ButtonFlavor.BASE : ButtonFlavor.BRANDED}
                                        className="ex-p-small header__link"
                                    >
                                        {t('label.browse')}
                                    </ExButton>
                                </li>
                                <li>
                                    <ExButton
                                        type={isActive('/activity') ? ButtonType.PRIMARY : ButtonType.TERTIARY}
                                        onClick={handleActivityClick}
                                        flavor={isActive('/activity') ? ButtonFlavor.BASE : ButtonFlavor.BRANDED}
                                        className="ex-p-small header__link"
                                    >
                                        {t('label.activity')}
                                    </ExButton>
                                </li>
                                <li>
                                    <ExButton type={ButtonType.TERTIARY} onClick={handleLogoutAction} flavor={ButtonFlavor.BRANDED} className="ex-p-small header__link">
                                        {t('label.logout')}
                                    </ExButton>
                                </li>
                            </ul>

                            <div className="header-mobile-menu-wrapper">
                                <ExIcon hideBrowserTooltip={true} icon="menu" onClick={handleHamburgerMenuToggle} className="header-mobile-menu-icon" data-testid="mobile-menu-icon" />
                                {mobileMenuIsVisible && (
                                    <div className="nav-drawer-wrapper">
                                        <ExNavigationDrawer panelTitle="Global Navigation" onClose={handleClosePanel} className="nav-drawer">
                                            <ExLeftMenubar>
                                                <ExLeftmenubarLink selected={isActive('/')} onClick={() => handleNavigate('/')} data-testid="nav-item-browse">
                                                    {t('label.browse')}
                                                </ExLeftmenubarLink>
                                                <ExLeftmenubarLink selected={isActive('/activity')} onClick={() => handleNavigate('/activity')} data-testid="nav-item-activity">
                                                    {t('label.activity')}
                                                </ExLeftmenubarLink>
                                                <ExLeftmenubarLink onClick={() => handleNavigate('logout')} data-testid="nav-item-logout">
                                                    <span>{t('label.logout')}</span>
                                                </ExLeftmenubarLink>
                                            </ExLeftMenubar>
                                        </ExNavigationDrawer>
                                    </div>
                                )}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Header;
