import { IKeyStringValueString } from 'interfaces/IGeneral';
import { IGeneralReducer } from 'interfaces/IGeneralReducer';
import { AnyAction } from 'redux';
import {
    SET_BROWSE_SWITCH_STATUS,
    SET_DATA_MAPS,
    SET_IS_IPACK_EDIT,
    SET_MODAL_TYPE,
    SET_NETWORK_ERROR,
    SET_SELECTED_DATA_MAP,
    SET_SELECTED_SOURCE_FIELD,
    SET_RUN_NOW_CONFIRMATION_MODAL_STATUS,
    SET_MODAL_STATUS,
    SET_FAILED_MODAL_TYPE,
    SET_ACCESS_TOKEN_PARAMETER_VALUE,
    SET_AUTHORIZATION_PARAMETER_VALUE,
    SET_TOKEN_GENERATED_STATUS,
    SET_OAUTH_TOKEN_DETAILS,
} from 'store/actions/actionTypes';

export const setExtensionData = (state: IGeneralReducer, action: AnyAction) => {
    if (state.env.sameEnvConfiguration) {
        return [...action.payload];
    } else {
        if (state.extensions.length && state.env.sameEnvConfiguration) {
            return [];
        } else {
            return [...state.extensions, ...action.payload];
        }
    }
};

export const updateState = (state: IGeneralReducer, action: AnyAction = { type: '', payload: null }) => {
    const reducerMap: IKeyStringValueString = {
        [SET_NETWORK_ERROR]: 'networkError',
        [SET_MODAL_TYPE]: 'modalType',
        [SET_SELECTED_SOURCE_FIELD]: 'selectedSourceField',
        [SET_DATA_MAPS]: 'dataMaps',
        [SET_SELECTED_DATA_MAP]: 'selectedDataMap',
        [SET_IS_IPACK_EDIT]: 'isEdit',
        [SET_BROWSE_SWITCH_STATUS]: 'artifactState',
        [SET_RUN_NOW_CONFIRMATION_MODAL_STATUS]: 'runNowConfirmationModalStatus',
        [SET_MODAL_STATUS]: 'modalStatus',
        [SET_FAILED_MODAL_TYPE]: 'accessTokenFailure',
        [SET_ACCESS_TOKEN_PARAMETER_VALUE]: 'accessTokenParameterValue',
        [SET_AUTHORIZATION_PARAMETER_VALUE]: 'authorizationParameterValue',
        [SET_TOKEN_GENERATED_STATUS]: 'oauthTokenGeneratedStatus',
        [SET_OAUTH_TOKEN_DETAILS]: 'oauthTokenDetails',
    };

    return {
        ...state,
        [reducerMap[action.type]]: action.payload,
    };
};
