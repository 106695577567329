import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IStore } from '../interfaces/IStore';
import { MODAL_STATE, MODAL_TITLES } from '../constants/modal';
import { MODAL_TYPE } from '../constants/routes/modalTypes';
import { DialogHeaderContent, DialogVariant } from '@boomi/exosphere';

export const useDialogTitle = () => {
    const { t } = useTranslation();
    const { selectedProcess, runStatus } = useSelector((state: IStore) => state.general.processes);
    const { modalType, isLoading, selectedIPack, selectedSourceField } = useSelector((state: IStore) => state.general);
    const { selectedEnvironments } = useSelector((state: IStore) => state.general);

    let title = '';
    let headerContent =  DialogHeaderContent.INFO;
    const variant = DialogVariant.SPACED;

    switch (modalType) {
        case MODAL_TYPE.delete: {
            title = t('dialogBox.uninstall.title');
            break;
        }

        case MODAL_TYPE.unattach: {
            title = t('dialogBox.unattach.title', { envName: selectedEnvironments ? selectedEnvironments[0].name : '' });
            break;
        }

        case MODAL_TYPE.runNow: {
            if (isLoading) {
                title = t('dialogBox.runNow.loading', { processName: `${selectedProcess?.name || ''}` });
                break;
            }
            title = runStatus?.isSuccess ? t('dialogBox.runNow.title.success') : t('dialogBox.runNow.title.failed');
            break;
        }
        case MODAL_TYPE.runNowConfirmation: {
            title = t('dialogBox.runNowConfirmation.title');
            break;
        }

        case MODAL_TYPE.openingAccessToken: {
            title = t('dialogBox.openingAccessToken.title');
            break;
        }

        case MODAL_TYPE.initializingOAuthToken: {
            title = t('dialogBox.initializingOAuthToken.title');
            break;
        }
        case MODAL_TYPE.accessTokenSuccess: {
            title = t('dialogBox.accessTokenSuccess.title');
            break;
        }
        case MODAL_TYPE.accessTokenFailed: {
            title = t('dialogBox.accessTokenFailed.title');
            break;
        }

        case MODAL_TYPE.tooltip: {
            title = selectedIPack?.name ?? '';
            break;
        }
        case MODAL_TYPE.logout: {
            title = t('dialogBox.logout.title');
            break;
        }

        case MODAL_TYPE.map: {
            title = t('dialogBox.map.sourceField', { fieldName: `${selectedSourceField?.name || ''}` });
            break;
        }

        default: {
            title = t(MODAL_TITLES[modalType]);
            headerContent = t(MODAL_STATE[modalType]);
            break;
        }
    }
    return { title, headerContent , variant };
};

export default useDialogTitle;