import { IConnection } from 'interfaces/IConfigureConnection';

export const extractFormIndex = (inputString: string) => {
    const [formIndex] = inputString.split('.');
    return formIndex;
};

export const extractTargetIds = (connections: IConnection[]): string[] => {
    const targetIds: string[] = [];

    connections.forEach((connection) => {
        connection.field.forEach((field) => {
            if (field.id === 'oauthOptions/OAuth2Config/credentials/@clientSecret') {
                targetIds.push(connection.id);
            }
        });
    });

    return targetIds;
};
