import { BadgeColor, ExBadge, ExIcon, ExInput, ExLoader, ExToggle, IconVariant, SearchFlavor, LoaderSize } from '@boomi/exosphere';
import useLandingPage from 'hooks/useLandingPage';
import CardContainer from 'containers/CardContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Browse.scss';
import DialogBox from 'components/common/DialogBox/DialogBox';

export const Browse = () => {
    const { t } = useTranslation();
    const { handleToggle, isToggleSwitchOn, searchValue, handleSearch, cardData, artifactState, isLoading, handleUninstall } = useLandingPage();

    return (
        <>
            {!isLoading ? (
                <div className="ex-ml-2x-large ex-mr-2x-large browse">
                    <div className="ex-row browse-toolbar">
                        <div className="ex-col-xs-12 ex-col-md-6 ex-col-xxl-3 browse-toolbar__search">
                            <ExInput placeholder="Search" flavor={SearchFlavor.GRAY} onInput={handleSearch} type="text" className="browse-toolbar__search-input" value={searchValue} clearable>
                                <ExIcon hideBrowserTooltip={true} icon="magnifying-glass" label="search icon" variant={IconVariant.ICON} slot="prefix"></ExIcon>
                            </ExInput>
                        </div>

                        <div className="ex-col-xs-8 ex-col-md-4 ex-col-xxl-3">
                            <div className="browse-toolbar__switch">
                                <div className="browse-toolbar__switch-label ex-pr-small">{t('label.toggleArtifactState')}</div>
                                &nbsp;
                                {isToggleSwitchOn() ? null : <span className="browser-off-text">{t('toggle.off')}</span>}
                                &nbsp;
                                <ExToggle leftIcon="success" label="toggle" rightIcon="" on={isToggleSwitchOn()} size="large" tabIndex={1} onChange={handleToggle}></ExToggle>
                            </div>
                        </div>
                        <div className="ex-col-xs-4 ex-col-md-2 ex-col-xxl-offset-4 ex-col-xxl-2">
                            <div className="browse-toolbar__artifact-count">
                                <ExBadge color={BadgeColor.GRAY}>{cardData.length} items</ExBadge>
                            </div>
                        </div>
                    </div>
                    <div className="ex-row">
                        <div className="ex-col-xs-12">
                            <div className="ex-box-row">
                                <CardContainer data={cardData} artifactState={artifactState} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="browse-loader">
                    <ExLoader label="" size={LoaderSize.LARGE}></ExLoader>
                </div>
            )}
            <DialogBox uninstall={handleUninstall} />
        </>
    );
};

export default Browse;
