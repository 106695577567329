export default function b64HmacSha1(k, d) {

  const str2rstr_utf8 = function (input) {
    var output = "";
    var i = -1;
    var x, y;

    while (++i < input.length) {
      x = input.charCodeAt(i);
      y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
      if (0xD800 <= x && x <= 0xDBFF && 0xDC00 <= y && y <= 0xDFFF) {
        x = 0x10000 + ((x & 0x03FF) << 10) + (y & 0x03FF);
        i++;
      }

      if (x <= 0x7F)
        output += String.fromCharCode(x);
      else if (x <= 0x7FF)
        output += String.fromCharCode(0xC0 | ((x >>> 6) & 0x1F),
          0x80 | (x & 0x3F));
      else if (x <= 0xFFFF)
        output += String.fromCharCode(0xE0 | ((x >>> 12) & 0x0F),
          0x80 | ((x >>> 6) & 0x3F), 0x80 | (x & 0x3F));
      else if (x <= 0x1FFFFF)
        output += String.fromCharCode(0xF0 | ((x >>> 18) & 0x07),
          0x80 | ((x >>> 12) & 0x3F), 0x80
        | ((x >>> 6) & 0x3F), 0x80 | (x & 0x3F));
    }
    return output;
  }

  const sha1_ft = function (t, b, c, d) {
    if (t < 20)
      return (b & c) | ((~b) & d);
    if (t < 40)
      return b ^ c ^ d;
    if (t < 60)
      return (b & c) | (b & d) | (c & d);
    return b ^ c ^ d;
  }

  const sha1_kt = function (t) {
    return (t < 20) ? 1518500249 : (t < 40) ? 1859775393 : (t < 60)
      ? -1894007588
      : -899497514;
  }

  const safe_add = function (x, y) {
    var lsw = (x & 0xFFFF) + (y & 0xFFFF);
    var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
  }

  const bit_rol = function (num, cnt) {
    return (num << cnt) | (num >>> (32 - cnt));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rstr2b64 = function (input) {
    var tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var output = "";
    var len = input.length;
    for (var i = 0; i < len; i += 3) {
      var triplet = (input.charCodeAt(i) << 16)
        | (i + 1 < len ? input.charCodeAt(i + 1) << 8 : 0)
        | (i + 2 < len ? input.charCodeAt(i + 2) : 0);
      for (var j = 0; j < 4; j++) {
        if (i * 8 + j * 6 > input.length * 8)
          output += "=";
        else
          output += tab.charAt((triplet >>> 6 * (3 - j)) & 0x3F);
      }
    }
    return output;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const b642rstr = function (input) {
    var tab = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;

    input = input.split("\n").join("").split("\r").join("");

    while (i < input.length) {

      enc1 = tab.indexOf(input.charAt(i++));
      enc2 = tab.indexOf(input.charAt(i++));
      enc3 = tab.indexOf(input.charAt(i++));
      enc4 = tab.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64 && chr2 != -1) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64 && chr3 != -1) {
        output = output + String.fromCharCode(chr3);
      }

    }

    return output;
  }

  const rstr_hmac_sha1 = function (key, data) {
    var bkey = rstr2binb(key);
    if (bkey.length > 16)
      bkey = binb_sha1(bkey, key.length * 8);

    var ipad = Array(16), opad = Array(16);
    for (var i = 0; i < 16; i++) {
      ipad[i] = bkey[i] ^ 0x36363636;
      opad[i] = bkey[i] ^ 0x5C5C5C5C;
    }

    var hash = binb_sha1(
      ipad.concat(rstr2binb(data)), 512 + data.length
      * 8);
    return binb_sha1(opad.concat(hash), 512 + 160);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const digest = function (input) {
    return binb_sha1(rstr2binb(input));
  }

  const rstr2binb = function (input) {
    var output = Array(input.length >> 2);
    for (let i = 0; i < output.length; i++)
      output[i] = 0;
    for (let i = 0; i < input.length * 8; i += 8)
      output[i >> 5] |= (input.charCodeAt(i / 8) & 0xFF) << (24 - i
        % 32);
    return output;
  }


  const binb2b64 = function (input) {
    var b64s = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    var r = [];

    //create a byte array
    var bytes = [];
    for (let i = 0; i < input.length * 32; i += 8) {
      var byteCode = (input[i >> 5] >>> (24 - i % 32)) & 0xFF;
      bytes.push(byteCode);
    }

    var dl = bytes.length;

    if ((dl % 3) == 1) {
      bytes[bytes.length] = 0;
      bytes[bytes.length] = 0;
    }
    if ((dl % 3) == 2)
      bytes[bytes.length] = 0;

    for (let i = 0; i < bytes.length; i += 3) {
      r[r.length] = b64s.charAt(bytes[i] >> 2);
      r[r.length] = b64s.charAt(((bytes[i] & 3) << 4)
        | (bytes[i + 1] >> 4));
      r[r.length] = b64s.charAt(((bytes[i + 1] & 15) << 2)
        | (bytes[i + 2] >> 6));
      r[r.length] = b64s.charAt(bytes[i + 2] & 63);
      if ((i % 57) == 54)
        r[r.length] = "\n";
    }

    if ((dl % 3) == 1)
      r[r.length - 1] = r[r.length - 2] = "=";
    if ((dl % 3) == 2)
      r[r.length - 1] = "=";

    return r.join("");
  }

  const binb_sha1 = function (x, len) {
    x[len >> 5] |= 0x80 << (24 - len % 32);
    x[((len + 64 >> 9) << 4) + 15] = len;

    var w = Array(80);
    var a = 1732584193;
    var b = -271733879;
    var c = -1732584194;
    var d = 271733878;
    var e = -1009589776;

    for (var i = 0; i < x.length; i += 16) {
      var olda = a;
      var oldb = b;
      var oldc = c;
      var oldd = d;
      var olde = e;

      for (var j = 0; j < 80; j++) {
        if (j < 16)
          w[j] = x[i + j];
        else
          w[j] = bit_rol(w[j - 3] ^ w[j - 8] ^ w[j - 14]
            ^ w[j - 16], 1);
        var t = safe_add(safe_add(bit_rol(a, 5), sha1_ft(j, b, c, d)), safe_add(safe_add(e, w[j]), sha1_kt(j)));
        e = d;
        d = c;
        c = bit_rol(b, 30);
        b = a;
        a = t;
      }

      a = safe_add(a, olda);
      b = safe_add(b, oldb);
      c = safe_add(c, oldc);
      d = safe_add(d, oldd);
      e = safe_add(e, olde);
    }

    var tmpArray = [];
    tmpArray.push(a);
    tmpArray.push(b);
    tmpArray.push(c);
    tmpArray.push(d);
    tmpArray.push(e);

    return tmpArray;
  }

  return binb2b64(rstr_hmac_sha1(str2rstr_utf8(k), str2rstr_utf8(d)));
}