import b64HmacSha1 from 'utils/Algo';
import { getTokenId, getTokenSecret } from './JWThelper';

class Auth {
    public static getCookie(name: string): string {
        const allCookies = `; ${document.cookie}`;
        const parts: string[] = allCookies.split(`; ${name}=`);
        if (parts.length !== 2) {
            throw new Error(`Cookie ${name} not found`);
        }
        const cookieValuePlusOtherCookies: string = parts[1];
        const cookieValuePlusOtherCookiesArray: string[] = cookieValuePlusOtherCookies.split(';');
        return cookieValuePlusOtherCookiesArray[0];
    }

    public static getAuthDate(): string {
        const date = new Date();
        const year = date.getFullYear();
        // Month is 0-based
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        const second = String(date.getSeconds()).padStart(2, '0');
        const timezoneOffset = String(date.toString().split(' ')[5].slice(3, 9));

        return `${year}-${month}-${day} ${hour}:${minute}:${second} ${timezoneOffset}`;
    }

    public static getAWSToken(date: string, handlerActionClass?: string): string {
        const AWS_KEY = 'AWS';
        const COLON = ':';
        const NEW_LINE = '\n';
        const SPACE = ' ';
        const POST = 'POST';

        const tokenId = decodeURIComponent(<string>getTokenId());
        const tokenSecret = decodeURIComponent(<string>getTokenSecret());

        const signature = `${POST}${NEW_LINE}${NEW_LINE}${NEW_LINE}${date}${handlerActionClass ? NEW_LINE + handlerActionClass : ''}`;

        return `${AWS_KEY}${SPACE}${tokenId}${COLON}${b64HmacSha1(tokenSecret, signature)}`;
    }
}

export default Auth;
