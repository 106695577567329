import { ExtensionsUpdateStatus } from '../constants/extensions';

const useHandleEnvInstallStatus = () => {
    const handleEnvInstallStatus = (environment: { environmentId: string; name: string; id: string; classification: string }[], env: string, envIntstallStatus: ExtensionsUpdateStatus) => {
        return environment?.map((envs) => {
            if (envs.id === env) {
                return {
                    ...envs,
                    installStatus: envIntstallStatus,
                };
            } else {
                return { ...envs };
            }
        });
    };

    return { handleEnvInstallStatus };
};
export default useHandleEnvInstallStatus;
