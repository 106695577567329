import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useHandleApiCall from 'hooks/useHandleApiCall';
import { IStore } from 'interfaces/IStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { getScheduleMutationQuery } from 'services/getDynamicQuery';
import { setModalStatus, setModalType } from 'store/actions/generalActions';
import { IProcessSchedule } from 'interfaces/IProcesses';
import { getSlicedPathname, getCleanedAlreadyScheduledBlocks } from 'utils/scheduleblock';

const useSetSchedule = () => {
    const { schedule: alreadyScheduledBlocks } = useSelector((state: IStore) => state.general.processes.selectedProcess.processSchedules);
    const ipackName = useSelector((state: IStore) => state.general.selectedIPack?.name);
    const { isRunsEveryError, isDaysBlockError } = useSelector((state: IStore) => state.general.processes.isScheduleError);
    const { name, envName, envClassification } = useSelector((state: IStore) => state.general?.processes?.selectedProcess);
    const { atomId, processId, id } = useSelector((state: IStore) => state.general?.processes?.selectedProcess?.processSchedules);
    const { maxRetry } = useSelector((state: IStore) => state.general?.processes?.selectedProcess?.processSchedules.retry);
    const { networkError } = useSelector((state: IStore) => state.general);

    const [scheduleBlocks, setScheduleBlocks] = useState<string[]>([]);
    const [scheduleBlocksToSend, setScheduleBlocksToSend] = useState<IProcessSchedule['schedule']>([]);
    const [alreadyScheduledBlocksToSend, setAlreadyScheduledBlocksToSend] = useState<IProcessSchedule['schedule']>(alreadyScheduledBlocks?.map((el, i) => ({ ...el, index: i })) || []);
    const [count, setCount] = useState<number>(0);
    const [openScheduleAccordions, setOpenScheduleAccordions] = useState<number[]>([]);
    const [checkScheduleFlag, setCheckScheduleFlag] = useState<boolean>(false);
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { makeApiCall } = useHandleApiCall();

    const slicedPathname = getSlicedPathname(pathname);

    const handleCancel = () => {
        navigate(`${slicedPathname.replace('confirmation', 'scheduleRun')}${search}`);
    };

    const onSaveSuccess = () => {
        navigate(`${slicedPathname.replace('confirmation', 'scheduleRun')}${search}`);
        dispatch(setModalStatus(false));
        dispatch(setModalType(''));
    };

    const checkDaysError = () => {
        const allSchedules = [...scheduleBlocksToSend, ...alreadyScheduledBlocksToSend];
        return allSchedules.some((el) => !el.daysOfWeek.length);
    };

    const handleSave = async () => {
        const emptyDaysBlock = checkDaysError();
        dispatch(setModalStatus(true));
        dispatch(setModalType('loading'));

        if (!isRunsEveryError && !isDaysBlockError && !emptyDaysBlock) {
            // change sunday from 0 to 7, backend doesn't accept 0
            const cleanedScheduleBlocksToSend = scheduleBlocksToSend.map((el) => {
                delete el.index;

                if (el.daysOfWeek === '0') {
                    el.daysOfWeek = el.daysOfWeek.replace('0', '7');
                }

                if (el.daysOfWeek.includes('0,')) {
                    el.daysOfWeek = el.daysOfWeek.replace('0,', '') + ',7';
                }

                return el;
            });

            const cleanedAlreadyScheduledBlocks = getCleanedAlreadyScheduledBlocks(alreadyScheduledBlocksToSend);

            const mutationObj = {
                atomId,
                processId,
                id,
                Schedule: [...cleanedScheduleBlocksToSend, ...cleanedAlreadyScheduledBlocks],
                Retry: { Schedule: [...cleanedScheduleBlocksToSend, ...cleanedAlreadyScheduledBlocks], maxRetry },
            };
            const { query } = getScheduleMutationQuery(JSON.stringify(JSON.stringify(mutationObj)), mutationObj.id);
            dispatch(setModalStatus(false));
            const response = await makeApiCall(query);

            if (response && !response.errors) {
                onSaveSuccess();

                return;
            }

            return;
        }

        dispatch(setModalStatus(false));
        dispatch(setModalType(''));
        setOpenScheduleAccordions([]);
    };
    const handleClick = () => {
        setCount((prev) => prev + 1);
        const defaultObj = {
            daysOfMonth: '*',
            daysOfWeek: '1,2,3,4,5',
            hours: '8-18',
            minutes: '0-59/1',
            months: '*',
            years: '*',
            index: alreadyScheduledBlocks.length ? alreadyScheduledBlocks.length + count - 1 : count,
        };

        if (alreadyScheduledBlocks.length) {
            setScheduleBlocks((current) => [...current, `${count + 1}`]);
            setScheduleBlocksToSend((current) => [...current, defaultObj]);
            handleAccordionToggle(count + 1);
            return;
        }

        setScheduleBlocksToSend((current) => [...current, defaultObj]);
        setScheduleBlocks((current) => [...current, `${count}`]);
        handleAccordionToggle(count);
    };
    const handleAccordionToggle = (index: number): void => {
        setOpenScheduleAccordions((prev: number[]) => {
            if (prev.includes(index)) {
                return prev.filter((item) => item !== index);
            } else {
                return [...prev, index];
            }
        });
    };
    useEffect(() => {
        if (alreadyScheduledBlocks?.length === 0 && scheduleBlocks.length === 0) {
            handleClick();
            return;
        }
        if (alreadyScheduledBlocks?.length) {
            alreadyScheduledBlocks.forEach((_, i: number) => {
                setCount(i);
                setScheduleBlocks((current) => [...current, `${i}`]);
            });
        }
    }, []);

    useEffect(() => {
        if (!checkScheduleFlag) {
            setCheckScheduleFlag(true);
            return;
        }
        if (scheduleBlocks.length === 0 && checkScheduleFlag) {
            setOpenScheduleAccordions([]);
        }
    }, [scheduleBlocks]);
    networkError && navigate('/scheduleError');

    return {
        ipackName,
        scheduleBlocks,
        scheduleBlocksToSend,
        setScheduleBlocks,
        setScheduleBlocksToSend,
        alreadyScheduledBlocksToSend,
        setAlreadyScheduledBlocksToSend,
        count,
        setCount,
        handleClick,
        t,
        name,
        envName,
        envClassification,
        alreadyScheduledBlocks,
        navigate,
        handleCancel,
        handleSave,
        isRunsEveryError,
        isDaysBlockError,
        handleAccordionToggle,
        openScheduleAccordions,
        setOpenScheduleAccordions,
        checkScheduleFlag,
    };
};
export default useSetSchedule;
