import { useLocation } from 'react-router';
import { resetState } from 'store/actions/generalActions';
import { store } from "store/store";

export const useQueryParam = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { id, envIds, integrationPackId } = Object.fromEntries(query.entries());

  return { id, envIds, integrationPackId };
};

export const useGuidParam = () => {
  const spaceGuid = store.getState().login.spaceGuid;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { guid, account_id } = Object.fromEntries(query.entries());
  if (guid?.length && guid !== spaceGuid) {
    store.dispatch(resetState(undefined));
  }
  return { guid , account_id};
};