import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '../interfaces/IStore';
import { useTranslation } from 'react-i18next';
import { setIsEditUninstallIpackInstance, setIsEditUninstallFromLastEnv } from 'store/actions/generalActions';

const useUninstallModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isEditUninstallFromLastEnv, isEditUninstallIpackInstance, selectedEnvironments, selectedIPack } = useSelector((state: IStore) => state.general);

    const handleModelDescription = () => {
        if (isEditUninstallFromLastEnv) {
            return {
                description: t('dialogBox.uninstallFromLastEnv.message', {
                    messageSubject: selectedIPack.name,
                    envName: selectedEnvironments ? selectedEnvironments[0]?.name : '',
                }),
                uninstall: t('dialogBox.uninstallFromLastEnv.uninstall'),
            };
        } else if (isEditUninstallIpackInstance) {
            return {
                description: t('dialogBox.uninstallIpackFromEditPage.message', {
                    messageSubject: selectedIPack.name,
                }),
                uninstall: t('dialogBox.uninstallFromLastEnv.uninstall'),
            };
        } else {
            return {
                description: t('dialogBox.uninstall.message', {
                    messageSubject: selectedIPack?.name,
                }),
                uninstall: t('dialogBox.uninstall.uninstall'),
            };
        }
    };

    const handleCancel = (closeModal: () => void) => {
        dispatch(setIsEditUninstallFromLastEnv(false));
        dispatch(setIsEditUninstallIpackInstance(false));
        closeModal();
    }

    const {description, uninstall} = handleModelDescription();
    return {description, uninstall, handleCancel};
};

export default useUninstallModal;
