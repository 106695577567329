import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button/Button';
import Dropdown from 'components/common/DropdownField/Dropdown';
import { setModalType } from 'store/actions/generalActions';
import { IModal } from 'interfaces/IModal';
import { MOCK_DATA } from 'constants/dropdown/dropdownData';
import { IStore } from 'interfaces/IStore';

import styles from 'components/common/DialogBox/DialogBox.module.scss';

const TrimWhitespaceModal: FC<IModal> = ({ closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { name } = useSelector((state: IStore) => state.general.selectedSourceField);
    const { dialogTitle, dialogText, dialogSections, dialogActions, dialogActions_left, dialogActions_right, dialog_body } = styles;

    return (
        <>
            <div className={dialogTitle}>
                <h2>{t('dialogBox.trimWhitespace.title', { item: name })}</h2>
            </div>
            <p className={`${dialogText} ${dialog_body}`}>{t('dialogBox.trimWhitespace.description')}</p>

            <div className={dialogSections}>
                <Dropdown title="dialogBox.description" options={MOCK_DATA} selectedItem={MOCK_DATA[0].value} handleChange={() => undefined} contained fullWidth />
            </div>

            <div className={dialogActions}>
                <div className={dialogActions_left}>
                    <Button label={t('btn.back')} card light handleClick={() => dispatch(setModalType('transform'))} data-testid="stringToLowercaseModal-back" />
                </div>
                <div className={dialogActions_right}>
                    <Button label={t('btn.cancel')} card light handleClick={() => closeModal()} data-testid="stringToLowercaseModal-cancel" />
                    <Button label={t('btn.apply')} card handleClick={() => undefined} data-testid="stringToLowercaseModal-apply" />
                </div>
            </div>
        </>
    );
};

export default TrimWhitespaceModal;
