import { IDataMapsTable } from 'interfaces/IGenericTable';

const useDataMapsProcessDataHandler = () => {
    const processDataMapsData = (dataMaps: any, sameEnvConfiguration: boolean, envs: any[], step: number, process: any): IDataMapsTable[] => {
        let dataMapsData: IDataMapsTable[] = [];
        if (sameEnvConfiguration) {
            dataMapsData =
                dataMaps?.find((mapData: any) => {
                    return mapData?.[0]?.Result?.extensionGroupId === process;
                }) || [];
        } else {
            dataMapsData =
                dataMaps?.find((mapData: any) => {
                    return mapData?.[0]?.Result?.extensionGroupId === process && mapData?.[0].Result?.environmentId == envs[step];
                }) || [];
        }

        return dataMapsData;
    };

    return { processDataMapsData };
};
export default useDataMapsProcessDataHandler;
