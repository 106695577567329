import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IModal } from 'interfaces/IModal';
import { IStore } from 'interfaces/IStore';
import { setModalStatus, setRunNowConfirmationModalStatus } from 'store/actions/generalActions';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';
import styles from 'components/common/DialogBox/DialogBox.module.scss';
const RunNowConfirmationModal: FC<IModal> = ({ closeModal }) => {
    const { selectedProcess } = useSelector((state: IStore) => state.general.processes);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const textElements = {
        description: 'dialogBox.runNowConfirmation.message',
    };
    const { dialog_body } = styles;
    return (
        <>
            <div className={dialog_body}>{t(textElements.description, { processName: `${selectedProcess?.name || ''}` })}</div>

            <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large">
                <ExButton
                    className="ex-mr-small"
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.SECONDARY}
                    size={ButtonSize.LARGE}
                    onClick={() => closeModal()}
                    data-testid="runNowConfirmationModal-cancel"
                >
                    {t('btn.cancel')}
                </ExButton>
                <ExButton
                    flavor={ButtonFlavor.BASE}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.LARGE}
                    data-testid="runNowConfirmationModal-continueProcessRun"
                    onClick={() => {
                        dispatch(setRunNowConfirmationModalStatus(true));
                        dispatch(setModalStatus(false));
                    }}
                >
                    {t('btn.continueProcessRun')}
                </ExButton>
            </div>
        </>
    );
};

export default RunNowConfirmationModal;
