import { IConnectionsArr } from 'interfaces/IConfigureConnection';
import { IInstalledEnv } from 'interfaces/IInstalled';
import { IInstanceProcess } from 'interfaces/IProcesses';
import { IStore } from 'interfaces/IStore';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDynamicProcessesListQuery } from 'services/getDynamicQuery';
import {
    setDataMaps,
    setDifferentEnvStep,
    setInstanceProcesses,
    setIsEditUninstallFromLastEnv,
    setIsIpackEdit,
    setModalStatus,
    setModalType,
    setSameEnvConfiguration,
    setSelectedEnvironments,
    updateExtensions,
} from 'store/actions/generalActions';
import useExtensionsData from './useExtensionsData';
import useHandleApiCall from './useHandleApiCall';
import useHandleResponseErrors from './useHandleResponseErrors';
import { useQueryParam } from './useQueryParam';

const useEditIpack = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { makeApiCall } = useHandleApiCall();
    const { getExtensionsData } = useExtensionsData();
    const { handleResponseErrors } = useHandleResponseErrors();
    const { id } = useQueryParam();
    const selectedIPack = useSelector((state: IStore) => state.general.selectedIPack);
    const { sameEnvConfiguration } = useSelector((state: IStore) => state.general.env);
    const { networkError, isEdit } = useSelector((state: IStore) => state.general);
    const handleIpackEdit = async (environment: IInstalledEnv) => {
        dispatch(setModalType('loading'));
        dispatch(setModalStatus(true));
        dispatch(setIsIpackEdit(true));
        dispatch(setSelectedEnvironments([environment]));
        const envId = environment.id || '';
        const conditionalRouteStep = await getExtensionsData(id, [envId]);
        const { query: processQuery } = getDynamicProcessesListQuery(id);
        const processListResponse = await makeApiCall(processQuery);
        if (processListResponse?.data?.integrationPackInstanceProcesses?.length) {
            dispatch(setInstanceProcesses(processListResponse.data.integrationPackInstanceProcesses.map((item: IInstanceProcess) => ({ label: item.name, value: item.id }))));
        }
        if (!conditionalRouteStep) {
            return;
        }

        navigate(`/edit/multi${conditionalRouteStep}?id=${id}&integrationPackId=${selectedIPack.id}&envIds=${envId.toString()}&sameConfig=${sameEnvConfiguration}`);
        dispatch(setModalStatus(false));
    };

    const handleUnattachIPackEnv = (environment: IInstalledEnv, rowDetails: { environmentName: string | undefined; installStatus: string; Actions: string; id: string }[]) => {
        const installedEnvs = rowDetails.filter((item: any) => item.isInstalled);
        if (installedEnvs?.length === 1) {
            dispatch(setIsEditUninstallFromLastEnv(true));
            dispatch(setModalStatus(true));
            dispatch(setModalType(''));
        } else {
            dispatch(setSelectedEnvironments([environment]));
            dispatch(setModalStatus(true));
            dispatch(setModalType('unattach'));
        }
    };

    const getTableColumns = (isEnvMissingAtom: boolean, columnsWithAttachmentStatus: string[], columns: string[]) => (isEnvMissingAtom ? columnsWithAttachmentStatus : columns);

    useEffect(() => {
        networkError && isEdit && handleResponseErrors(id);
    }, [networkError]);

    useEffect(() => {
        const extensionsStateArr: IConnectionsArr['result'] = [];
        dispatch(updateExtensions(extensionsStateArr));
        dispatch(setDataMaps([]));
        dispatch(setIsIpackEdit(false));
        dispatch(setSameEnvConfiguration(true));
        dispatch(setDifferentEnvStep(0));
    }, []);

    return { handleIpackEdit, handleUnattachIPackEnv, getTableColumns };
};

export default useEditIpack;
