import { IProcessEnvironment } from 'interfaces/IEnvironment';
import { IProcess } from 'interfaces/IProcesses';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getExecuteProcessQuery } from 'services/getDynamicQuery';
import { setSelectedProcess, setModalType, setModalStatus, setProcessRunStatus, setRunNowConfirmationModalStatus } from 'store/actions/generalActions';
import useHandleApiCall from './useHandleApiCall';
import { IStore } from 'interfaces/IStore';
import { useEffect } from 'react';

const useConfirmationTableActions = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { makeApiCall } = useHandleApiCall();
    const { runNowConfirmationModalStatus, processes } = useSelector((state: IStore) => state.general);

    useEffect(() => {
        const runNowIfConfirmed = async () => {
            if (runNowConfirmationModalStatus) {
                // Call handleRunNow when runNowConfirmationModalStatus is true
                await handleRunNowOnConfirm();
            }
        };
        runNowIfConfirmed();
    }, [runNowConfirmationModalStatus]);

    const handleSchedule = (item: IProcess, env: IProcessEnvironment | undefined) => {
        dispatch(setSelectedProcess({ ...item, envName: env?.name, envID: env?.id, envClassification: env?.classification }));
        navigate(`${pathname}/schedule${search}`);
    };

    const handleRunNow = async (item: IProcess, env: IProcessEnvironment | undefined) => {
        dispatch(setSelectedProcess({ ...item, envName: env?.name, envID: env?.id, envClassification: env?.classification }));
        const { atomId, processId } = item.processSchedules;
        const { runProcessQuery } = getExecuteProcessQuery(atomId, processId);
        dispatch(setModalType('runNow'));
        dispatch(setModalStatus(true));
        const response = await makeApiCall(runProcessQuery);

        if (response.errors) {
            dispatch(
                setProcessRunStatus({
                    isSuccess: false,
                    errorMessage: JSON.parse(response.errors[0].message).message,
                }),
            );

            return;
        }

        dispatch(setProcessRunStatus({ isSuccess: true, errorMessage: '' }));
    };
    const handleRunNowOnConfirm = async () => {
        const { atomId, processId } = processes.selectedProcess.processSchedules;
        const { runProcessQuery } = getExecuteProcessQuery(atomId, processId);
        dispatch(setModalType('runNow'));
        dispatch(setModalStatus(true));
        const response = await makeApiCall(runProcessQuery);

        if (response.errors) {
            dispatch(
                setProcessRunStatus({
                    isSuccess: false,
                    errorMessage: JSON.parse(response.errors[0].message).message,
                }),
            );

            return;
        }

        dispatch(setProcessRunStatus({ isSuccess: true, errorMessage: '' }));
        dispatch(setRunNowConfirmationModalStatus(false));
    };
    const handleRunNowConfirmationPrompt = async (item: IProcess, env: IProcessEnvironment | undefined) => {
        dispatch(setSelectedProcess({ ...item, envName: env?.name, envID: env?.id, envClassification: env?.classification }));
        dispatch(setModalStatus(true));
        dispatch(setModalType('runNowConfirmation'));
    };

    return { handleSchedule, handleRunNow, handleRunNowConfirmationPrompt };
};

export default useConfirmationTableActions;
