import { IPackInstallType } from 'constants/artifact';

const useIPackDetails = () => {
    const getIPackCardDetails = (installType: string) => {
        let isSingleInstall = false,
            typeLabel = '';
        if (installType.toUpperCase() === IPackInstallType.SINGLE) {
            isSingleInstall = true;
            typeLabel = 'singleInstall';
        } else {
            typeLabel = 'multiInstall';
        }
        return {
            isSingleInstall,
            typeLabel,
        };
    };

    return { getIPackCardDetails };
};

export default useIPackDetails;
