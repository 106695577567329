
import useHandleResponseErrors from 'hooks/useHandleResponseErrors';
import { ExtensionsUpdateStatus } from '../constants/extensions';
import { IInstalledEnv } from '../interfaces/IInstalled';

const useHandleInstallError = () => {
    const { handleResponseErrors } = useHandleResponseErrors();

    const handleInstallError = async (environment: IInstalledEnv[]) => {
        const extensionsDataMapsStatus = environment?.map((element) => element.installStatus).every((installStatus) => installStatus === ExtensionsUpdateStatus.FAILURE);
        if (extensionsDataMapsStatus) {
            await handleResponseErrors();
        }
    };

    return { handleInstallError };
};
export default useHandleInstallError;
