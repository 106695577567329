import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IAlertBox } from 'interfaces/IAlertBox';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import './AlertBox.scss';

const AlertBox: FC<IAlertBox> = ({ type = 'info', message, messageVariable }) => {
    const { t } = useTranslation();

    const alertTypes: { [key: string]: AlertBannerType } = {
        info: AlertBannerType.INFO,
        danger: AlertBannerType.ERROR,
        warning: AlertBannerType.WARNING,
    };
    return (
        <div className="alert-banner" data-testid="alert-banner">
            <ExAlertBanner type={alertTypes[type] || AlertBannerType.INFO} open={true}>
                {t(message, { messageVariable: `${messageVariable || ''}` })}
            </ExAlertBanner>
        </div>
    );
};

export default AlertBox;
