import { useEffect } from 'react';

const useModalStatusScroll = (modalStatus: boolean) => {
    useEffect(() => {
        if (modalStatus) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
        }
    }, [modalStatus]);
};

export default useModalStatusScroll;
