import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IStore } from 'interfaces/IStore';
import { IModalUnattach } from 'interfaces/IModal';
import useUnattach from 'hooks/useUnattach';
import { ButtonFlavor, ButtonSize, ButtonType, ExButton } from '@boomi/exosphere';

const UnattachModal: FC<IModalUnattach> = ({ closeModal, selectedIPack }) => {
    const { t } = useTranslation();
    const { handleUnattach } = useUnattach();
    const { selectedEnvironments } = useSelector((state: IStore) => state.general);

    return (
        <>
            <p>
                {t('dialogBox.unattach.message', {
                    ipackName: selectedIPack,
                    envName: selectedEnvironments ? selectedEnvironments[0].name : '',
                })}
            </p>
            <div className="artifact-card__actions artifact-card__actions-installed ex-mt-large">
                <ExButton className="ex-mr-small" flavor={ButtonFlavor.BRANDED} type={ButtonType.SECONDARY}
                          size={ButtonSize.LARGE} data-testid="unattachModal-cancel" onClick={() => closeModal()}>
                    {t('btn.cancel')}
                </ExButton>
                <ExButton
                    flavor={ButtonFlavor.RISKY}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.LARGE}
                    data-testid="unattachModal-unattach"
                    onClick={() => {
                        handleUnattach();
                    }}
                >
                    {t('dialogBox.unattach.unattach')}
                </ExButton>
            </div>
        </>
    );
};

export default UnattachModal;
