import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useHandleApiCall from 'hooks/useHandleApiCall';
import { IExtensionsData } from 'interfaces/IExtensionsData';
import { IStore } from 'interfaces/IStore';
import { getDynamicConnectionQuery, getDynamicMappingsQuery } from 'services/getDynamicQuery';
import { setExtensionsStatus } from 'store/actions/generalActions';
import useExtensionStatus from './useExtensionStatus';
import { getConnectionStep, getDataMapsStep, getNextRoute, getPropertyStep, parseConnectionResponse, parseDataMapsResponse, parsePropertiesResponse } from 'utils/Extensions';

const useExtensionsData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sameEnvConfiguration, step } = useSelector((state: IStore) => state.general.env) || { sameEnvConfiguration: false, step: 0 };
    const envStep = step ? step : 0;
    const { makeApiCall } = useHandleApiCall();
    const { getExtensionStatus } = useExtensionStatus();

    const getExtensionsData = async (id: string, envIds: string[]) => {
        const { query: connectionQuery } = getDynamicConnectionQuery(id, envIds);
        const { query: mappingquery } = getDynamicMappingsQuery(id, envIds);

        const connectionResponse = await makeApiCall(connectionQuery);
        const mappingResponse = await makeApiCall(mappingquery);
        if (!connectionResponse || !mappingResponse) {
            return;
        }
        if (connectionResponse.errors || mappingResponse.errors) {
            navigate('/error');
            return;
        }

        // We will need to get here all the id's from each extensionPack in order to send the data to backend.
        // While fetching the extensions for all environments, every environment has a specific id. This id needs to be passed in the confirmation page.
        // Please store all the ids in redux, in order to be used there.

        const extensionsPack = connectionResponse?.data?.integrationPackEnvironmentAttachments?.[envStep]?.environmentExtensions;
        const mapExtension = mappingResponse?.data?.integrationPackEnvironmentAttachments?.[envStep]?.environmentMapExtension;
        const connectionExtensions = parseConnectionResponse(extensionsPack);
        const propertyExtensions = parsePropertiesResponse(extensionsPack);
        const dataMapsExtension = parseDataMapsResponse(mapExtension);

        const mapsErrorStatusCode = dataMapsExtension?.response?.[0].statusCode !== 200;
        const dataMapsValue = mapsErrorStatusCode ? [] : dataMapsExtension;

        const extensionsData: IExtensionsData = {
            dataMaps: getExtensionStatus(dataMapsValue, 'DATA_MAPS'),
            connection: getExtensionStatus(connectionExtensions, 'CONNECTION'),
            properties: getExtensionStatus(propertyExtensions, 'PROCESS_PROPERTY'),
        };

        const { dataMaps, connection, properties } = extensionsData;
        const extensionsStatus = {
            isDataMaps: Boolean(dataMaps),
            isConnection: Boolean(connection),
            isProperties: Boolean(properties),
        };

        dispatch(setExtensionsStatus(extensionsStatus));
        const { isDataMaps, isConnection, isProperties } = extensionsStatus;

        const configureStep = getConnectionStep(sameEnvConfiguration, step);
        const propertiesStep = getPropertyStep(sameEnvConfiguration, step);
        const dataMapsStep = getDataMapsStep(sameEnvConfiguration, step);

        const thirdStep = isDataMaps ? dataMapsStep : '/confirmation';
        const secondStep = isProperties ? propertiesStep : thirdStep;

        return getNextRoute(isConnection, configureStep, secondStep);
    };

    return { getExtensionsData };
};

export default useExtensionsData;
