import React from 'react';

import Banner from 'components/common/Banner/Banner';
import ActionToolbar from 'components/common/ActionToolbar/ActionToolbar';
import CardContainer from 'containers/CardContainer';
import Spinner from 'components/common/Spinner/Spinner';
import DialogBox from 'components/common/DialogBox/DialogBox';
import useInstalled from 'hooks/useInstalled';
import { ArtifactState } from 'constants/artifact';

const Installed = () => {
    const { cardData, handleUninstall, isLoading } = useInstalled();
 
    return (
        <>
            <Banner title="label.installed" />
            <div className="container">
                {!isLoading ? (
                    <>
                        <ActionToolbar data={cardData} />
                        <CardContainer data={cardData} artifactState={ArtifactState.INSTALLED} />
                    </>
                ) : (
                    <Spinner />
                )}
            </div>
            <DialogBox uninstall={handleUninstall} />
        </>
    );
};

export default Installed;
